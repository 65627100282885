import React, { useMemo, useState } from 'react';
import escapeRegExp from 'lodash';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import { formControlInputProps } from '../../../proptypes';
import styles from '../PackageForm.module.scss';
import { getMessages } from './helpers';

const SelectControl = (props) => {
  const {
    options,
    label,
    input,
    placeholder,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    allowOverride,
    customOnBlur,
    isDisabled,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const searchRegExp = useMemo(
    () => new RegExp(escapeRegExp(searchQuery), 'i'),
    [searchQuery],
  );
  const matchedOptions = options.filter((option) => !searchQuery || searchRegExp.test(option.label));

  if (options.length === 1 && !isDisabled) {
    input.onChange(options[0].value);
  }
  const { name, value } = input;

  const optionsList = matchedOptions.map(({ label: optionLabel, value: optionValue }) => {
    if (optionLabel && optionValue) {
      return (
        <SelectOption
          label={optionLabel}
          value={optionValue}
          key={`${name}/${optionValue}`}
        />
      );
    }
    return null;
  });

  optionsList.push(<SelectOption value="" label="" key={`${name}/empty`} />);

  const emptyResults = allowOverride && searchQuery !== '' ? (
    <SelectOption value={searchQuery} label={searchQuery} key={searchQuery} />
  ) : (
    <Column alignment="center stretch" spacing="small" spacingInset="small">
      <Text alignment="center">No results</Text>
    </Column>
  );

  const messages = getMessages(touched, error, warning);

  // TODO: add support for multi select options
  const normalizedValue = Array.isArray(value) ? value[0] : value;

  return (
    <div className={styles.controlBox}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <Select
        {...input}
        onBlur={() => {
          customOnBlur(name, value);
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        value={normalizedValue}
        label={label}
        error={touched && !!error}
        size="small"
        placeholder={placeholder}
        disabled={isDisabled}
      >
        {optionsList}
        {!optionsList.length && emptyResults}
      </Select>
      {messages}
    </div>
  );
};

SelectControl.propTypes = {
  isDisabled: PropTypes.bool,
  allowOverride: PropTypes.bool,
  customOnBlur: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};

SelectControl.defaultProps = {
  isDisabled: false,
  allowOverride: false,
  label: '',
  placeholder: '',
};

export default SelectControl;
