import {
  onCommentsReset,
  onCommentsStartPolling,
  onCommentsStopPolling,
} from '../../actionCreators';
import { CommentActions } from '../../constants';

export default (dispatch, getState, action) => {
  const { type } = action;

  if (type === CommentActions.OPEN_MODAL) {
    dispatch(onCommentsStartPolling());
  }

  if (type === CommentActions.CLOSE_MODAL) {
    dispatch(onCommentsStopPolling());
    dispatch(onCommentsReset());
  }
};
