import {
  onNotificationsMarkAs,
  onPackageFormSetTab,
} from '../../actionCreators';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
  NotificationsTypes,
  PackageTabTypes,
  nonOmnichannelPackageTabList,
} from '../../constants';
import { submitError } from '../../metrics';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';

export const getDefaultTabNameByNotificationType = (notificationType) => {
  switch (notificationType) {
    case NotificationsTypes.CREATE:
    case NotificationsTypes.UPDATE:
    case NotificationsTypes.DELETE:
      return nonOmnichannelPackageTabList.FORM.name;
    case NotificationsTypes.MENTION:
      return nonOmnichannelPackageTabList.COMMENTS.name;
    default:
      return nonOmnichannelPackageTabList.FORM.name;
  }
};

export default (dispatch, getState, action) => {
  const state = getState();
  const {
    User: { currentUser: { username } },
    Notifications: { list },
  } = state;
  const { payload: { id } } = action;

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.NOTIFICATION_OPEN,
    username,
  };

  const selectedNotification = list.find((notification) => notification.id === id);
  if (!selectedNotification) {
    submitError(
      `Cannot find notification with id='${id}' ${MetricNames.NOTIFICATION_OPEN}`,
      metricContext,
    );
    return;
  }
  const { isRead, notificationType } = selectedNotification;
  const tabName = getDefaultTabNameByNotificationType(notificationType);

  if (!isRead) {
    dispatch(onNotificationsMarkAs([selectedNotification], true));
  }
  const tabType = isWebsiteOmnichannelViewMode(state)
    ? PackageTabTypes.OMNICHANNEL
    : PackageTabTypes.NON_OMNICHANNEL;

  dispatch(onPackageFormSetTab(tabName, tabType));
};
