import {
  onBulkImportClearPendingPackages,
  onBulkImportCompleteImporting,
  onBulkImportPackageFailed,
  onBulkImportPackageSucceeded,
  onOpenToast,
} from '../../actionCreators';
import {
  BulkImportActions,
  CampaignColumns,
  Comparators,
  FilterGroupTypes,
  Urls,
} from '../../constants';
import {
  createFilter,
  createFilterString,
} from '../../helpers/filters';

import history from '../../history';

export default (dispatch, getState, action) => {
  if (action.responseType === BulkImportActions.SUCCESS) {
    dispatch(onBulkImportPackageSucceeded(action.refMarker));
  } else {
    dispatch(onBulkImportPackageFailed(action.refMarker));
  }
  const state = getState();
  const { BulkImport: { failedPackageRefMarkers, succeededPackageRefMarkers, expectedPackageCount } } = state;
  const completePackageCount = failedPackageRefMarkers.length + succeededPackageRefMarkers.length;

  if (completePackageCount >= expectedPackageCount) {
    const rules = succeededPackageRefMarkers.map((refMarker) => {
      return createFilter(CampaignColumns.REF_MARKER.name, Comparators.EQUALS, refMarker);
    });
    const filters = [{ type: FilterGroupTypes.OR, rules }];
    const message = `${succeededPackageRefMarkers.length} packages successful. ${failedPackageRefMarkers.length} packages failed.`;
    dispatch(onOpenToast(message, 0));
    dispatch(onBulkImportCompleteImporting());
    dispatch(onBulkImportClearPendingPackages());
    history.push(`${Urls.SHOW_ALL_CAMPAIGNS}?${createFilterString({ filters })}`);
  }
};
