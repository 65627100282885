import React from 'react';
import PropTypes from 'prop-types';
import AuditLog from '../AuditLogPage';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const CampaignAuditLogPage = (props) => {
  const { match: { params: { id } } } = props;
  return <AuditLog id={id} />;
};

CampaignAuditLogPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default WithHistoryTiming(CampaignAuditLogPage, { pageName: PageNames.CAMPAIGN_AUDIT });
