import {
  onHideLoadingSpinner,
  onPerformanceTimerStop,
  onShowLoadingSpinner,
  onUtilizationSelectTimezone,
  onUtilizationSetResults,
} from '../../actionCreators';
import {
  ON_METADATA_RECEIVED,
  PerformanceTimerNames,
  UTILIZATION_DATE_FORMAT,
  UtilizationActions,
} from '../../constants';
import { getDefaultTimezoneFromState } from '../../helpers/dateTime';
import { getSlotUtilizationData } from '../../selectors/utilizationDataSelectors';
import window from '../../helpers/window';
import loadSlotsOrder from './loadSlotsOrder';
import setSlotsOrder from './setSlotsOrder';
import onFilterChange from './onFilterChange';

const WAIT_TIME_OUT = 500;

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;

  if (type === UtilizationActions.SHOW_RESULTS) {
    dispatch(onShowLoadingSpinner());

    const state = getState();
    window.setTimeout(() => {
      getSlotUtilizationData(state)
        .then((data) => {
          dispatch(onHideLoadingSpinner());

          const newData = {
            ...data,
            daysInDateRange: data.daysInDateRange.map((date) => {
              return date.format(UTILIZATION_DATE_FORMAT);
            }),
          };

          dispatch(onPerformanceTimerStop(PerformanceTimerNames.UTILIZATION_PAGE));
          dispatch(onUtilizationSetResults(newData));
        })
        .catch((error) => {
          dispatch(onPerformanceTimerStop(PerformanceTimerNames.UTILIZATION_PAGE));
          dispatch(onHideLoadingSpinner());
          // TODO: error reporting here
          /* eslint-disable no-console */
          console.log(error);
        });
    }, WAIT_TIME_OUT);

    return loadSlotsOrder(dispatch, getState, action, next);
  }

  if (type === ON_METADATA_RECEIVED) {
    next(action);
    const defaultTimezone = getDefaultTimezoneFromState(getState());
    dispatch(onUtilizationSelectTimezone(defaultTimezone));
    return null;
  }

  if (type === UtilizationActions.SET_SLOTS_ORDER) {
    return setSlotsOrder(dispatch, getState, action, next);
  }

  if (type === UtilizationActions.FILTER_CHANGE) {
    return onFilterChange(dispatch, getState, action, next);
  }

  return next(action);
};
