import graphqlAction from 'src/actionCreators/graphql';
import {
  MetadataAuditLogActions,
  ON_ADD_METADATA_ENTRY,
  ON_CLEAR_METADATA,
  ON_METADATA_RECEIVED,
  ON_REMOVE_METADATA_ENTRY,
  ON_UPDATE_METADATA,
} from 'src/constants';
import { updateMetadata } from 'src/graphql/mutations';
import { getMetadata } from 'src/graphql/queries';

export const onAddMetadataEntry = (data) => ({
  type: ON_ADD_METADATA_ENTRY,
  data,
});
export const onRemoveMetadataEntry = (data) => ({
  type: ON_REMOVE_METADATA_ENTRY,
  data,
});
export const onMetadataReceived = (data) => ({
  type: ON_METADATA_RECEIVED,
  data,
});
export const onClearMetadata = () => ({
  type: ON_CLEAR_METADATA,
});
export const onPerformMetadataUpdate = () => ({
  type: ON_UPDATE_METADATA,
});
export const onFetchMetadataAction = (business) => {
  return graphqlAction({
    graphqlAction: getMetadata,
    data: { business },
    onSuccessAction: onMetadataReceived,
    label: 'fetchMetadata',
  });
};
export const onUpdateMetadataAction = (
  username,
  business,
  metadata,
  version,
  onFailureFunction = () => {},
  onSuccessFunction = () => {},
) => graphqlAction({
  graphqlAction: updateMetadata,
  data: {
    input: {
      metadata: {
        business,
        metadata,
        version,
      },
      username,
    },
  },
  label: 'updateMetadata',
  onSuccessFunction,
  onFailureFunction,
});

// TODO: Implement the metadata audit log feature in frontend/backend
export const onMetadataAuditLogsReceived = (payload) => ({
  type: MetadataAuditLogActions.METADATA_AUDIT_LOGS_RECEIVED,
  payload,
});
export const onMetadataAuditLogsAddLog = (payload) => ({
  type: MetadataAuditLogActions.ADD_LOG,
  payload,
});
export const onMetadataAuditLogsClearData = () => ({
  type: MetadataAuditLogActions.CLEAR_DATA,
});
export const onMetadataAuditLogSetFilterString = (filterString) => ({
  type: MetadataAuditLogActions.SET_FILTER_STRING,
  payload: filterString,
});
export const onGetMetadataAuditLogsByBusiness = (business, nextToken = null) => {
  return graphqlAction({
    graphqlAction: () => 'nothing', // getMetadataAuditLogsByBusiness() should be implemented
    data: { business, nextToken },
    onSuccessAction: onMetadataAuditLogsReceived,
    label: 'fetchMetadataAuditLogs',
  });
};
