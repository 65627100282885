// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HistoryTimingWrapper = (props) => {
  const { children, onComponentLoaded, pageName } = props;

  useEffect(() => {
    onComponentLoaded(pageName);
  }, [onComponentLoaded, pageName]);

  return children;
};

HistoryTimingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onComponentLoaded: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default HistoryTimingWrapper;
