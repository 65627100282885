import {
  CampaignColumns,
  CampaignTypes,
  PageTypes,
  WidgetTypes,
} from '../constants';

const ONLINE_ONLY_PACKAGES = {
  'Online Storefront Shoveler Feature Plus': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Shoveler Feature Plus',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Shoveler Feature Plus',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Targeted Storefront Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Targeted Storefront Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Targeted Storefront Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Storefront Tile Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Hero Tile',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Hero Tile',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Enhanced Storefront Shoveler Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Enhanced Storefront Shoveler Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Enhanced Storefront Shoveler Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spaish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Storefront Shoveler Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Shoveler Basic Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Storefront Shoveler Basic Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Fresh Find': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Fresh Find Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Fresh Finds 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Fresh Find Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Fresh Finds 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Deal Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Fresh Find Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Fresh Finds 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Fresh Find Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Fresh Finds 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Targeted Deals Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Targeted Deals Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'COOP 3',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Targeted Deals Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'COOP 3',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Deal Page Hero Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Deal Hero Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Hero 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Deal Hero Feature',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.PLACEMENT.name]: 'Hero 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Category Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Category Page Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.PLACEMENT.name]: 'Hero',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Category Page Hero',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.PLACEMENT.name]: 'Hero',
        [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Enhanced Category Shoveler Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Enhanced Category Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.PLACEMENT.name]: 'Shoveler 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Enhanced Category Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.PLACEMENT.name]: 'Shoveler 1',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Category Shoveler Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Category Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Category Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Checkout Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Checkout Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CHECKOUT,
        [CampaignColumns.PAGE_NAME.name]: 'BYG',
        [CampaignColumns.PAGE_ID.name]: 'BYG',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Checkout Page Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.CHECKOUT,
        [CampaignColumns.PAGE_NAME.name]: 'BYG',
        [CampaignColumns.PAGE_ID.name]: 'BYG',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Specialty Page Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Specialty Page Feature',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Specialty Page Feature',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'SNAP Page Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'SNAP Page Feature',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'SNAP Page Feature',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Tier A Event Dedicated Inclusion': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Tier B Event Dedicated Inclusion': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Online Tier C Event Dedicated Inclusion': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Page Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'House - Online Only': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [],
  },
  'Prime Day Inclusion': {},
  'Prime Day Exclusive Shoveler': {},
};

export default ONLINE_ONLY_PACKAGES;
