import { GraphQLLabels, MetricNames, MetricTypes } from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { getUserSetting } from '../../graphql/queries';
import { submitAppSyncError, submitStringMetric } from '../../metrics';

export default (dispatch, getState, action, next, payload) => {
  const { User: { currentUser: { username } } } = getState();

  const metricContext = {
    location: payload.metricLocation,
    type: MetricTypes.APPSYNC,
    data: {
      username,
      dataType: payload.dataType,
    },
    action: MetricNames.GET_USER_SETTING,
    username,
  };

  submitStringMetric(MetricNames.GET_USER_SETTING, metricContext);

  dispatch(onGraphQLAction({
    graphqlAction: getUserSetting,
    data: {
      username,
      dataType: payload.dataType,
    },
    label: GraphQLLabels.GET_USER_SETTING,
    onSuccessFunction({ data: { getUserSetting: response } }) {
      payload.successFunction(response);
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
