import {
  getAssetAuditLog,
  getCampaignAuditLogs,
  getPackageAuditLogs,
  getRateCardAuditLog,
} from '../../graphql/queries';

import {
  AuditLogActions,
  AuditLogTypes,
  GraphQLLabels,
  Language,
  ON_METADATA_RECEIVED,
} from '../../constants';
import { appsyncToLocal } from '../../helpers/translateCampaigns';
import { appsyncToLocal as appsyncToLocalRateCard } from '../../helpers/translateRateCard';
import onGraphQLAction from '../../actionCreators/graphql';
import {
  onAuditLogClear,
  onAuditLogClearUntranslatedLogs,
  onAuditLogFetch,
  onAuditLogSetLoading,
  onAuditLogSetLogs,
  onAuditLogSetUntranslatedLogs,
  onOpenToast,
} from '../../actionCreators';

export const translateCampaignAuditLogs = (campaignData, metadata) => {
  return campaignData.map(({ campaign, auditLogMetadata }) => appsyncToLocal({
    ...campaign,
    ...auditLogMetadata,
  }, metadata));
};

export const translatePackageAuditLogs = (packageData, metadata) => {
  return packageData.map(({ package: packageItem, auditLogMetadata }) => appsyncToLocal({
    ...packageItem,
    ...auditLogMetadata,
  }, metadata));
};

const getParamsNameByAuditLogType = (auditLogType) => {
  if (auditLogType === AuditLogTypes.CAMPAIGN) {
    return {
      queryName: getCampaignAuditLogs,
      responseParamName: 'getCampaignAuditLogs',
      queryIdName: 'campaignId',
    };
  }

  if (auditLogType === AuditLogTypes.PACKAGE) {
    return {
      queryName: getPackageAuditLogs,
      responseParamName: 'getPackageAuditLogs',
      queryIdName: 'packageId',
    };
  }

  if (auditLogType === AuditLogTypes.RATE_CARD) {
    return {
      queryName: getRateCardAuditLog,
      responseParamName: 'getRateCardAuditLog',
      queryIdName: 'rateCardId',
    };
  }

  if (auditLogType === AuditLogTypes.RATE_CARD_ASSETS) {
    return {
      queryName: getAssetAuditLog,
      responseParamName: 'getAssetAuditLog',
      queryIdName: 'assetId',
    };
  }

  return {
    queryName: getCampaignAuditLogs,
    responseParamName: 'getCampaignAuditLogs',
    queryIdName: 'campaignId',
  };
};

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === AuditLogActions.SET_ITEM) {
    const { payload: { id, auditLogType } } = action;
    dispatch(onAuditLogClear());
    dispatch(onAuditLogFetch(id, auditLogType));
  }

  if (action.type === AuditLogActions.FETCH) {
    const { payload: { id, auditLogType } } = action;

    const {
      queryName,
      responseParamName,
      queryIdName,
    } = getParamsNameByAuditLogType(auditLogType);

    dispatch(onAuditLogSetLoading(true));
    dispatch(onGraphQLAction({
      graphqlAction: queryName,
      data: { [queryIdName]: id },
      label: GraphQLLabels.GET_AUDIT_LOGS,
      onSuccessFunction(data) {
        const response = data.data[responseParamName];
        if (!response) {
          dispatch(onAuditLogSetLoading(false));
          return;
        }
        let campaignData = response.items || response;

        if (auditLogType === AuditLogTypes.RATE_CARD) {
          campaignData = campaignData.map((rawData) => {
            const newData = appsyncToLocalRateCard(rawData);
            newData.merchIntent = JSON.stringify(newData.merchIntent);
            newData.limitedPlacements = JSON.stringify(newData.limitedPlacements);
            newData.mainPlacements = JSON.stringify(newData.mainPlacements);

            return newData;
          }).reverse();
        }

        if (auditLogType === AuditLogTypes.RATE_CARD_ASSETS) {
          campaignData = campaignData.map((rawData) => {
            const newData = {
              ...JSON.parse(rawData.campaign),
              ...rawData,
            };

            delete newData.campaign;

            return newData;
          }).reverse();
        }

        dispatch(onAuditLogSetLoading(false));
        const { Meta: { initialMetadataLoaded, metadata } } = getState();
        if (!initialMetadataLoaded) {
          dispatch(onAuditLogSetUntranslatedLogs(campaignData));
        } else {
          const translatedLogs = auditLogType === AuditLogTypes.PACKAGE
            ? translatePackageAuditLogs(campaignData, metadata)
            : translateCampaignAuditLogs(campaignData, metadata);
          dispatch(onAuditLogSetLogs(translatedLogs));
        }
      },
      onFailureFunction() {
        dispatch(onAuditLogSetLoading(false));
        dispatch(onOpenToast(Language.AUDIT_LOG_ERROR_FETCHING));
      },
    }));
  }

  if (action.type === ON_METADATA_RECEIVED) {
    next(action);
    const {
      AuditLog: { untranslatedLogs, type },
      Meta: { metadata },
    } = getState();
    if (untranslatedLogs && untranslatedLogs.length > 0) {
      const translatedCampaignData = type === AuditLogTypes.PACKAGE
        ? translatePackageAuditLogs(untranslatedLogs, metadata)
        : translateCampaignAuditLogs(untranslatedLogs, metadata);
      dispatch(onAuditLogSetLogs(translatedCampaignData));
      dispatch(onAuditLogClearUntranslatedLogs());
    }
    return;
  }

  next(action);
};
