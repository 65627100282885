import getUserSettings from 'src/middleware/UserSettings/getUserSettings';
import updateUserSettings from 'src/middleware/UserSettings/updateUserSettings';
import {
  CloudSettingsDataType,
  MetricLocations,
} from '../constants';
import {
  onBusinessReceived,
  onSetNewUserExperienceShown,
} from '../actionCreators';

export const getSelectedBusiness = (dispatch, getState, action, next) => {
  const location = MetricLocations.BUSINESS_SELECTOR;
  const dataType = CloudSettingsDataType.BUSINESS_SELECTOR_CFG;
  const payload = {
    metricLocation: location,
    dataType,
    successFunction: (response) => {
      if (!response) {
        dispatch(onSetNewUserExperienceShown(true));
      } else {
        dispatch(onBusinessReceived(response.settings));
      }
    },
  };
  return getUserSettings(dispatch, getState, action, next, payload);
};

export const setSelectedBusiness = (dispatch, getState, action, next, businessType) => {
  const payload = {
    metricLocation: MetricLocations.BUSINESS_SELECTOR,
    dataType: CloudSettingsDataType.BUSINESS_SELECTOR_CFG,
    data: businessType,
  };
  return updateUserSettings(dispatch, getState, action, next, payload);
};
