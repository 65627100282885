import React from 'react';
import Loader from '@amzn/meridian/loader';
import styles from './OverlayLoader.module.scss';

const OverlayLoader = (props) => {
  const {
    isLoading,
    text = '',
    overlayStyleOverrides = {},
  } = props;

  if (!isLoading) {
    return null;
  }

  const textLabel = text
    ? <p className={styles.loaderText}>{text}</p>
    : null;

  return (
    <div className={styles.overlay} style={overlayStyleOverrides}>
      <div className={styles.loaderContainer}>
        <div className={styles.loaderIcon}>
          <Loader type="linear" size="large" />
        </div>
        {textLabel}
      </div>
    </div>
  );
};

export default OverlayLoader;
