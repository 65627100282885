import {
  onNotificationsInitLastSeen,
  onNotificationsLoad,
  onNotificationsSetLoading,
} from '../../actionCreators';
import { NotificationsActions } from '../../constants';
import { getDefaultInitialLoadStartDateTime } from '../../helpers/customerNotifications';
import updateLastSeenTime from './updateLastSeenTime';
import createLastSeenTime from './createLastSeenTime';
import checkLastSeenTime from './checkLastSeenTime';
import sortNotifications from './sortNotifications';
import loadNotifications from './loadNotifications';
import markAs from './markAs';
import openNotification from './openNotification';
import commitMarkedNotifications from './commitMarkedNotifications';
import updateLastNotificationReceivedTime from './updateLastNotificationReceivedTime';
import onCreateNotificationSubscriptionReceived from './onCreateNotificationSubscriptionReceived';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === NotificationsActions.INIT) {
    dispatch(onNotificationsInitLastSeen());
    dispatch(onNotificationsSetLoading(true));
    dispatch(onNotificationsLoad({
      startDateTime: getDefaultInitialLoadStartDateTime(),
    }));
  }

  if (type === NotificationsActions.LOAD) {
    return loadNotifications(store)(next)(action);
  }

  if (type === NotificationsActions.SORT) {
    return sortNotifications(dispatch, getState, action, next);
  }

  if (type === NotificationsActions.INIT_LAST_SEEN) {
    return checkLastSeenTime(dispatch, getState, action, next);
  }

  if (type === NotificationsActions.CREATE_LAST_SEEN_AT) {
    return createLastSeenTime(dispatch, getState, action, next);
  }

  if (type === NotificationsActions.UPDATE_LAST_NOTIFICATION_RECEIVED_TIME) {
    return updateLastNotificationReceivedTime(dispatch, getState, action, next);
  }

  if (type === NotificationsActions.CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED) {
    return onCreateNotificationSubscriptionReceived(dispatch, getState, action, next);
  }
  // Update last time active when a customer open notifications' widget
  if (type === NotificationsActions.OPEN_WIDGET) {
    next(action);

    return updateLastSeenTime(dispatch, getState, action);
  }

  if (type === NotificationsActions.MARK_AS) {
    next(action);

    return markAs(store)(next)(action);
  }

  if (type === NotificationsActions.COMMIT_MARKED) {
    return commitMarkedNotifications(dispatch, getState, action, next);
  }

  if (type === NotificationsActions.OPEN) {
    next(action);

    return openNotification(dispatch, getState, action);
  }

  return next(action);
};
