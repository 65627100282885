import React from 'react';
import Package from '../Package';
import { campaignFormProps } from '../../proptypes';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const ClonePackagePage = (props) => {
  const { match: { params: { id } } } = props;

  return (
    <Package
      isNew={false}
      isClone
      id={id}
    />
  );
};

ClonePackagePage.propTypes = campaignFormProps;

export default WithHistoryTiming(ClonePackagePage, { pageName: PageNames.CLONE_PACKAGE });
