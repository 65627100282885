import _ from 'lodash';
import { createSelector } from 'reselect';
import { getBusinessStartYears } from './sitewide';

export const getPackages = (state) => state.GridViewPage.packages;
export const getPackageLoadingStatus = (state) => state.GridViewPage.raw.packageLoadingStatus;

export const getPackagesForCurrentBusinessStartYears = createSelector(
  [getPackages, getBusinessStartYears],
  (packages, businessStartYears) => {
    const pkgs = {};

    businessStartYears.forEach((businessStartYear) => {
      if (packages[businessStartYear]) {
        Object.assign(pkgs, packages[businessStartYear]);
      }
    });
    return pkgs;
  },
);

export const getPackage = createSelector(
  [
    getPackagesForCurrentBusinessStartYears,
    (state, id) => id,
  ],
  (packages, id) => {
    if (!packages) {
      return null;
    }

    return packages[id];
  },
);

export const arePackagesLoaded = createSelector(
  [
    getPackageLoadingStatus,
    getBusinessStartYears,
  ],
  (packageLoadingStatus, businessStartYears) => {
    if (!packageLoadingStatus || _.isEmpty(packageLoadingStatus)) {
      return false;
    }

    return businessStartYears.every((businessStartYear) => !packageLoadingStatus[businessStartYear]);
  },
);
