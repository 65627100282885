import {
  CampaignColumns,
  CampaignStatuses,
  DEFAULT_TIMEZONE_ID,
  DedupeTypes,
  PageIds,
  PageTypes,
  PlacementSource,
  PlacementType,
  Placements,
  SovTypes,
  WidgetTypes,
} from '../../../constants';

const defaultPlacementPreset = {
  [CampaignColumns.PLACEMENT_SOURCE.name]: PlacementSource.RATE_CARD,
};

const PlacementsConfig = {
  'Prime Day Hero ASIN Shoveler Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day - Hero deal feature placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-1',
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-1',
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Prime Day Dedicated Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day - Dedicated Shoveler placeholder',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Themed Endcap Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: ['E.ENDCAP'],
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'I.ENDCAP',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Prime Day Blended Shoveler Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day - Blended Shoveler placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day',
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Basic Deal Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'COOP 2',
      'COOP 3',
      'COOP 4',
      'COOP 5',
      'COOP 6',
      'COOP 7',
    ],
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-dealspage-desktop-top-6',
      'alm-dealspage-desktop-top-7',
      'alm-dealspage-desktop-middle-1',
      'alm-dealspage-desktop-middle-2',
      'alm-dealspage-desktop-middle-3',
      'alm-dealspage-desktop-middle-4',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-dealspage-mobile-reserved-2',
      'alm-dealspage-mobile-top-7',
      'alm-dealspage-mobile-middle-1',
      'alm-dealspage-mobile-middle-2',
      'alm-dealspage-mobile-middle-3',
      'alm-dealspage-mobile-middle-4',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Ambient Endcap - Exclusive': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Ambient Endcap - Exclusive',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Ambient Endcap - Exclusive',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.H_V_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.H_V_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Ambient Endcap - Exclusive (1 quarter)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Ambient Endcap - Exclusive (1 quarter)',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Ambient Endcap - Exclusive (1 quarter)',
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Ambient Endcap - Exclusive (1 quarter) placeholder',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.H_V_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.H_V_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Ambient Endcap - Shared': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Ambient Endcap - Shared',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Ambient Endcap - Shared',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.LTD_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.LTD_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Chilled Endcap - Exclusive': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Chilled Endcap - Exclusive',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Chilled Endcap - Exclusive',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.P_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.P_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Chilled Endcap - Shared': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Chilled Endcap - Shared',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Chilled Endcap - Shared',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.LTD_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.LTD_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Frozen Endcap - Shared': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Frozen Endcap - Shared',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Frozen Endcap - Shared',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.P_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.P_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Frozen Endcap - Exclusive': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'Frozen Endcap - Exclusive',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Frozen Endcap - Exclusive',
    [CampaignColumns.PAGETYPE.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_ID.name]: PageTypes.IN_STORE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.IN_STORE,
    [CampaignColumns.PLACEMENT.name]: [
      WidgetTypes.P_ENDCAP,
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.P_ENDCAP,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'SNAP EBT Page Shoveler (1-quarter)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.TIMEZONE_ID.name]: DEFAULT_TIMEZONE_ID,
    [CampaignColumns.PACKAGE_NAME.name]: 'SNAP EBT Page Shoveler (1-quarter)',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'SNAP EBT Page Shoveler (1-quarter)',
    [CampaignColumns.PAGETYPE.name]: PageTypes.OTHER,
    [CampaignColumns.PAGE_ID.name]: PageIds.SNAP_EBT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.SNAP_EBT,
    [CampaignColumns.PAGE_THEME.name]: PageTypes.SNAP_EBT,
    [CampaignColumns.PLACEMENT.name]: [
      'Other',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Circular Feature': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: [
      'S.CIRCULAR - BWS',
      'S.CIRCULAR - Bakery',
      'S.CIRCULAR - Beverages',
      'S.CIRCULAR - Dairy',
      'S.CIRCULAR - Deli',
      'S.CIRCULAR - Famous For',
      'S.CIRCULAR - Frozen',
      'S.CIRCULAR - Health Beauty',
      'S.CIRCULAR - Home Kitchen',
      'S.CIRCULAR - Meat Seafood',
      'S.CIRCULAR - Pantry',
      'S.CIRCULAR - Prep. Foods',
      'S.CIRCULAR - Produce',
      'S.CIRCULAR - Snacks',
      'S.CIRCULAR - Special Promo PB Seasonal',
    ],
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'S.CIRC',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Dedicated Event Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Omnichannel Event placeholder - Shoveler feature',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Fresh Find Hero': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'Fresh Find Hero',
      'Fresh Finds 1',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-top-7',
      'alm-dealspage-desktop-reserved-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-top-7',
      'alm-dealspage-mobile-reserved-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Deal Hero Feature': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'Hero 1',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-dealpage-desktop-reserved-2',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-dealpage-mobile-reserved-2',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Animated Storefront Hero Tile': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'HERO',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-tcg-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-tcg-special-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.IS_ANIMATED_CONTENT.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Storefront Hero Tile': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'HERO',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-tcg-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-tcg-special-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.IS_ANIMATED_CONTENT.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Storefront Shoveler Basic Feature': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler 5',
    ],
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'VSSM Storefront Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.PLACEMENT_SOURCE.name]: PlacementSource.VSSM,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.PACKAGE_NAME.name]: 'VSSM Storefront',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'VSSM Storefront Shoveler',
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_THEME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PLACEMENT.name]: [
      'VSSM Shoveler 6',
      'VSSM Shoveler 7',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-bottom-4',
      'alm-storefront-desktop-bottom-5',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-bottom-4',
      'alm-storefront-mobile-bottom-5',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Storefront Shoveler Feature Plus': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-top-7',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-top-7',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Enhanced Storefront Shoveler Feature': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler 5',
    ],
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'ENSHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Category Page Hero': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Category',
    [CampaignColumns.PLACEMENT.name]: [
      'Hero',
    ],
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-category-desktop-top-1',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-category-mobile-top-1',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Category Page Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.PLACEMENT_SOURCE.name]: PlacementSource.RATE_CARD,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler Bottom 2',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'VSSM Category Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.PLACEMENT_SOURCE.name]: PlacementSource.VSSM,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
    [CampaignColumns.PACKAGE_NAME.name]: 'VSSM Category',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'VSSM Category Shoveler',
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_THEME.name]: PageTypes.CATEGORY,
    [CampaignColumns.PLACEMENT.name]: [
      'VSSM Shoveler 5',
      'Shoveler 4',
      'Shoveler Bottom 2',
    ],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-category-desktop-middletwo-1',
      'alm-category-desktop-middletwo-2',
      'alm-category-desktop-bottom-2',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-category-mobile-middletwo-1',
      'alm-category-mobile-middletwo-2',
      'alm-category-mobile-bottom-2',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Enhanced Category Page Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Category',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler Bottom 2',
    ],
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'ENSHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  'Checkout Page Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Checkout',
    [CampaignColumns.PAGE_NAME.name]: 'Checkout',
    [CampaignColumns.PAGE_GROUP.name]: 'Checkout',
    [CampaignColumns.PLACEMENT.name]: [
      'Checkout 1',
      'Checkout 2',
    ],
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'center-2',
      'center-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'center-2',
      'center-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'OTHR',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  // "Dedicated Event Page Shovler" and "Dedicated Event Shovler" are the same
  'Dedicated Event Page Shovler': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Omnichannel Event placeholder - Shoveler feature',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
  },
  // "Specialty Page Exclusive Shoveler" and "Dedicated Specialty Page Feature" are the same
  'Dedicated Specialty Page Feature': {
    ...defaultPlacementPreset,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Specialty Page Exclusive Shoveler placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Other',
    [CampaignColumns.PLACEMENT.name]: ['Other'],
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Category Hero (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Hero (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.PLACEMENT.name]: Placements.HERO,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-category-desktop-top-1',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-category-mobile-top-1',
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
  },
  'Category Enhanced Shoveler (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Enhanced Shoveler (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Category Shoveler (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Shoveler (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Category Hero (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Hero (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.PLACEMENT.name]: Placements.HERO,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-category-desktop-top-1',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-category-mobile-top-1',
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
  },
  'Category Enhanced Shoveler (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Enhanced Shoveler (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Category Enhanced Shoveler (1-quarter)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Enhanced Shoveler (1-quarter) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Category Shoveler (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Category Shoveler (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.CATEGORY,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Deal Shoveler (1-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deal Shoveler (1-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: PageTypes.DEALS,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_2_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
  },
  'Deal Shoveler (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deal Shoveler (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: PageTypes.DEALS,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_2_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
  },
  'Deal Shoveler (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deal Shoveler (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: PageTypes.DEALS,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_2_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
  },
  'Fresh Event Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Fresh Event Inclusion placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Fresh Event Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Fresh Event Shoveler placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Specialty Page Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Specialty Page Inclusion placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.OTHER,
    [CampaignColumns.PAGE_NAME.name]: '',
    [CampaignColumns.PAGE_ID.name]: '',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Storefront Shoveler - Standard (1-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Shoveler - Standard (1-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
  },
  'Storefront Shoveler - Standard (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Shoveler - Standard (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
  },
  'Storefront Shoveler - Standard (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Shoveler - Standard (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
  },
  'Storefront Enhanced Shoveler (1-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Enhanced Shoveler (1-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
  },
  'Storefront Enhanced Shoveler (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Enhanced Shoveler (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: PageIds.FMC_DUMMY_VALUE,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1_4,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
  },
  'Deals Stripe (1-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deals Stripe (1-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: PageTypes.DEALS,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-dealspage-desktop-middle-1',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-dealspage-mobile-middle-1',
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.STRIPE,
  },
  'Deals Stripe (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deals Stripe (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: PageTypes.DEALS,
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-dealspage-desktop-middle-1',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-dealspage-mobile-middle-1',
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.STRIPE,
  },
  'Prime Day (Lead-In) Landing Page  - Shoveler Inclusion': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day (Lead-In) Landing Page  - Shoveler Inclusion placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: '2025 Lemonade Stand',
    [CampaignColumns.PAGE_ID.name]: '0000000000',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Prime Day (Lead-In) Landing Page - Exclusive Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day (Lead-In) Landing Page - Exclusive Shoveler placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: '2025 Lemonade Stand',
    [CampaignColumns.PAGE_ID.name]: '0000000000',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Custom Landing Page (2-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Custom Landing Page (2-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: 'Other',
    [CampaignColumns.PAGE_ID.name]: '0000000000',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Custom Landing Page (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Custom Landing Page (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.EVENT,
    [CampaignColumns.PAGE_NAME.name]: 'Other',
    [CampaignColumns.PAGE_ID.name]: '0000000000',
    [CampaignColumns.SOV.name]: 0.1,
  },
  'Storefront Tile (1-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Tile (1-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PLACEMENT.name]: Placements.HERO_SIDE_TILE,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-storefront-desktop-tcg-3',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-storefront-mobile-tcg-3',
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
  },
  'Storefront Tile (4-weeks)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Storefront Tile (4-weeks) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.STOREFRONT,
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PLACEMENT.name]: Placements.HERO_SIDE_TILE,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-storefront-desktop-tcg-3',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-storefront-mobile-tcg-3',
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.WIDGET.name]: WidgetTypes.HERO,
  },
  'Deals Stripe (4-week)': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Deals Stripe (4-week) placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_NAME.name]: PageTypes.DEALS,
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PLACEMENT.name]: Placements.COOP_1,
    [CampaignColumns.DESKTOP_SLOT.name]: 'alm-dealspage-dekstop-middle-1',
    [CampaignColumns.MOBILE_SLOT.name]: 'alm-dealspage-mobile-middle-1',
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.WIDGET.name]: WidgetTypes.STRIPE,
  },
  'Circular - Custom Banner': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Circular - Custom Banner placeholder',
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.PACKAGE_NAME.name]: 'Circular - Custom Banner',
  },
  'In-store sampling': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'In-store sampling placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PACKAGE_NAME.name]: 'In-store sampling',
  },
  'Prime Day (Event) Landing Page - Exclusive Shoveler': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.ONLINE,
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day (Event) Landing Page - Exclusive Shoveler placeholder',
    [CampaignColumns.IS_DEAL.name]: false,
    [CampaignColumns.PACKAGE_NAME.name]: 'Prime Day (Event) Landing Page - Exclusive Shoveler',
  },
  'Shared Endcap': {
    ...defaultPlacementPreset,
    [CampaignColumns.CAMPAIGN_TYPE.name]: PlacementType.IN_STORE,
  },
};

const customizedPlacementsConfig = {
  ...PlacementsConfig,
  'Storefront Side Tile Hero': PlacementsConfig['Storefront Hero Tile'],
  'Weekly Circular Feature': PlacementsConfig['Shared Endcap'],
  'Exclusive Endcap': PlacementsConfig['Shared Endcap'],
  'Shelf Wobblers': PlacementsConfig['Shared Endcap'],
  'Premium In-Store Endcap - Ambient': PlacementsConfig['Shared Endcap'],
  'Limited In-Store Endcap - Ambient': PlacementsConfig['Shared Endcap'],
  'Premium In-Store Endcap - Chilled': PlacementsConfig['Shared Endcap'],
  'Limited In-Store Endcap - Chilled': PlacementsConfig['Shared Endcap'],
  'Premium In-Store Endcap - Frozen': PlacementsConfig['Shared Endcap'],
  'Limited In-Store Endcap - Frozen': PlacementsConfig['Shared Endcap'],
  'F3G Lobby Pallet': PlacementsConfig['Shared Endcap'],
  'F3G Perimeter Pallet': PlacementsConfig['Shared Endcap'],
  'NF Perimeter Pallet': PlacementsConfig['Shared Endcap'],
  'Non-Food Perimeter Pallet': PlacementsConfig['Shared Endcap'],
  'Email - Bottom Banner Feature': PlacementsConfig['Shared Endcap'],
};

export default customizedPlacementsConfig;
