/* eslint-disable */

// TODO: @deprecated
// Remove this middleware and related to it code
import {
  MetricNames,
  MetricTypes,
} from '../../constants';
import { submitAppSyncError } from '../../metrics';

export default (store) => (next) => (action) => {
  // const { getState, dispatch } = store;
  // if (action.type === ON_MIGRATE_LOCAL_TO_CLOUD) {
  //   const { data } = action;
  //   const payload = {
  //     metricLocation: data.location,
  //     dataType: data.type,
  //     data: data.setting,
  //   };
  //   updateUserSettings(dispatch, getState, action, next, payload);
  // }

  return next(action);
};

// TODO: middleware/GridViewFilter shouldn't be using this function from here
export const checkLocalStorage = (dataType, metricLocation) => {
  try {
    const localStorageSetting = localStorage.getItem(dataType) || null;
    return localStorageSetting;
  } catch (error) {
    const metricContext = {
      location: metricLocation,
      type: MetricTypes.APPSYNC,
      action: MetricNames.GET_USER_SETTING,
    };
    submitAppSyncError(error, metricContext);
  }
};

// TODO: middleware/GridViewFilter shouldn't be using this function from here
export const getDefaultFiltersAndSorts = () => {
  return [{
    filters: [],
    sorts: [],
  }];
};

// TODO: middleware/GridViewFilter shouldn't be using this function from here
export const updateLocalStorage = (dataType, data) => {
  return localStorage.setItem(dataType, data);
};
