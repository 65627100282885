import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import {
  AdLeftDesktopSlots,
  BusinessTypes,
  BusinessYear,
  CampaignColumns,
  CampaignStatuses,
  Delimiters,
  SovTypes,
} from '../constants';
import { getPackage } from '../selectors/package';
import { getCampaign } from '../selectors/campaign';
import { isPackage } from './package';

export const getString = (value) => {
  const valueType = Object.prototype.toString.call(value);
  const allowedTypes = [
    '[object String]',
    '[object Number]',
  ];

  if (!value || !allowedTypes.includes(valueType)) {
    return '';
  }

  return String(value);
};
export const getStringWith = (methods) => (value) => {
  if (!Array.isArray(methods)) {
    return getString(value);
  }

  return methods.reduce((string, method) => string[method](), getString(value));
};

export const getNumber = (value) => {
  if (Object.prototype.toString.call(value) === '[object Boolean]') {
    return 0;
  }

  const newValue = +value;
  if (!newValue) {
    return 0;
  }

  return newValue;
};
export const isFloat = (value) => {
  if (typeof value !== 'number') {
    return false;
  }

  return !!(value % 1);
};
export const isInteger = (value) => {
  if (typeof value !== 'number') {
    return false;
  }

  return Number.isFinite(value) && Math.floor(value) === value;
};
export const openLinkInNewTab = (url) => window.open(url, '_blank');
export const isFreshBusiness = (businessType) => businessType === BusinessTypes.FRESH.name;
export const isFreshTrafficBusiness = (businessType) => businessType === BusinessTypes.FRESH_TRAFFIC.name;
export const isWFMBusiness = (businessType) => businessType === BusinessTypes.WFM.name;
export const isWFMCBusiness = (businessType) => businessType === BusinessTypes.WFMC_NA.name;
export const isFreshSGBusiness = (businessType) => businessType === BusinessTypes.FRESH_SG.name;
export const isFreshUKBusiness = (businessType) => businessType === BusinessTypes.FRESH_UK.name;
export const isFreshDEBusiness = (businessType) => businessType === BusinessTypes.FRESH_DE.name;
export const is3PNABusiness = (businessType) => businessType === BusinessTypes.NA_3P.name;
export const isFreshITBusiness = (businessType) => businessType === BusinessTypes.FRESH_IT.name;
export const isFreshESBusiness = (businessType) => businessType === BusinessTypes.FRESH_ES.name;
export const is3PFRBusiness = (businessType) => businessType === BusinessTypes.FR_3P.name;
export const is3PUKBusiness = (businessType) => businessType === BusinessTypes.UK_3P.name;
export const is3PITBusiness = (businessType) => businessType === BusinessTypes.IT_3P.name;
export const is3PESBusiness = (businessType) => businessType === BusinessTypes.ES_3P.name;
export const is3PDEBusiness = (businessType) => businessType === BusinessTypes.DE_3P.name;
export const is3PJPBusiness = (businessType) => businessType === BusinessTypes.JP_3P.name;
export const isFreshJPBusiness = (businessType) => businessType === BusinessTypes.FRESH_JP.name;
export const isFreshUsOnsiteTraffic = (businessType) => businessType === BusinessTypes.FRESH_TRAFFIC.name;
export const isFreshIntTraffic = (businessType) => businessType === BusinessTypes.FRESH_INT_TRAFFIC.name;
export const isFreshOnsiteTraffic = (businessType) => isFreshUsOnsiteTraffic(businessType) || isFreshIntTraffic(businessType);
export const isGenericFreshBusiness = (business) => {
  return (
    isFreshBusiness(business)
    || isFreshSGBusiness(business)
    || isFreshUKBusiness(business)
    || isFreshDEBusiness(business)
    || isFreshESBusiness(business)
    || isFreshITBusiness(business)
  );
};
export const isJPBusiness = (business) => {
  return (
    is3PJPBusiness(business)
    || isFreshJPBusiness(business)
  );
};
export const isGeneric3PBusiness = (business) => {
  return (
    is3PNABusiness(business)
    || is3PUKBusiness(business)
    || is3PESBusiness(business)
    || is3PITBusiness(business)
    || is3PDEBusiness(business)
    || is3PFRBusiness(business)
    || is3PJPBusiness(business)
  );
};
export const isUSBusiness = (business) => {
  return (
    isFreshBusiness(business)
    || isWFMBusiness(business)
    || is3PNABusiness(business)
    || isWFMCBusiness(business)
    || isFreshTrafficBusiness(business)
  );
};
export const isUKBusiness = (business) => {
  return (
    isFreshUKBusiness(business)
      || is3PUKBusiness(business)
  );
};
export const isDEBusiness = (business) => {
  return (
    isFreshDEBusiness(business)
      || is3PDEBusiness(business)
  );
};
export const isESBusiness = (business) => {
  return (
    isFreshESBusiness(business)
      || is3PESBusiness(business)
  );
};
export const isITBusiness = (business) => {
  return (
    isFreshITBusiness(business)
      || is3PITBusiness(business)
  );
};
export const isEUBusiness = (business) => {
  return isUKBusiness(business)
    || isDEBusiness(business)
    || isESBusiness(business)
    || isITBusiness(business)
    || is3PFRBusiness(business);
};
export const isOmnichannelModeEnabled = (business) => isFreshBusiness(business);

export const isContentSymphonyIntegrationEnabled = (business) => {
  return !isFreshSGBusiness(business) && !isFreshTrafficBusiness(business);
};
export const shouldShowTrafficDriversBlock = (business) => {
  return !isJPBusiness(business);
};
export const convertDecimalToPercent = (value) => {
  if (Object.prototype.toString.call(value) !== '[object Number]') {
    return -1;
  }
  const parsedInt = parseInt(value * 100, 10);
  if (!parsedInt && parsedInt !== 0) {
    return -1;
  }
  return Math.ceil(parsedInt);
};
export const updatePageTitle = (newPageTitle) => {
  document.title = newPageTitle;
};
export const hasItemChanged = (state) => {
  const { GridViewPage: { activeCell, activeCellValue } } = state;
  if (!activeCell) {
    return false;
  }
  const { item: { id, packageId }, columnName } = activeCell;

  if (isPackage({ id, packageId })) {
    const targetPackage = getPackage(state, id);

    return targetPackage && targetPackage[columnName] !== activeCellValue;
  }
  const campaign = getCampaign(state, id, packageId);
  if (!campaign) {
    return false;
  }

  return campaign[columnName] !== activeCellValue;
};
export const isValidUrl = (value) => {
  return /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
};

/**
 * Get human friendly column name by its internal name.
 *
 * @param {string} columnName
 * @returns {string}
 */
export const getColumnDisplayNameByName = (columnName) => {
  const columnsNames = getColumnDisplayNameByName.cachedColumnsNames;

  if (!Object.keys(columnsNames).length) {
    Object
      .keys(CampaignColumns)
      .forEach((columnId) => {
        const { name, display } = CampaignColumns[columnId];
        columnsNames[name] = display;
      });
  }

  return columnsNames[columnName]
    ? columnsNames[columnName]
    : '';
};
getColumnDisplayNameByName.cachedColumnsNames = {};

export const nl2br = (str, breakTag = '<br />') => {
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const sanitize = (str) => {
  return sanitizeHtml(str, {
    allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'abbr', 'code', 'hr', 'br',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    disallowedTagsMode: 'discard',
    selfClosing: ['img', 'br', 'hr'],
    allowedAttributes: {
      a: ['href', 'target'],
      img: ['src'],
      p: ['style'],
    },
    allowedStyles: {
      '*': {
        // Match HEX and RGB
        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'text-align': [/^left$/, /^right$/, /^center$/],
        // Match any number with px, em, or %
        'font-size': [/^\d+(?:px|em|%)$/],
      },
      'p': {
        'font-size': [/^\d+rem$/],
      },
    },
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false,
  });
};

/**
 * Allows to use templates in strings.
 *
 * @param string
 * @param mapping
 * @returns string
 *
 * Usage example:
 * getTemplateString('Hello world! My name is #{username}! I like #{animalType}!', {
 *   username: 'John',
 *   animalType: 'cats',
 * })
 *
 * =>
 *
 * Hello world! My name is John! I like cats'!
 */
export const getTemplateString = (string, mapping = {}) => {
  const matches = string.match(/#{[a-zA-Z]+?}/g);
  if (!matches) {
    return string;
  }
  let replacedString = string;

  matches.forEach((templateStringToReplace) => {
    const templateString = templateStringToReplace.replace(/#|{|}/g, '');
    if (mapping[templateString]) {
      replacedString = replacedString.replace(templateStringToReplace, mapping[templateString]);
    }
  });

  return replacedString;
};
// Sov is disabled for these sov types since Content Symphony sets them automatically
export const shouldDisableSovForSovType = (sovType) => [
  SovTypes.RANDOM,
  SovTypes.DEFAULT,
].includes(sovType);

const getBusinessesList = () => {
  if (Object.keys(getBusinessesList.cache).length) {
    return getBusinessesList.cache;
  }

  return Object.keys(BusinessTypes).reduce((previous, key) => ({
    ...previous,
    [BusinessTypes[key].name]: true,
  }), {});
};
getBusinessesList.cache = {};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const isFinalizedAdLeftCampaign = (desktopSlot, status = CampaignStatuses.APPROVED) => {
  return AdLeftDesktopSlots.includes(desktopSlot)
    && [
      CampaignStatuses.INPUTS_COMPLETE,
      CampaignStatuses.APPROVED,
    ].includes(status);
};

export const getTranslatedOptionsList = (options) => {
  if (!options || !options.length) {
    return [];
  }
  return options.map((option) => {
    return {
      value: option,
      label: option,
    };
  });
};

export const trimAndLowerCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((value) => value.trim().toLowerCase());
  }

  if (typeof data === 'string') {
    return data.trim().toLowerCase();
  }

  console.warn('Cannot trim/lowercase a non-string/array entity!');

  return data;
};

export const joinBusinessStartYear = (business, startYear) => `${business}_${startYear}`;
export const getBusinessAndStartYearFromItem = (item) => {
  if (!item || !item[CampaignColumns.BUSINESS_START_YEAR.name]) {
    return [];
  }

  return item[CampaignColumns.BUSINESS_START_YEAR.name].split(Delimiters.UNDERSCORE);
};
export const getItemBusiness = (item) => {
  if (_.isEmpty(item)) {
    return '';
  }

  const [business] = getBusinessAndStartYearFromItem(item);

  return business;
};

export const createItemShell = (columns, overrides = {}) => {
  // eslint-disable-next-line no-shadow
  const itemShell = Object.values(columns).reduce((itemShell, { name }) => ({
    ...itemShell,
    [name]: overrides[name] !== undefined ? overrides[name] : '',
  }), {});

  Object.entries(overrides).forEach(([key, value]) => {
    // eslint-disable-next-line no-prototype-builtins
    if (itemShell.hasOwnProperty(key)) {
      return;
    }
    itemShell[key] = value;
  });

  return itemShell;
};

// eslint-disable-next-line camelcase
export const isEvergreenBusinessStartYear = (business_startYear) => business_startYear.split('_')[1] === BusinessYear.EVERGREEN;
// eslint-disable-next-line camelcase
export const isEvergreen = ({ business_startYear }) => isEvergreenBusinessStartYear(business_startYear);

export const getNonEmptyObjKeys = (obj) => Object.keys(_(obj).pickBy(_.identity).value());

export const isEmptyValue = (value) => {
  return (
    value === undefined
    || value === null
    // eslint-disable-next-line use-isnan
    || value === NaN
    || (typeof value === 'object' && Object.keys(value).length === 0)
    || (typeof value === 'string' && value.trim().length === 0)
  );
};

export const isArrayEmpty = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  return _.every(arr, (val) => _.isEmpty(val));
};

export const getCampaignColumnWithName = (name) => {
  return Object.values(CampaignColumns).find((column) => column.name === name);
};
