import React, { useRef, useState } from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Icon from '@amzn/meridian/icon';
import Button from '@amzn/meridian/button';
import closeKnockoutTokens
  from '@amzn/meridian-tokens/base/icon/close-knockout';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import { CampaignTypes } from '../../../constants';
import styles from './OmnichannelPackageTabs.module.scss';

const OmnichannelPackageTabs = ({
  tabs,
  currentTab,
  isNew,
  isLoadingCampaigns,
  onSwitchTab,
  onAddTab,
  onRemoveTab,
}) => {
  const buttonRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const addOnlineCampaignTab = () => onAddTab(CampaignTypes.ONLINE);
  const addInStoreCampaignTab = () => onAddTab(CampaignTypes.IN_STORE);
  const onRemoveCampaignTab = (tabIndex) => () => onRemoveTab(tabIndex);

  const getRemoveTabButton = (tabIndex) => {
    if (tabIndex < 0) {
      return null;
    }

    return (
      <div className={styles.removeButtonContainer}>
        <Button
          type="icon"
          onClick={onRemoveCampaignTab(tabIndex)}
          size="small"
        >
          <Icon tokens={closeKnockoutTokens}>
            Remove
          </Icon>
        </Button>
      </div>
    );
  };
  const getAddTabButton = () => {
    return (
      <>
        <Button
          ref={buttonRef}
          onClick={toggleMenu}
          aria-controls="add-campaign-menu"
          aria-haspopup="menu"
          aria-expanded="false"
          disabled={!isNew && isLoadingCampaigns}
        >
          <Icon tokens={plusCircleTokens}>
            Add campaign
          </Icon>
        </Button>
        <Menu
          anchorNode={buttonRef.current}
          id="add-new-campaign-menu"
          open={isMenuOpen}
          position="bottom"
          onClose={toggleMenu}
          alignment="center"
        >
          <MenuItem onClick={addOnlineCampaignTab}>Add Online
            campaign
          </MenuItem>
          <MenuItem onClick={addInStoreCampaignTab}>Add In-Store
            campaign
          </MenuItem>
        </Menu>
      </>
    );
  };

  const getTabList = () => {
    if (!isNew && isLoadingCampaigns) {
      return <p className={styles.loader}>Loading tabs...</p>;
    }

    const tabList = tabs.map(({ tabIndex, display }) => {
      const removeTabButton = getRemoveTabButton(tabIndex);
      return (
        <div key={tabIndex} className={styles.tabContainer}>
          <Tab value={tabIndex}>{display}</Tab>
          {removeTabButton}
        </div>
      );
    });

    return (
      <TabGroup
        value={currentTab}
        onChange={onSwitchTab}
        fill="tabs"
      >
        {tabList}
      </TabGroup>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabsContainer}>
        {getTabList()}
      </div>
      <div>
        {getAddTabButton()}
      </div>
    </div>
  );
};

export default OmnichannelPackageTabs;
