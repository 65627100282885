import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Placement from './Placement';
import {
  getFirstOnlineCampaignIndex,
  getSecondaryLanguageUniqueColumns,
} from '../../../../../helpers/campaign';
import {
  getTemplateString,
  isFreshBusiness,
  isGeneric3PBusiness,
} from 'src/helpers/common';
import {
  CampaignColumns,
  Delimiters,
  Form,
  Language,
  Placements,
} from 'src/constants';
import { onFormSyncTabs } from 'src/actionCreators';
import {
  getSelectedBusiness,
  isWebsiteOmnichannelViewMode,
} from 'src/selectors/sitewide';
import { createCampaignFieldForIndex, getCurrentPackageActiveTab } from 'src/helpers/form';
import { isPlacementSourceVssm } from 'src/helpers/package';

const selector = formValueSelector(Form.NAME);

export const mapStateToProps = (state) => {
  const campaigns = selector(state, 'campaigns') || [];
  const syncUpCampaignsLabel = getTemplateString(
    Language.CAMPAIGN_SYNC_UP_LABEL,
    {
      uniqueColumns: getSecondaryLanguageUniqueColumns(true)
        .join(`${Delimiters.COMMA} `),
    },
  );
  const currentCampaignIndex = getCurrentPackageActiveTab(state);
  const placementSource = selector(state, CampaignColumns.PLACEMENT_SOURCE.name);
  const placement = selector(state, createCampaignFieldForIndex(currentCampaignIndex)(CampaignColumns.PLACEMENT).name);
  const selectedBusiness = getSelectedBusiness(state);

  return {
    currentTab: currentCampaignIndex,
    shouldDisableSovType: placement === Placements.ZONE_9,
    shouldShowSyncUpCampaignsBlock: (
      isWebsiteOmnichannelViewMode(state)
      && currentCampaignIndex !== getFirstOnlineCampaignIndex(campaigns)
    ),
    shouldShowMarketingManagerNotes: isWebsiteOmnichannelViewMode(state),
    shouldShowMerchant: isGeneric3PBusiness(selectedBusiness),
    shouldShowChannel: !isFreshBusiness(selectedBusiness),
    isVSSM: isPlacementSourceVssm(placementSource),
    syncUpCampaignsLabel,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSyncCampaign(tabIndex) {
      dispatch(onFormSyncTabs(tabIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Placement);
