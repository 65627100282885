import { PerformanceTimerActions, TimerActions } from 'src/constants';

export const onStartTimer = () => ({
  type: TimerActions.START_TIMER,
});
export const onCompleteTimer = ({ pageName }) => ({
  type: TimerActions.COMPLETE_TIMER,
  payload: {
    pageName,
  },
});
export const onCancelTimer = () => ({
  type: TimerActions.CANCEL_TIMER,
});
export const onPerformanceTimerStart = (timerName) => ({
  type: PerformanceTimerActions.TIMER_START,
  payload: {
    timerName,
  },
});
export const onPerformanceTimerStop = (timerName) => ({
  type: PerformanceTimerActions.TIMER_STOP,
  payload: {
    timerName,
  },
});
