import { onSendCampaignEdit } from '../../actionCreators';
import { getCampaign } from '../../selectors/campaign';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { campaign: { id, packageId } } } = action;

  const campaign = getCampaign(state, id, packageId);

  next({
    ...action,
    payload: {
      ...action.payload,
      campaign: {
        ...action.payload.campaign,
        version: campaign.version,
      },
    },
  });

  dispatch(onSendCampaignEdit(id, packageId));

  return next(action);
};
