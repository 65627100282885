import {
  FeatureNotificationActions,
  NotificationsActions,
} from 'src/constants';

export const onNotificationsInit = () => ({
  type: NotificationsActions.INIT,
});
export const onNotificationsLoad = (options = {}) => {
  const { nextToken, startDateTime } = options;

  return {
    type: NotificationsActions.LOAD,
    payload: {
      nextToken,
      startDateTime,
    },
  };
};
export const onCreateNotificationSubscriptionReceived = (notification) => ({
  type: NotificationsActions.CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED,
  payload: {
    notification,
  },
});
export const onNotificationsSet = ({ notifications, shouldOverride }) => ({
  type: NotificationsActions.SET,
  payload: {
    notifications,
    shouldOverride,
  },
});
export const onNotificationsSort = () => ({
  type: NotificationsActions.SORT,
});
export const onNotificationsSetLoading = (isLoading) => ({
  type: NotificationsActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onNotificationsSetLoaded = () => ({
  type: NotificationsActions.SET_NOTIFICATIONS_LOADED,
});
export const onNotificationsUpdateNewCount = () => ({
  type: NotificationsActions.UPDATE_NEW_NOTIFICATIONS_COUNT,
});
export const onNotificationsSetNewCount = (count) => ({
  type: NotificationsActions.SET_NEW_NOTIFICATIONS_COUNT,
  payload: {
    count,
  },
});
export const onNotificationsOpenWidget = () => ({
  type: NotificationsActions.OPEN_WIDGET,
});
export const onNotificationsCloseWidget = () => ({
  type: NotificationsActions.CLOSE_WIDGET,
});
export const onNotificationsInitLastSeen = () => ({
  type: NotificationsActions.INIT_LAST_SEEN,
});
export const onNotificationsCreateLastSeenAt = () => ({
  type: NotificationsActions.CREATE_LAST_SEEN_AT,
});
export const onSetNotificationsPagination = (pageNumber) => ({
  type: NotificationsActions.SET_PAGE_NUMBER,
  payload: {
    pageNumber,
  },
});
export const onNotificationsUpdateLastReceivedTime = () => ({
  type: NotificationsActions.UPDATE_LAST_NOTIFICATION_RECEIVED_TIME,
});
export const onNotificationsEnableExperience = () => ({
  type: NotificationsActions.ENABLE_EXPERIENCE,
});
export const onNotificationsMarkAs = (notifications, valueToMark) => ({
  type: NotificationsActions.MARK_AS,
  payload: {
    notifications,
    valueToMark,
  },
});
export const onNotificationsSetUpdating = (isUpdating) => ({
  type: NotificationsActions.SET_UPDATING,
  payload: {
    isUpdating,
  },
});
export const onNotificationOpen = (id) => ({
  type: NotificationsActions.OPEN,
  payload: {
    id,
  },
});
export const onNotificationMark = (notifications, overrideValue) => ({
  type: NotificationsActions.MARK,
  payload: {
    notifications,
    overrideValue,
  },
});
export const onNotificationsCommitMarked = (valueToMark) => ({
  type: NotificationsActions.COMMIT_MARKED,
  payload: {
    valueToMark,
  },
});
export const onNotificationsResetMarked = () => ({
  type: NotificationsActions.RESET_MARKED,
});
export const onNotificationsResetLoaded = () => ({
  type: NotificationsActions.RESET_LOADED,
});
export const onLoadNotifications = () => ({
  type: FeatureNotificationActions.LOAD,
});
export const onAddNotifications = (notifications) => ({
  type: FeatureNotificationActions.ADD_BULK,
  data: notifications,
});
export const onCloseAllNotifications = (version) => ({
  type: FeatureNotificationActions.CLOSE_BULK,
  data: { version },
});
