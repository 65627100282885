import { connect } from 'react-redux';
import { onStartYearsSelect } from '../../actionCreators';
import { submitStringMetric } from '../../metrics';
import { MetricLocations, MetricTypes } from '../../constants';
import { getStartYears } from '../../selectors/sitewide';
import StartYearsSelector from './StartYearsSelector';

const mapStateToProps = (state) => {
  return {
    startYears: getStartYears(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange(years) {
      submitStringMetric(
        years,
        {
          location: MetricLocations.YEAR_SELECTOR,
          type: MetricTypes.CLICK,
        },
      );
      dispatch(onStartYearsSelect(years));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartYearsSelector);
