import { connect } from 'react-redux';
import _ from 'lodash';
import { isLoadingFinished } from 'src/selectors/gridView';
import Package from './Package';
import {
  onCommentsReset,
  onCommentsSetActiveCampaign,
  onCommentsStartPolling,
  onCommentsStopPolling,
  onPackageFormSetTab,
} from '../../actionCreators';
import { Form, PackageTabTypes } from '../../constants';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';
import { getPackage } from '../../selectors/package';
import { getFirstOnlineCampaign } from '../../helpers/campaign';
import { getRateCardAssetById } from '../../selectors/rateCardSelectors';

const hasFormContentChanged = (state) => {
  if (!state.form || !state.form[Form.NAME]) {
    return false;
  }

  const {
    form: {
      [Form.NAME]: {
        initial = {},
        values = {},
      },
    },
  } = state;

  const changes = _.differenceWith(_.toPairs(values), _.toPairs(initial), _.isEqual);

  return !!changes.length;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    id,
    isAssetEditMode,
  } = ownProps;

  const {
    PackageTabs: {
      [PackageTabTypes.NON_OMNICHANNEL]: nonOmnichannelPackageTab,
      [PackageTabTypes.OMNICHANNEL]: omnichannelPackageTab,
    },
    Notifications: {
      isExperienceEnabled: isNotificationsExperienceEnabled,
    },
  } = state;

  let loadedPackage = null;
  if (isAssetEditMode) {
    const asset = getRateCardAssetById(state, id, true);
    if (!_.isEmpty(asset)) {
      // eslint-disable-next-line no-shadow
      const { id, version, rateCardId } = asset;

      loadedPackage = {
        ...asset.campaign,
        id,
        version,
        rateCardId,
      };
    }
  } else {
    loadedPackage = getPackage(state, id);
  }

  const shouldShowOnCloseConfirmation = hasFormContentChanged(state);
  const isLoading = _.isEmpty(loadedPackage)
      && !isLoadingFinished(state);

  const isOmnichannel = isWebsiteOmnichannelViewMode(state);
  const defaultOnlineCampaignForNonOmnichannel = !isOmnichannel
    ? getFirstOnlineCampaign(loadedPackage && loadedPackage.campaigns)
    : null;

  return {
    currentTab: (
      isOmnichannel
        ? omnichannelPackageTab
        : nonOmnichannelPackageTab
    ),
    isNotificationsExperienceEnabled,
    loadedPackage,
    isLoading,
    shouldShowOnCloseConfirmation,
    isAssetEditMode,
    isOmnichannelMode: isOmnichannel,
    defaultOnlineCampaignForNonOmnichannel,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onStartCommentsPolling: () => dispatch(onCommentsStartPolling()),
    onStopCommentsPolling: () => dispatch(onCommentsStopPolling()),
    onSetActiveCampaign: (id, packageId) => {
      dispatch(onCommentsSetActiveCampaign({ id, packageId }));
    },
    onResetComments: () => dispatch(onCommentsReset()),
    onSwitchOmnichannelTab: (tabName) => {
      dispatch(onPackageFormSetTab(tabName, PackageTabTypes.OMNICHANNEL));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Package);
