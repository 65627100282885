import {
  ASSIGN_CAMPAIGNS_TO_PACKAGES,
  GridViewActions,
  LOAD_PACKAGES,
  PackageActions,
  SET_PACKAGES,
  SET_PACKAGES_WITH_CAMPAIGNS,
} from 'src/constants';

export const onAddPackage = (newPackage, options = {}) => {
  const {
    isClone = false,
    isBulkAction = false,
    hasFailedSlotsValidation = false,
  } = options;

  return {
    type: PackageActions.ADD_PACKAGE,
    payload: {
      newPackage,
      options: {
        isClone,
        isBulkAction,
        hasFailedSlotsValidation,
      },
    },
  };
};
export const onEditPackage = (updatedPackage, options = {}) => {
  const {
    isBulkAction = false,
    skipCampaignsUpdate = false,
  } = options;

  return {
    type: PackageActions.EDIT_PACKAGE,
    payload: {
      updatedPackage,
      options: {
        isBulkAction,
        skipCampaignsUpdate,
      },
    },
  };
};
export const onDeletePackage = (currentPackage, options = {}) => {
  const { isBulkAction = false } = options;

  return {
    type: PackageActions.DELETE_PACKAGE,
    payload: {
      currentPackage,
      options: {
        isBulkAction,
      },
    },
  };
};
export const setPackagesWithCampaigns = (businessStartYear, combinedPackagesForBusinessStartYear) => ({
  type: SET_PACKAGES_WITH_CAMPAIGNS,
  payload: {
    businessStartYear,
    combinedPackagesForBusinessStartYear,
  },
});
export const assignCampaignsToPackages = (businessStartYear) => ({
  type: ASSIGN_CAMPAIGNS_TO_PACKAGES,
  payload: { businessStartYear },
});
export const onSendPackageEdit = (id, options = {}) => {
  const {
    isBulkAction = false,
  } = options;
  return {
    type: GridViewActions.SEND_PACKAGE_EDIT,
    payload: {
      id,
      options: {
        isBulkAction,
      },
    },
  };
};
export const loadPackages = (
  business,
  startYear,
  nextToken = null,
) => ({
  type: LOAD_PACKAGES,
  payload: {
    business,
    startYear,
    nextToken,
  },
});
export const setPackages = (businessStartYear, packages) => ({
  type: SET_PACKAGES,
  payload: {
    businessStartYear,
    packages,
  },
});
