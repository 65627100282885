import { connect } from 'react-redux';
import UtilizationFiltersPanel from './UtilizationFiltersPanel';
import {
  isFreshBusiness,
  isGenericFreshBusiness,
  trimAndLowerCase,
} from '../../../helpers/common';
import { getDependentOptionsForUtilization } from '../../../helpers/getMetadataOptions';
import {
  UtilizationOnlineIgnoredPageTypes,
  UtilizationViewMode,
} from '../../../constants/utilization';
import {
  onUtilizationFilterChange,
  onUtilizationShowFmcSlots,
  onUtilizationShowResults,
  onUtilizationSwitchViewMode,
  onUtilizationToggleAbsSovMode,
  onUtilizationToggleStatsMode,
} from '../../../actionCreators';
import { CampaignColumns, PageTypes } from '../../../constants';

const getFilteredPageTypeOptionValues = (filterOptions) => {
  const pageType = filterOptions.get(CampaignColumns.PAGETYPE.name);
  if (!filterOptions || !pageType) {
    return filterOptions;
  }

  const ignoredOptionList = trimAndLowerCase(UtilizationOnlineIgnoredPageTypes);

  const newPageType = { ...pageType };
  newPageType.options = newPageType.options.filter((optionName) => {
    return !ignoredOptionList.includes(optionName.trim().toLowerCase());
  });

  const newFilterOptions = new Map(filterOptions);
  newFilterOptions.set(CampaignColumns.PAGETYPE.name, newPageType);

  return newFilterOptions;
};

export const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      selectedFilters,
      isAggregateByWeeksMode,
      isAbsStatsValuesOnly,
      showFmcSlots,
      viewMode,
    },
    Meta: {
      metadata,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const filterOptions = getFilteredPageTypeOptionValues(getDependentOptionsForUtilization(metadata, selectedBusiness));

  const shouldShowFmcSlotsSwitch = !!(
    selectedFilters
    && selectedFilters[CampaignColumns.PAGETYPE.name]
    && selectedFilters[CampaignColumns.PAGETYPE.name] === PageTypes.CATEGORY
  );

  return {
    selectedFilters,
    filterOptions,
    isAggregateByWeeksMode,
    isAbsStatsValuesOnly,
    isCalendarViewMode: UtilizationViewMode.CALENDAR.name === viewMode,
    showFmcSlots,
    shouldShowFmcSlotsSwitch,
    shouldShowViewModeSwitch: isGenericFreshBusiness(selectedBusiness) && !isFreshBusiness(selectedBusiness),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onFilterChange(filterOptionKey, filterOptionValue, selectedFilter) {
      dispatch(onUtilizationFilterChange(filterOptionKey, filterOptionValue, selectedFilter));
    },
    onToggleMode() {
      dispatch(onUtilizationToggleStatsMode());
      dispatch(onUtilizationShowResults());
    },
    onToggleAbsSovMode() {
      dispatch(onUtilizationToggleAbsSovMode());
      dispatch(onUtilizationShowResults());
    },
    onToggleFmcSlots(showFmsSlots) {
      dispatch(onUtilizationShowFmcSlots(!showFmsSlots));
    },
    onSwitchViewMode(viewMode) {
      dispatch(onUtilizationSwitchViewMode(viewMode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationFiltersPanel);
