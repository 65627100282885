import { RateCardMerchTypes } from '../../../../constants/rateCard';

const Awareness = {
  'Category Shoveler (1-quarter)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Page Shoveler',
    ],
  },
  'Category Enhanced Shoveler (1-quarter)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Enhanced Category Page Shoveler',
    ],
  },
  '[Standard] Product Launch Bundle': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Hero (4-weeks)',
      'Category Enhanced Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (4-weeks)',
    ],
  },
  '[Premium] Product Launch Bundle': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Hero (4-weeks)',
      'Category Enhanced Shoveler (4-weeks)',
      'Storefront Enhanced Shoveler (4-weeks)',
      'Shared Endcap',
    ],
  },
  'Category Hero (2-weeks)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Hero (2-weeks)',
    ],
  },
  'Category Hero (4-weeks)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Hero (4-weeks)',
    ],
  },
  'Storefront Shoveler - Standard (4-weeks)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Storefront Shoveler - Standard (4-weeks)',
    ],
  },
  'Storefront Enhanced Shoveler (1-week)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Storefront Enhanced Shoveler (1-week)',
    ],
  },
  'Storefront Enhanced Shoveler (4-weeks)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Storefront Enhanced Shoveler (4-weeks)',
    ],
  },
  'Storefront Tile (4-weeks)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Storefront Tile (4-weeks)',
    ],
  },
  'Storefront Tile (1-week)': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Storefront Tile (1-week)',
    ],
  },
  'Circular - Custom Banner': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Circular - Custom Banner',
    ],
  },
  'In-store sampling': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'In-store sampling',
    ],
  },
  'Email - Bottom Banner Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Email - Bottom Banner Feature',
    ],
  },
};

export default Awareness;
