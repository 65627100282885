import { CampaignColumns, InputTypes } from './index';
import createColumn from '../helpers/createColumn';

export const RateCardColumns = Object.freeze({
  ID: createColumn('id', 'ID', InputTypes.INPUT),
  BUSINESS: createColumn('business', 'Business', InputTypes.INPUT),
  VENDOR: createColumn('vendor', 'Vendor Name', InputTypes.SINGLE_SELECT),
  MERCHANT_INVESTMENT: createColumn('merchantInvestment', 'Merchant Investment', InputTypes.INPUT),
  VERTICAL: createColumn('vertical', 'Vertical', InputTypes.SINGLE_SELECT),
  MEDIA_PACKAGE: createColumn('mediaPackage', 'Media Package', InputTypes.SINGLE_SELECT),
  IS_EARLY_BIRD: createColumn('isEarlyBird', 'Early Bird', InputTypes.SINGLE_CHECKBOX),
  IS_VSSM: createColumn('isVSSM', 'Is VSSM?', InputTypes.SINGLE_CHECKBOX),
  IS_FRESH: createColumn('isFresh', 'Amazon Fresh', InputTypes.SINGLE_CHECKBOX),
  IS_PUBLISHED: createColumn('isAssetPublished', 'Is Published?', InputTypes.SINGLE_CHECKBOX),
  MARKETING_MANAGER: createColumn('marketingManager', 'Marketing Manager', InputTypes.INPUT),
  NOTE: createColumn('note', 'Notes', InputTypes.TEXT_AREA_INPUT),
  MERCHANDISING_INTENT: createColumn('merchIntent', 'Merchandising Intent', InputTypes.TEXT_AREA_INPUT),
  LIMITED_PLACEMENTS: createColumn('limitedPlacements', 'Limited Placements', InputTypes.INPUT),
  MAIN_PLACEMENTS: createColumn('mainPlacements', 'Main Placements', InputTypes.INPUT),
  CONFIGURATION_VERSION: createColumn('configVersion', 'Configuration Version', InputTypes.SINGLE_SELECT),
});

export const RateCardTabsList = Object.freeze({
  RATE_CARD: { name: 'rateCard', label: 'Rate Card' },
  ASSETS: { name: 'assets', label: 'Assets' },
  AUDIT_LOG: { name: 'auditLog', label: 'Audit Log' },
});

export const RateCardReportType = Object.freeze({
  EXTERNAL: {
    display: 'External (for Vendor)',
    name: 'external',
  },
  INTERNAL: {
    display: 'Internal',
    name: 'internal',
  },
});

export const RateCardExportColumnsAllowedSet = {
  [RateCardReportType.EXTERNAL.name]: {
    [CampaignColumns.STATUS.name]: true,
    // TODO: should it really be here? MM's notes seem to be a little concerning
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: true,
    [CampaignColumns.VENDOR.name]: true,
    [CampaignColumns.BRAND.name]: true,
    [CampaignColumns.START_DATE.name]: true,
    [CampaignColumns.END_DATE.name]: true,
    [CampaignColumns.PAGETYPE.name]: true,
    [CampaignColumns.PAGE_NAME.name]: true,
    [CampaignColumns.CHANNEL.name]: true,
    [CampaignColumns.PROMO_TYPE.name]: true,
    [CampaignColumns.HERO_ASINS.name]: true,
    [CampaignColumns.ASINS.name]: true,
    [CampaignColumns.INVENTORY_AWARENESS.name]: true,
    [CampaignColumns.COPY.name]: true,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.WIDGET.name]: true,
  },
  // No restrictions
  [RateCardReportType.INTERNAL.name]: {},
};

export const RATE_CARD_TOTAL_POINTS_THRESHOLDS = Object.freeze({
  NOT_USED: 100,
  UNDER_USED: 99,
  ALL_USED: 0,
  OVER_USED: 101,
});

export const RateCardMediaPackage = Object.freeze({
  TEN_MILLIONS: {
    value: '$10MM',
    name: 'TEN_MILLIONS',
  },
  FIFTEEN_MILLIONS: {
    value: '$15MM',
    name: 'FIFTEEN_MILLIONS',
  },
  TWENTY_MILLIONS: {
    value: '$20MM',
    name: 'TWENTY_MILLIONS',
  },
});

export const RateCardPlacementPrices = Object.freeze({
  BONUS_PLACEMENT_COST: 0,
});

const RateCardMerchIntent = Object.freeze({
  AWARENESS: {
    label: 'Awareness',
    name: 'awareness',
    share: 40,
  },
  CONSIDERATION: {
    label: 'Consideration',
    name: 'consideration',
    share: 40,
  },
  CONVERSION: {
    label: 'Conversion',
    name: 'conversion',
    share: 15,
  },
});

export const merchIntentValidationRules = {
  [RateCardMerchIntent.AWARENESS.name]: {
    min: 25,
    max: 60,
  },
  [RateCardMerchIntent.CONSIDERATION.name]: {
    min: 25,
    max: 60,
  },
  [RateCardMerchIntent.CONVERSION.name]: {
    min: 10,
    max: 40,
  },
};

export const RateCardHeadersNames = Object.freeze([
  'Actions',
  CampaignColumns.VENDOR.display,
  CampaignColumns.MARKETING_MANAGER.display,
  CampaignColumns.MARKETING_MANAGER_NOTES.display,
  RateCardColumns.IS_PUBLISHED.display,
]);

export const RateCardAssetsHeadersNames = Object.freeze([
  'Actions',
  CampaignColumns.PACKAGE_NAME.display,
  CampaignColumns.PLACEMENT_IN_BUNDLE.display,
  CampaignColumns.CHANNEL.display,
  CampaignColumns.START_DATE.display,
  CampaignColumns.END_DATE.display,
  CampaignColumns.WIDGET.display,
  CampaignColumns.PLACEMENT.display,
  CampaignColumns.MARKETING_MANAGER_NOTES.display,
]);

export const RateCardPlacementColumnNames = Object.freeze({
  CLASS: {
    name: 'className',
    label: 'Class',
  },
  TYPE: {
    name: 'type',
    label: 'Type',
  },
  PLACEMENT_NAME: {
    name: 'placementName',
    label: 'Package/Bundle',
  },
  DOLLARS: {
    name: 'dollars',
    label: 'Cost',
  },
  NET_PLACEMENTS: {
    name: 'netPlacements',
    label: 'Net placements',
    description: 'The sum (total) of all the campaigns (Online + In-Store) being created/within a given Bundle (Net Placements = (Online # + In-Store #) * # of bundles)',
  },
  PLANNED_PLACEMENTS: {
    name: 'plannedPlacements',
    label: 'Planned placements',
  },
  VENDOR_MAX: {
    name: 'vendorMax',
    label: 'Max/Vendor',
    description: 'Maximum # a vendor is allowed to purchase for the year',
  },
  UFF_PLACEMENT: {
    name: 'uffPlacement',
    label: 'Online Placement #',
    description: '# of Online campaigns in the bundle',
  },
  AFS_PLACEMENT: {
    name: 'afsPlacement',
    label: 'In-Store Placement #',
    description: '# of In-Store campaigns in the bundle',
  },
});

export const RateCardMerchTypes = Object.freeze({
  AWARENESS: {
    label: 'Awareness',
    name: 'awareness',
    defaultSpread: 40,
  },
  CONSIDERATION: {
    label: 'Consideration',
    name: 'consideration',
    defaultSpread: 40,
  },
  CONVERSION: {
    label: 'Conversion',
    name: 'conversion',
    defaultSpread: 20,
  },
});

export const RateCardConfig = Object.freeze({
  LIMIT_PER_PAGE: 20,
  ASSETS_LIMIT_PER_PAGE: 40,
  VENDOR_MAX_LIMIT: 999,
});

export const RateCardConfigNames = Object.freeze({
  YEAR_2022: {
    name: 'rate-card-2022-Aug',
    label: 2022,
  },
  YEAR_2023: {
    name: 'rate-card-2023-Aug',
    label: 2023,
  },
  YEAR_2024: {
    name: 'rate-card-2024-Aug',
    label: 2024,
  },
});

export const RateCardPlacementTypes = Object.freeze({
  LIMITED: 'Limited',
  MAIN: 'Main',
  MAIN_VSSM: 'Main VSSM',
});

export const RateCardSearchFiltersList = [
  [RateCardColumns.VENDOR.name],
  [RateCardColumns.NOTE.name],
  [RateCardColumns.MARKETING_MANAGER.name],
];

export const RateCardAssetsMenuLinksName = Object.freeze({
  DELETE_ALL: 'Delete All',
  SHOW_PUBLISHED: 'Show published',
});
