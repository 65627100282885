import React from 'react';
import Select, { SelectOption } from '@amzn/meridian/select';
import { BusinessYear, Delimiters } from '../../constants';

const StartYearsSelector = ({ startYears, onChange }) => {
  const options = Object.values(BusinessYear).map((year) => {
    return (
      <SelectOption
        key={year}
        value={year}
        label={year}
      />
    );
  });

  const getLabel = (values) => {
    return values.map(({ label }) => label).join(Delimiters.COMMA);
  };
  return (
    <Select
      value={startYears}
      valueLabel={getLabel}
      onChange={onChange}
      placeholder="Select a year"
      width={150}
      size="small"
    >
      {options}
    </Select>
  );
};

export default StartYearsSelector;
