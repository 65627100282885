import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import deleteTokens from '@amzn/meridian-tokens/base/icon/close-circle';

import SingleSelect from '../../StyledInputs/SingleSelect';
import Input from '../../StyledInputs/Input';

import { filterViewProps } from '../../../proptypes';
import {
  Comparators,
  FilterGroupTypes,
  SortOrder,
} from '../../../constants';

import GridViewFilterGroup from '../GridViewFilterGroup';

import styles from './GridViewFilterModal.module.scss';
import sitewideStyles from '../../../styles/sitewide.module.scss';

const sortOptions = Object.keys(SortOrder).map((key) => {
  return SortOrder[key];
});

const defaultView = { name: '', filters: [], sorts: [] };
const defaultFilter = { column: '', comparator: Comparators.EQUALS, value: '' };
const defaultSortOrder = SortOrder[Object.keys(SortOrder)[0]].value;
const defaultSort = { column: '', order: defaultSortOrder };

const GridViewFilterModal = ({
  isOpen,
  closeModal,
  saveView,
  filterViewBeingEdited,
  campaignColumns,
}) => {
  const columnOptions = Object.keys(campaignColumns).map((key) => {
    return {
      label: campaignColumns[key].display,
      value: campaignColumns[key].name,
    };
  });
  const { name, filters = [], sorts = [] } = filterViewBeingEdited;
  const nonEmptyFilters = filters.length > 0 ? filters : [defaultFilter];
  const nonEmptySorts = sorts.length > 0 ? sorts : [defaultSort];
  const [filterName, setFilterName] = useState(name);
  const [activeFilters, setActiveFilters] = useState(nonEmptyFilters);
  const [activeSorts, setActiveSorts] = useState(nonEmptySorts);
  const saveActiveView = () => {
    // TODO: if no name supplied, show an error and return
    const view = {
      name: filterName,
      filters: activeFilters,
      sorts: activeSorts,
    };

    saveView(view);
    closeModal();
  };

  const addSort = () => {
    setActiveSorts([...activeSorts, { ...defaultSort }]);
  };

  const deleteSort = (index) => {
    setActiveSorts(activeSorts.filter((sort, i) => i !== index));
  };

  const updateSort = (index, val, key) => {
    const newSorts = activeSorts.map((sort, i) => {
      if (i !== index) { return sort; }
      return {
        ...sort,
        [key]: val,
      };
    });
    return setActiveSorts(newSorts);
  };

  const sortList = activeSorts.map((sort, index) => {
    return (
      // index should be ok here, as the sorts will not be reordered at any time
      /* eslint-disable-next-line react/no-array-index-key */
      <Row height="38px" width="100%" widths="mixed" key={sort.column + sort.order + index}>
        <div className={styles.sortColumn}>
          <SingleSelect
            inputValue={sort.column}
            options={columnOptions}
            size="small"
            onInputChange={(val) => { updateSort(index, val, 'column'); }}
          />
        </div>
        <div className={styles.sortColumn}>
          <SingleSelect
            inputValue={sort.order}
            options={sortOptions}
            size="small"
            onInputChange={(val) => { updateSort(index, val, 'order'); }}
          />
        </div>
        <div>
          <button
            onClick={() => { deleteSort(index); }}
            className={sitewideStyles.buttonAsLink}
            type="button"
          >
            <Icon tokens={deleteTokens} className={sitewideStyles.svgIcon} />
          </button>
        </div>
      </Row>
    );
  });

  return (
    <Modal open={isOpen} scrollContainer="modal">
      <div className={styles.wrapper}>
        <div className={styles.spacer}>
          <Row height="64px">
            <div className={styles.tripleFilterColumn}>
              <Input inputValue={filterName} onInputChange={(val) => { setFilterName(val); }} placeholder="View name" label="View name" />
            </div>
          </Row>
        </div>
        <Heading level={3}>Filters</Heading>
        <GridViewFilterGroup
          filters={activeFilters}
          onUpdateFilters={setActiveFilters}
          groupType={FilterGroupTypes.AND}
          depth={1}
          isTopLevelGroup
        />
        <Heading level={3}>Sorts</Heading>
        <div className={styles.row}>
          {sortList}
          <Row height="38px">
            <Button onClick={addSort} size="small" className={styles.actionButton}>Add Sort</Button>
          </Row>
        </div>
      </div>
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={closeModal}>Cancel</Button>
          <Button type="secondary" size="small" onClick={saveActiveView}>Save</Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

GridViewFilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  filterViewBeingEdited: filterViewProps,
  saveView: PropTypes.func.isRequired,
  campaignColumns: PropTypes.objectOf(PropTypes.shape({
    display: PropTypes.string,
    name: PropTypes.string,
    inputType: PropTypes.string,
  })).isRequired,
};

GridViewFilterModal.defaultProps = {
  filterViewBeingEdited: defaultView,
};

export default GridViewFilterModal;
