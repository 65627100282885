import { onOpenToast } from '../../actionCreators';
import {
  Delimiters,
  Form,
  FormActions,
  Language,
} from '../../constants';
import { isMissingInventoryAwarenessFields } from '../../helpers/form';
import onChange from './onChange';
import addTab from './addTab';
import removeTab from './removeTab';
import syncTabs from './syncTabs';
import onBlur from './onBlur';

// TODO: split this middleware and extract functionality into separate files
export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;
  const { form } = getState();

  if (!form[Form.NAME]) {
    return next(action);
  }

  if (type === FormActions.ADD_TAB) {
    return addTab(dispatch, getState, action, next);
  }

  if (type === FormActions.REMOVE_TAB) {
    return removeTab(dispatch, getState, action, next);
  }

  if (type === FormActions.SYNC_TABS) {
    return syncTabs(dispatch, getState, action, next);
  }

  // Skip if action is for another form
  if (!action.meta || !action.meta.form || !(action.meta.form === Form.NAME)) {
    return next(action);
  }

  if (type === FormActions.SET_SUBMIT_FAILED) {
    const formName = form[Form.NAME];
    if (formName
      && formName.syncErrors
      && Object.keys(formName.syncErrors).length) {
      const missingRequiredFields = Object.keys(formName.syncErrors);
      const listOfFields = missingRequiredFields.join(`${Delimiters.COMMA} `);
      const message = `[Error] Please make sure all of the following fields are correctly filled out: ${listOfFields}!`;
      dispatch(onOpenToast(message));

      if (isMissingInventoryAwarenessFields(missingRequiredFields)) {
        dispatch(onOpenToast(Language.NEEDS_INVENTORY_AWARENESS_WARNING));
      }
    }
  }
  if (type === FormActions.CHANGE) {
    return onChange(dispatch, getState, action, next);
  }
  if (type === FormActions.BLUR) {
    return onBlur(dispatch, getState, action, next);
  }

  return next(action);
};
