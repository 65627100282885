import _ from 'lodash';
import {
  change,
  getFormInitialValues,
  getFormMeta,
  getFormValues,
} from 'redux-form';
import {
  CampaignColumns, Delimiters, Form, Urls,
} from '../../constants';
import {
  getFormFullFieldName,
  getPackageFormGroupName,
  isSafeToAutoChangeField,
} from '../../helpers/form';
import {
  getCampaignWatchableFieldsForCampaignNameChange,
  isNew,
} from '../../helpers/campaign';
import { getPackageWatchableFieldsForCampaignNameChange } from '../../helpers/package';
import { getGeneratedCampaignName } from '../../helpers/campaignName';

const isClonePage = (state) => {
  const { router: { location: { pathname } } } = state;
  if (!pathname) {
    return false;
  }

  return (pathname.indexOf(Urls.CLONE_PACKAGE) > -1);
};

const isCampaignNameWatchableField = (fieldName) => {
  let formattedFieldName = fieldName;
  if (fieldName.includes(Delimiters.DOT)) {
    const fieldNameParts = fieldName.split(Delimiters.DOT);

    formattedFieldName = fieldNameParts[fieldNameParts.length - 1];
  }

  return [
    ...getPackageWatchableFieldsForCampaignNameChange(),
    ...getCampaignWatchableFieldsForCampaignNameChange(),
  ].includes(formattedFieldName);
};

export default (dispatch, getState, action) => {
  const state = getState();

  const pkg = getFormValues(Form.NAME)(state);
  if (!pkg || !pkg.campaigns) {
    return;
  }

  const { campaigns } = pkg;
  const { meta: { field: activeField } } = action;

  const isClonePackagePage = isClonePage(state);
  const isActiveFieldCampaignNameWatchable = isCampaignNameWatchableField(activeField);

  const formInitialValues = getFormInitialValues(Form.NAME)(state);
  const formMeta = getFormMeta(Form.NAME)(state);

  campaigns.forEach((campaign, campaignIndex) => {
    const campaignFormMetadata = !_.isEmpty(formMeta) && !_.isEmpty(formMeta.campaigns)
      ? formMeta.campaigns[campaignIndex]
      : {};
    const campaignFormInitialFields = !_.isEmpty(formInitialValues) && !_.isEmpty(formInitialValues.campaigns)
      ? formInitialValues.campaigns[campaignIndex]
      : {};
    const isSafeToAutoChangeCampaignNameField = isSafeToAutoChangeField(
      CampaignColumns.CAMPAIGN_NAME.name,
      campaignFormMetadata,
      campaignFormInitialFields,
    );
    const shouldUpdateCampaignName = (
      (isNew(campaign) || (!isNew(campaign) && isClonePackagePage))
      && isActiveFieldCampaignNameWatchable
      && isSafeToAutoChangeCampaignNameField
    );
    if (!shouldUpdateCampaignName) {
      return;
    }

    const fullFormFieldName = getFormFullFieldName(
      getPackageFormGroupName(campaignIndex),
      CampaignColumns.CAMPAIGN_NAME.name,
    );

    dispatch(change(
      Form.NAME,
      fullFormFieldName,
      getGeneratedCampaignName(campaign, pkg),
    ));
  });
};
