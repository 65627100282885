import React from 'react';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';
import RateCardContainer from '../RateCardContainer';
import { campaignFormProps } from '../../proptypes';

const NewRateCardPage = () => {
  return (
    <RateCardContainer isNew />
  );
};

NewRateCardPage.propTypes = campaignFormProps;

export default WithHistoryTiming(NewRateCardPage, { pageName: PageNames.RATE_CARD_NEW });
