import { connect } from 'react-redux';
import RateCardSearch from './RateCardSearch';
import { onRateCardSetSearchQuery } from '../../actionCreators';

export const mapStateToProps = (state) => {
  const { RateCard: { searchQuery } } = state;
  return {
    searchQuery,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (value) => {
      dispatch(onRateCardSetSearchQuery(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardSearch);
