import { RateCardActions } from '../../constants';

import loadCardsList from './loadCardsList';
import addRateCard from './addRateCard';
import deleteRateCard from './deleteRateCard';
import updateRateCard from './updateRateCard';
import loadCardsAssetsList from './loadCardsAssetsList';
import addRateCardAsset from './addRateCardAsset';
import cloneRateCard from './cloneRateCard';
import updateRateCardAsset from './updateRateCardAsset';
import deleteRateCardAsset from './deleteRateCardAsset';
import createRateCardAssets from './createRateCardAssets';
import exportAssets from './exportAssets';
import deleteRateCardAssetBatch from './deleteRateCardAssetsBatch';
import publishRateCardAssetsBatch from './publishRateCardAssetsBatch';
import markRateCardAsPublished from './markRateCardAsPublished';
import refreshRateCardPlacements from './refreshRateCardPlacements';

export default (store) => (next) => (action) => {
  const { type } = action;
  const { dispatch, getState } = store;

  if (type === RateCardActions.LOAD_LIST) {
    return loadCardsList(store)(next)(action);
  }

  if (type === RateCardActions.RATE_CARD_ADD) {
    return addRateCard(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_CLONE) {
    return cloneRateCard(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_DELETE) {
    return deleteRateCard(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_UPDATE) {
    return updateRateCard(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSET_LOAD_LIST) {
    return loadCardsAssetsList(store)(next)(action);
  }

  if (type === RateCardActions.RATE_CARD_CREATE_ASSETS) {
    return createRateCardAssets(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSET_ADD) {
    return addRateCardAsset(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSET_UPDATE) {
    return updateRateCardAsset(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSETS_DELETE_BATCH) {
    return deleteRateCardAssetBatch(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSETS_PUBLISH) {
    return publishRateCardAssetsBatch(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSET_DELETE) {
    return deleteRateCardAsset(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_ASSET_EXPORT) {
    return exportAssets(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_MARK_AS_PUBLISHED) {
    return markRateCardAsPublished(dispatch, getState, action, next);
  }

  if (type === RateCardActions.RATE_CARD_REFRESH_PLACEMENTS) {
    return refreshRateCardPlacements(dispatch, getState, action, next);
  }

  return next(action);
};
