import {
  RateCardMediaPackage,
  RateCardPlacementPrices,
} from '../../../../../constants/rateCard';

const Consideration = [
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Shoveler - Standard (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 8,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Tile (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 2,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Category Hero (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 2,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Shoveler - Standard (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 12,
    numberPlacements: 12,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Tile (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 3,
    numberPlacements: 3,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Category Hero (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 4,
    numberPlacements: 4,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Shoveler - Standard (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 14,
    numberPlacements: 14,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Storefront Tile (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 6,
    numberPlacements: 6,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Category Hero (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 6,
    numberPlacements: 6,
  },
];

export default Consideration;
