import { CampaignColumns } from '../constants';

import { createRequiredFields } from '../helpers/createRequiredFields';

const PACKAGE_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.VERTICAL.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
  ],
  STATUS_APPROVED: [],
};

const CAMPAIGN_FIELDS = {
  BASE: [
    CampaignColumns.PAGETYPE.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
  ],
  STATUS_APPROVED: [
    CampaignColumns.CHANNEL.name,
    CampaignColumns.PAGE_ID.name,
    CampaignColumns.DESKTOP_SLOT.name,
    CampaignColumns.MOBILE_SLOT.name,
    CampaignColumns.CAMPAIGN_NAME.name,
    CampaignColumns.COPY.name,
  ],
};

const WFM_REQUIRED_FIELDS = createRequiredFields(
  PACKAGE_FIELDS,
  CAMPAIGN_FIELDS,
);

export default WFM_REQUIRED_FIELDS;
