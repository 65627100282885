import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { getCurrentPackageActiveTab } from '../helpers/form';
import { Form } from '../constants';

export const getFormActiveCampaign = createSelector(
  [
    (state) => getFormValues(Form.NAME)(state),
    getCurrentPackageActiveTab,
  ],
  (pkgFormValues, activeTabIndex) => {
    if (_.isEmpty(pkgFormValues) || _.isEmpty(pkgFormValues.campaigns)) {
      return {};
    }

    // eslint-disable-next-line no-shadow
    return pkgFormValues.campaigns.find((_, index) => index === activeTabIndex);
  },
);
