import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const Placement = ({ campaignColumns, currentTab, shouldShowMarketingManagerNotes }) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getMarketingManagerNotes = () => {
    if (!shouldShowMarketingManagerNotes) {
      return null;
    }
    return (
      <div className={styles.formGroupSection}>
        <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
          <FormField {...campaignField(campaignColumns.MARKETING_MANAGER_NOTES)} />
        </div>
      </div>
    );
  };

  const marketingManagerNotes = getMarketingManagerNotes();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>In-Store Placement</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.STATUS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PLACEMENT_IN_BUNDLE)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlFullWidth)}>
              <FormField {...campaignField(campaignColumns.ASINS)} />
            </div>
            <div className={classnames(styles.formControl, styles.formControlFullWidth)}>
              <FormField {...campaignField(campaignColumns.COPY)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PROMO_TYPE)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PROMO_ID)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...campaignField(campaignColumns.IS_DEAL)} />
            </div>
          </div>
          {marketingManagerNotes}
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
Placement.propTypes = formCampaignColumns;

export default Placement;
