import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import Button from '@amzn/meridian/button';
import styles from './Comment.module.scss';
import UserAvatar from '../UserAvatar';
import { commentProps } from '../../proptypes';

const Comment = (props) => {
  const {
    campaignId,
    createdAt,
    createdBy,
    formattedCreatedAt,
    message,
    isOwnComment,
    onDeleteComment,
  } = props;

  const sanitizedUsername = sanitizeHtml(createdBy);
  const sanitizedMessage = sanitizeHtml(message);
  const isActionableComment = isOwnComment(createdBy);

  const avatarBlock = (
    <section className={styles.photoContainer}>
      <div className={styles.photoWrapper}>
        <UserAvatar
          username={createdBy}
          disableShadow
        />
      </div>
    </section>
  );
  const getContentBlock = () => {
    const deleteButton = isActionableComment
      ? (
        <span className={styles.replyBack}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              /* eslint-disable-next-line no-alert */
              if (window.confirm('Are you sure you want to delete this comment? This action cannot be undone.')) {
                onDeleteComment({ campaignId, createdAt });
              }
            }}
          >
            Delete
          </Button>
        </span>
      )
      : null;

    return (
      <div className={styles.mainContainer}>
        <section className={styles.messageContainer}>
          <span className={styles.username}>{sanitizedUsername}</span>
          <div className={styles.message}>
            {sanitizedMessage}
          </div>
        </section>
        <section className={styles.controlsContainer}>
          <span className={styles.dateTime}>
            {formattedCreatedAt}
          </span>
          {deleteButton}
        </section>
      </div>
    );
  };

  const contentBlock = getContentBlock();

  return (
    <article
      className={classnames({
        [styles.commentContainer]: true,
        [styles.ownComment]: isActionableComment,
      })}
    >
      <div className={styles.contentWrapper}>
        {avatarBlock}
        {contentBlock}
      </div>
    </article>
  );
};

Comment.propTypes = {
  ...commentProps,
  formattedCreatedAt: PropTypes.string.isRequired,
  isOwnComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
};

export default Comment;
