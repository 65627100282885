import { API, graphqlOperation } from 'aws-amplify';
import { getBusinessStartYearFromItem } from '../../helpers/businessStartYear';
import {
  appsyncToLocal,
  formatPackageForMutationInput,
  getExcludedFields,
  localToAppsync,
} from '../../helpers/translateCampaigns';
import {
  BulkImportActions,
  CampaignColumns,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import {
  onAddPackage,
  onBulkImportActionCompleted,
  onCreatedPackageReceived,
  onOpenToast,
} from '../../actionCreators';
import { getUsername } from '../../selectors/user';
import {
  getCurrentBusinessStartYear,
  getSelectedBusiness,
  isWebsiteOmnichannelViewMode,
} from '../../selectors/sitewide';
import { parseGraphQLResponse } from '../../helpers/api';
import * as mutatations from '../../graphql/mutations';
import { createAddCampaignsPromiseChain } from './addCampaign';

const createAddPackagePromise = (dispatch, getState, action) => {
  const state = getState();
  const { Meta: { metadata } } = state;
  const username = getUsername(state);
  const business = getSelectedBusiness(state);
  const isOmnichannelMode = isWebsiteOmnichannelViewMode(state);

  const {
    payload: {
      newPackage,
      options,
    },
  } = action;

  const { isClone, isBulkAction } = options;
  const packageToSend = localToAppsync(newPackage);
  const { startDateTime, endDateTime, campaigns } = packageToSend;
  packageToSend[CampaignColumns.BUSINESS_START_YEAR.name] = startDateTime && endDateTime
    ? getBusinessStartYearFromItem(packageToSend, business)
    : getCurrentBusinessStartYear(state);
  getExcludedFields(['id', 'version', 'campaigns'])
    .forEach((excludedField) => {
      delete packageToSend[excludedField];
    });

  const dataToSend = {
    input: {
      package: formatPackageForMutationInput(packageToSend, isClone),
      username,
    },
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: dataToSend,
    action: MetricNames.ADD_PACKAGE,
    username,
  };

  submitStringMetric(MetricNames.ADD_PACKAGE, metricContext);

  // eslint-disable-next-line no-shadow
  const assignRefMarkerToCampaigns = (campaigns, packageRefMarker) => {
    return campaigns.map((campaign) => {
      return {
        ...campaign,
        refMarker: isOmnichannelMode ? '' : packageRefMarker,
      };
    });
  };

  const addPackage = (params) => {
    return API.graphql(graphqlOperation(mutatations.createPackage, params));
  };

  const onParseResponse = (response) => {
    const rawPackage = parseGraphQLResponse(response, 'createPackage');

    return appsyncToLocal(rawPackage, metadata);
  };

  const onAddPackageToState = (translatedPackage) => {
    dispatch(onCreatedPackageReceived(translatedPackage));

    if (isBulkAction) {
      dispatch(onBulkImportActionCompleted(
        translatedPackage.refMarker,
        BulkImportActions.SUCCESS,
      ));
    } else {
      dispatch(onOpenToast(Language.PACKAGE_CREATE_SUCCESS));
    }

    return translatedPackage;
  };

  // eslint-disable-next-line no-shadow
  const onCreateCampaigns = (pkg, campaigns) => {
    if (!campaigns) {
      return;
    }
    // eslint-disable-next-line camelcase
    const { id, business_startYear, refMarker } = pkg;

    // eslint-disable-next-line consistent-return
    return createAddCampaignsPromiseChain(
      assignRefMarkerToCampaigns(campaigns, refMarker),
      id,
      business_startYear,
      dispatch,
      getState,
      options,
    );
  };

  const onError = (error) => {
    submitAppSyncError(error, metricContext);

    if (isBulkAction) {
      dispatch(onBulkImportActionCompleted(
        packageToSend.refMarker,
        BulkImportActions.ERROR,
      ));
    } else {
      dispatch(onOpenToast(Language.PACKAGE_ADD_ERROR));
    }
  };

  return addPackage(dataToSend)
    .then(onParseResponse)
    .then(onAddPackageToState)
    .then((translatedPackage) => onCreateCampaigns(translatedPackage, campaigns))
    .catch(onError);
};

export const createAddPackagesPromiseChain = (packages, dispatch, getState, options) => {
  const addPackagesPromiseChain = packages.map((pkg) => () => createAddPackagePromise(dispatch, getState, onAddPackage(pkg, options)));
  return addPackagesPromiseChain.reduce((prev, cur) => prev.then(cur), Promise.resolve());
};

export default (dispatch, getState, action, next) => {
  createAddPackagePromise(dispatch, getState, action);
  return next(action);
};
