import React from 'react';
import PropTypes from 'prop-types';
import Tag from '@amzn/meridian/tag';
import { FixedUtilizationFilters } from '../../../constants/utilization';
import styles from './UtilizationFilterTagList.module.scss';
import { Delimiters } from '../../../constants';

const UtilizationFilterTagList = (props) => {
  const {
    filters,
    onRemoveFilter,
  } = props;

  if (!filters || !filters.length) {
    return null;
  }

  const onRemoveTag = (filterName) => () => onRemoveFilter(filterName);

  const getTagList = () => {
    const list = filters
      .filter(([, value]) => value)
      .map(([key, value]) => {
        const formattedValue = Array.isArray(value)
          ? value.join(`${Delimiters.COMMA} `)
          : value;

        return (
          <li
            key={key}
            className={styles.item}
          >
            <Tag onClose={!FixedUtilizationFilters.includes(key) ? onRemoveTag(key) : null}>{formattedValue}</Tag>
          </li>
        );
      });

    return (
      <ul className={styles.list}>
        {list}
      </ul>
    );
  };

  return getTagList();
};

UtilizationFilterTagList.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onRemoveFilter: PropTypes.func.isRequired,
};
UtilizationFilterTagList.defaultProps = {
  filters: [],
};

export default UtilizationFilterTagList;
