import { connect } from 'react-redux';
import GridViewHeader from './GridViewHeader';
import { areRowsExpanded } from '../../selectors/gridView';
import { onToggleRowsExpanded } from '../../actionCreators';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';

export const mapStateToProps = (state) => {
  return {
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    areRowsExpanded: areRowsExpanded(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleRowsExpanded: () => {
      dispatch(onToggleRowsExpanded());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewHeader);
