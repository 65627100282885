import { getUsername } from '../../selectors/user';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import { setPackagesWithCampaigns } from '../../actionCreators';
import { submitStringMetric } from '../../metrics';
import { getPackageLoadingStatus } from '../../selectors/package';
import { getCampaignLoadingStatus } from '../../selectors/campaign';
import { getSortedCampaigns } from '../../helpers/campaign';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const { payload: { businessStartYear } } = action;
  const { GridViewPage: { raw: { campaigns, packages } } } = state;
  const campaignLoadingStatus = getCampaignLoadingStatus(state);
  const packageLoadingStatus = getPackageLoadingStatus(state);

  if (!campaignLoadingStatus && !packageLoadingStatus) {
    return next(action);
  }

  if (campaignLoadingStatus[businessStartYear] || packageLoadingStatus[businessStartYear]) {
    return next(action);
  }

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: { businessStartYear },
    action: MetricNames.ASSIGN_CAMPAIGNS_TO_PACKAGES,
    username,
  };

  const packagesForBusinessStartYear = packages[businessStartYear];
  const campaignsForBusinessStartYear = campaigns[businessStartYear];
  const combinedPackagesForBusinessStartYear = { ...packagesForBusinessStartYear };

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const campaignId in campaignsForBusinessStartYear) {
    const campaign = campaignsForBusinessStartYear[campaignId];
    if (campaign.packageId) {
      const parentPackage = combinedPackagesForBusinessStartYear[campaign.packageId];
      if (parentPackage) {
        if (Array.isArray(parentPackage.campaigns)) {
          const existingCampaigns = [...parentPackage.campaigns];
          existingCampaigns.push(campaign);
          parentPackage.campaigns = getSortedCampaigns(existingCampaigns);
        } else {
          parentPackage.campaigns = [campaign];
        }
      }
    }
  }

  dispatch(setPackagesWithCampaigns(businessStartYear, combinedPackagesForBusinessStartYear));

  submitStringMetric(MetricNames.ASSIGN_CAMPAIGNS_TO_PACKAGES, metricContext);
  return next(action);
};
