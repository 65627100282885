import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import { CampaignTypes, Language } from '../../constants';
import sitewideStyles from '../../styles/sitewide.module.scss';
import styles from './PackageForm.module.scss';
import { formCampaignColumns } from '../../proptypes';
import BasicInfo from './Sections/BasicInfo';
import AutomatedFieldsSummary from './Sections/AutomatedFieldsSummary';
import SubmitButtons from './Sections/SubmitButtons/SubmitButtons';
import OnlinePlacement from './Sections/Online/Placement';
import OnlineContentAsins from './Sections/Online/ContentAsins';
import OnlinePromotionalInfo from './Sections/Online/PromotionalInfo';
import OnlineScheduling from './Sections/Online/Scheduling';
// eslint-disable-next-line import/no-duplicates
import OnlineAutomatedFields from './Sections/AutomatedFields';
import InStorePlacement from './Sections/InStore/Placement';
import InStoreScheduling from './Sections/InStore/Scheduling';
// eslint-disable-next-line import/no-duplicates
import InStoreAutomatedFields from './Sections/AutomatedFields';
import OnsiteTrafficPlacement from './Sections/OnlineTraffic/Placement';
import OnsiteTrafficContentAsins from './Sections/OnlineTraffic/ContentAsins';
import OnsiteTrafficScheduling from './Sections/OnlineTraffic/Scheduling';
import OnsiteTrafficAutomatedFields from './Sections/OnlineTraffic/AutomatedFields';
import OnsiteTrafficBasicInfo from './Sections/OnlineTraffic/BasicInfo';

const PackageForm = (props) => {
  const {
    onModalClose,
    onCreate,
    onClone,
    onUpdate,
    handleSubmit,
    submitting,
    isNew,
    isClone,
    isTrafficMode,
    isLoading,
    isNotFound,
    isRefMarkerEditable,
    isOmnichannelMode,
    isLoadingCampaigns,
    isReadOnlyMode,
    campaignColumns,
    showConfirmation,
    campaign,
  } = props;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Loader type="circular" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Alert>{Language.GENERIC_NOT_FOUND}</Alert>
        </div>
      </div>
    );
  }

  const processCampaign = (data, { hasFailedSlotsValidation = false } = {}) => {
    if (isNew) {
      onCreate(data, { hasFailedSlotsValidation });
    } else if (isClone) {
      onClone(data);
    } else {
      onUpdate(data);
    }
  };

  const processSubmit = (data) => {
    if (!showConfirmation) {
      processCampaign(data);

      onModalClose();
      return;
    }

    if (window.confirm(Language.SLOT_OVERBOOKED_CHECK_BYPASS_CONFIRMATION_MESSAGE)) {
      processCampaign(data, { hasFailedSlotsValidation: true });

      onModalClose();
    }
  };

  const getAutomatedFieldsSummarySection = () => {
    return <AutomatedFieldsSummary campaignColumns={campaignColumns} />;
  };

  const getBaseInfoSection = () => {
    const automatedFieldsSummary = isOmnichannelMode
      ? getAutomatedFieldsSummarySection()
      : null;

    return (
      <>
        <BasicInfo campaignColumns={campaignColumns} />
        {automatedFieldsSummary}
      </>
    );
  };

  const getOnsiteTrafficBasicInfoSection = () => {
    return <OnsiteTrafficBasicInfo campaignColumns={campaignColumns} />;
  };

  const getInStoreSections = () => {
    return (
      <>
        <InStorePlacement campaignColumns={campaignColumns} />
        <InStoreScheduling campaignColumns={campaignColumns} />
        <InStoreAutomatedFields
          campaignColumns={campaignColumns}
          isRefMarkerEditable={isRefMarkerEditable}
        />
      </>
    );
  };

  const getOnlineSections = () => {
    return isTrafficMode ? (
      <>
        <OnsiteTrafficPlacement campaignColumns={campaignColumns} />
        <OnsiteTrafficContentAsins campaignColumns={campaignColumns} />
        <OnlinePromotionalInfo campaignColumns={campaignColumns} />
        <OnsiteTrafficScheduling campaignColumns={campaignColumns} />
        <OnsiteTrafficAutomatedFields campaignColumns={campaignColumns} />
      </>
    )
      : (
        <>
          <OnlinePlacement campaignColumns={campaignColumns} />
          <OnlineContentAsins campaignColumns={campaignColumns} />
          <OnlinePromotionalInfo campaignColumns={campaignColumns} />
          <OnlineScheduling campaignColumns={campaignColumns} />
          <OnlineAutomatedFields
            campaignColumns={campaignColumns}
            isRefMarkerEditable={isRefMarkerEditable}
          />
        </>
      );
  };

  const getNonOmnichannelExperience = () => {
    const sections = [
      isTrafficMode ? getOnsiteTrafficBasicInfoSection() : getBaseInfoSection(),
    ];
    if (isLoadingCampaigns) {
      sections.push(<p>Loading sections...</p>);
    } else {
      [
        getOnlineSections(),
      ].forEach((section) => {
        sections.push(section);
      });
    }

    return sections;
  };

  const getOmnichannelExperience = () => {
    const defaultSection = getBaseInfoSection();
    if (!campaign) {
      return defaultSection;
    }

    const { campaignType } = campaign;
    switch (campaignType) {
      case CampaignTypes.IN_STORE:
        return getInStoreSections();
      case CampaignTypes.ONLINE:
        return getOnlineSections();
      default:
        return getOnlineSections();
    }
  };

  const getContent = () => {
    return isOmnichannelMode
      ? getOmnichannelExperience()
      : getNonOmnichannelExperience();
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(processSubmit)}
    >
      {getContent()}
      <SubmitButtons isButtonDisabled={submitting || isReadOnlyMode} />
    </form>
  );
};

PackageForm.propTypes = {
  isNew: PropTypes.bool,
  isClone: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...formCampaignColumns,
};

PackageForm.defaultProps = {
  isNew: false,
  isClone: false,
};

export default PackageForm;
