import { connect } from 'react-redux';
import { isCurrentUserMelodyAdmin } from 'src/selectors/user';
import Component from './GridViewActionCell';

import {
  onCommentsModalOpen,
  onCommentsSetActiveCampaign,
  onCreateCsCampaignOpenModal,
  onCreateCsCampaignSetCampaign,
  onDeletePackage,
  onThirdPartyIFrameOpenComposerForCampaign,
  onThirdPartyIFrameOpenJSONBourneForCampaign,
  onThirdPartyIFrameOpenMelodyForCampaign,
} from '../../actionCreators';
import { isContentSymphonyIntegrationEnabled } from '../../helpers/common';
import {
  isBusinessFreshTraffic,
  isReadOnlyMode,
  isWebsiteOmnichannelViewMode,
} from '../../selectors/sitewide';
import { getFirstOnlineCampaign } from '../../helpers/campaign';

export const mapStateToProps = (state, { id }) => {
  const {
    ThirdPartyIFrame: {
      composerEnabled,
    },
    Notifications: {
      isExperienceEnabled,
    },
    Sitewide: {
      selectedBusiness,
      isActionBarExpanded,
    },
  } = state;

  const contentSymphonyIntegrationEnabled = isContentSymphonyIntegrationEnabled(selectedBusiness);

  return {
    id,
    isActionBarExpanded,
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    isFreshTrafficBusiness: isBusinessFreshTraffic(state),
    isReadOnlyMode: isReadOnlyMode(state),
    isMelodyEnabled: isCurrentUserMelodyAdmin(state),
    composerEnabled,
    notificationsEnabled: isExperienceEnabled,
    contentSymphonyIntegrationEnabled,
  };
};

export const mapDispatchToProps = (dispatch, { item }) => {
  return {
    onDelete() {
      dispatch(onDeletePackage(item));
    },
    onOpenComposer() {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        if (firstOnlineCampaign) {
          dispatch(onThirdPartyIFrameOpenComposerForCampaign(firstOnlineCampaign.id, item.id));
        }
      } else {
        dispatch(onThirdPartyIFrameOpenComposerForCampaign(item.id, item.packageId));
      }
    },
    onOpenMelody() {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        if (firstOnlineCampaign) {
          dispatch(onThirdPartyIFrameOpenMelodyForCampaign(firstOnlineCampaign.id, item.id));
        }
      } else {
        dispatch(onThirdPartyIFrameOpenMelodyForCampaign(item.id, item.packageId));
      }
    },
    onOpenComments() {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        if (firstOnlineCampaign) {
          dispatch(onCommentsSetActiveCampaign({ id: firstOnlineCampaign.id, packageId: item.id }));
        }
      } else {
        dispatch(onCommentsSetActiveCampaign(item));
      }
      dispatch(onCommentsModalOpen());
    },
    onOpenJSONBourne() {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        if (firstOnlineCampaign) {
          dispatch(onThirdPartyIFrameOpenJSONBourneForCampaign(firstOnlineCampaign.id, item.id));
        }
      } else {
        dispatch(onThirdPartyIFrameOpenJSONBourneForCampaign(item.id, item.packageId));
      }
    },
    onOpenContentSymphony() {
      dispatch(onCreateCsCampaignOpenModal());
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        if (firstOnlineCampaign) {
          dispatch(onCreateCsCampaignSetCampaign(firstOnlineCampaign));
        }
      } else {
        dispatch(onCreateCsCampaignSetCampaign(item));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
