import { RateCardActions } from '../../constants';
import {
  RateCardConfig,
  RateCardReportType,
  RateCardTabsList,
} from '../../constants/rateCard';
import { getLatestConfigName } from '../../helpers/getRateCardConfig';

export const initialState = {
  cards: [],
  assets: [],
  assetsIdHash: {},
  pageNumber: 0,
  pageNumberAssets: 0,
  cardsPerPage: RateCardConfig.LIMIT_PER_PAGE,
  assetsPerPage: RateCardConfig.ASSETS_LIMIT_PER_PAGE,
  isListLoaded: false,
  isAssetsListLoaded: false,
  currentTab: RateCardTabsList.RATE_CARD.name,
  isReportModalOpen: false,
  reportType: RateCardReportType.EXTERNAL.name,
  searchQuery: '',
  configName: getLatestConfigName(),
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case RateCardActions.RATE_CARD_RESET_ASSETS:
    case RateCardActions.RATE_CARD_ASSETS_DELETE_BATCH: {
      return {
        ...state,
        assets: [],
        isListLoaded: true,
        assetsIdHash: {},
      };
    }
    case RateCardActions.SET_LIST: {
      const { payload: { cards } } = action;

      return {
        ...state,
        cards: [
          ...state.cards,
          ...cards,
        ],
      };
    }
    case RateCardActions.SET_LIST_LOADED: {
      return {
        ...state,
        isListLoaded: true,
      };
    }
    case RateCardActions.SET_PAGE_NUMBER: {
      const { payload: { pageNumber } } = action;
      return {
        ...state,
        pageNumber,
      };
    }
    case RateCardActions.RATE_CARD_ADD_RECEIVED: {
      const { payload: { rateCard } } = action;

      if (state.cards.find((card) => card.id === rateCard.id)) {
        return state;
      }

      const newCards = [...state.cards].concat(rateCard);
      return {
        ...state,
        cards: newCards,
      };
    }
    case RateCardActions.RATE_CARD_DELETE: {
      const { payload: { rateCard } } = action;

      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== rateCard.id),
      };
    }
    case RateCardActions.RATE_CARD_UPDATED_RECEIVED: {
      const { payload: { rateCard } } = action;

      return {
        ...state,
        cards: state.cards.map((card) => {
          if (card.id !== rateCard.id) {
            return card;
          }

          return rateCard;
        }),
      };
    }
    case RateCardActions.RATE_CARD_ASSET_SET_LIST: {
      const { payload: { assets } } = action;

      const assetsIdHash = {};
      const assetsToAdd = assets.filter((asset) => {
        assetsIdHash[asset.id] = true;
        return !state.assetsIdHash[asset.id];
      });

      return {
        ...state,
        assets: [...state.assets, ...assetsToAdd],
        assetsIdHash: { ...state.assetsIdHash, ...assetsIdHash },
      };
    }
    case RateCardActions.RATE_CARD_ASSET_SET_LIST_LOADED: {
      return {
        ...state,
        isAssetsListLoaded: true,
      };
    }
    case RateCardActions.SET_PAGE_NUMBER_ASSETS: {
      const { payload: { pageNumberAssets } } = action;
      return {
        ...state,
        pageNumberAssets,
      };
    }
    case RateCardActions.RATE_CARD_ASSET_UPDATE_RECEIVED: {
      const {
        payload: {
          asset,
        },
      } = action;

      return {
        ...state,
        assets: state.assets.map((curAsset) => {
          if (curAsset.id !== asset.id) {
            return curAsset;
          }

          return asset;
        }),
      };
    }
    case RateCardActions.RATE_CARD_ASSET_DELETE: {
      const { payload: { asset } } = action;

      return {
        ...state,
        assets: state.assets.filter((currentAsset) => currentAsset.id !== asset.id),
      };
    }
    case RateCardActions.SET_TAB: {
      const { payload: { tabName } } = action;

      return {
        ...state,
        currentTab: tabName,
      };
    }
    case RateCardActions.RESET_TAB: {
      return {
        ...state,
        currentTab: initialState.currentTab,
      };
    }
    case RateCardActions.OPEN_REPORT_MODAL: {
      const { payload: { reportType } } = action;
      return {
        ...state,
        isReportModalOpen: true,
        reportType,
      };
    }
    case RateCardActions.CLOSE_REPORT_MODAL: {
      return {
        ...state,
        isReportModalOpen: false,
      };
    }
    case RateCardActions.SET_SEARCH_QUERY: {
      const { payload: { searchQuery } } = action;

      return {
        ...state,
        searchQuery,
      };
    }
    case RateCardActions.SET_CONFIG_NAME: {
      const { payload: { configName } } = action;

      return {
        ...state,
        configName,
      };
    }
    default: {
      return state;
    }
  }
};
