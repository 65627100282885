import { MetadataEditorActions } from 'src/constants';

export const onMetadataEditorSelectField = (field) => ({
  type: MetadataEditorActions.SELECT_FIELD,
  data: field,
});
export const onMetadataEditorSetDependencies = (dependencies) => ({
  type: MetadataEditorActions.SET_DEPENDENCIES,
  data: dependencies,
});
export const onMetadataEditorSetDependentOption = ({ name, selection }) => ({
  type: MetadataEditorActions.SET_DEPENDENT_OPTION,
  data: { name, selection },
});
export const onMetadataEditorAddOption = (option) => ({
  type: MetadataEditorActions.ADD_OPTION,
  data: option,
});
export const onMetadataEditorDeleteOption = (option) => ({
  type: MetadataEditorActions.DELETE_OPTION,
  data: option,
});
export const onMetadataEditorAddOptionForField = (data) => ({
  type: MetadataEditorActions.ADD_OPTION_FOR_FIELD,
  data,
});
export const onMetadataEditorDeleteOptionForField = (data) => ({
  type: MetadataEditorActions.DELETE_OPTION_FOR_FIELD,
  data,
});
export const onMetadataEditorSetQuickCreateModalVisibility = (isVisible) => ({
  type: MetadataEditorActions.SET_QUICK_CREATE_MODAL_VISIBILITY,
  data: isVisible,
});
export const onMetadataEditoQuickCreateModalSave = (data) => ({
  type: MetadataEditorActions.QUICK_CREATE_MODAL_SAVE,
  data,
});
