import React from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Tooltip from '@amzn/meridian/tooltip';
import { getUtilizationCellStyles } from '../../../styles/utilization';
import CalendarWeek from '../CalendarWeek';
import { campaignProps } from '../../../proptypes';
import {
  Language,
  UrlSearchQuery,
  Urls,
} from '../../../constants';
import { openCampaignModal } from '../../../helpers/campaignModal';
import { getTemplateString } from '../../../helpers/common';
import siteWideStyle from '../../../styles/sitewide.module.scss';
import styles from './inStoreUtilizationRender.module.scss';

const InStoreUtilizationRender = (events) => {
  const history = useHistory();

  // eslint-disable-next-line react/destructuring-assignment
  if (!events || !events.length) {
    return null;
  }

  const onOpenCampaign = (packageId, id) => () => openCampaignModal(
    history,
    `${Urls.EDIT_PACKAGE}/${packageId}`,
    Urls.IN_STORE_UTILIZATION,
    `${UrlSearchQuery.CAMPAIGN_ID}=${id}`,

  );

  // eslint-disable-next-line react/destructuring-assignment
  return events.map((event) => {
    const { start, end, campaign } = event;
    const {
      id,
      packageId,
      campaignName,
      status,
      vendor,
      vertical,
      placement,
      sov,
    } = campaign;

    const startDate = moment(start).format('MMM Do YYYY');
    const endDate = moment(end).format('MMM Do YYYY');
    return {
      start,
      end,
      render: (props) => {
        const newProps = {
          ...props,
          highlightingClass: classnames({
            [styles.status]: true,
            ...getUtilizationCellStyles(status),
          }),
        };
        const weekTitle = getTemplateString(Language.UTILIZATION_WEEK_TITLE, {
          sov: sov || '0',
          campaignName,
          status,
        });
        const weekContent = getTemplateString(Language.UTILIZATION_WEEK_CONTENT, {
          startDate,
          endDate,
          vertical,
          vendor,
          placement,
        });

        return (
          <CalendarWeek {...newProps}>
            <Tooltip position="top" title={Language.UTILIZATION_WEEK_TITLE_TOOLTIP}>
              <Link
                onClick={onOpenCampaign(packageId, id)}
                className={classnames([siteWideStyle.link, styles.link])}
              >
                <h3 className={styles.weekTitle}>
                  {weekTitle}
                </h3>
              </Link>
            </Tooltip>
            <p className={styles.weekContent}>
              {weekContent}
            </p>
          </CalendarWeek>
        );
      },
    };
  });
};

InStoreUtilizationRender.propTypes = {
  events: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    campaign: campaignProps,
  }),
};
InStoreUtilizationRender.defaultProps = {
  events: [],
};

export default InStoreUtilizationRender;
