import {
  CustomViewActions,
  FlashFillActions,
  GridViewActions,
} from 'src/constants';

export const onCreatedPackageReceived = (createdPackage) => ({
  type: GridViewActions.CREATED_PACKAGE_RECEIVED,
  payload: {
    createdPackage,
  },
});
export const onUpdatedPackageReceived = (updatedPackage) => ({
  type: GridViewActions.UPDATED_PACKAGE_RECEIVED,
  payload: {
    updatedPackage,
  },
});
export const onCreatedCampaignReceived = (campaign) => ({
  type: GridViewActions.CREATED_CAMPAIGN_RECEIVED,
  payload: { campaign },
});
export const onUpdatedCampaignReceived = (campaign) => ({
  type: GridViewActions.UPDATED_CAMPAIGN_RECEIVED,
  payload: {
    campaign,
  },
});
export const onToggleRowsExpanded = () => ({
  type: GridViewActions.TOGGLE_ROWS_EXPANDED,
});
export const onSetExpandedRowsOverride = (rowId, isOpen) => ({
  type: GridViewActions.SET_EXPANDED_ROWS_OVERRIDE,
  payload: {
    rowId,
    isOpen,
  },
});
export const onFlashFillPage = (id, packageId, cellValue, columnName) => ({
  type: FlashFillActions.FILL_PAGE,
  payload: {
    id,
    packageId,
    cellValue,
    columnName,
  },
});
export const onFlashFillPackage = (id, packageId, cellValue, columnName) => ({
  type: FlashFillActions.FILL_PACKAGE,
  payload: {
    id,
    packageId,
    cellValue,
    columnName,
  },
});
export const onCustomViewSetUseView = (shouldShow) => ({
  type: CustomViewActions.SET_USE_VIEW,
  payload: shouldShow,
});
export const onCustomViewSetColumnOrder = (columnOrder) => ({
  type: CustomViewActions.SET_COLUMN_ORDER,
  payload: columnOrder,
});
export const onCustomViewSetModalVisible = (showModal) => ({
  type: CustomViewActions.SET_MODAL_VISIBLE,
  payload: showModal,
});
export const onUpdateCampaignColumn = (
  item,
  columnName,
  newValue,
) => {
  return {
    type: GridViewActions.UPDATE_CAMPAIGN_COLUMN,
    payload: {
      item,
      columnName,
      newValue,
    },
  };
};
export const onSetPaginationPageAction = (data) => ({
  type: GridViewActions.SET_PAGE,
  data,
});
export const onSelectCell = (data) => ({
  type: GridViewActions.SELECT_CELL,
  payload: data,
});
export const onActiveCellInputUpdate = (value) => ({
  type: GridViewActions.ACTIVE_CELL_INPUT_UPDATE,
  payload: {
    value,
  },
});
export const onMoveActiveCell = (direction) => ({
  type: GridViewActions.MOVE_ACTIVE_CELL,
  payload: { direction },
});
export const onResetActiveCell = () => ({ type: GridViewActions.RESET_ACTIVE_CELL });
export const onActiveCellInputSubmit = () => ({ type: GridViewActions.ACTIVE_CELL_INPUT_SUBMIT });
