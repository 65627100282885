import React from 'react';
import classnames from 'classnames';
import styles from '../../RateCardForm.module.scss';
import FormField from '../../../PackageForm/FormField';
import { formCampaignColumns } from '../../../../proptypes';

const GeneralInfo = ({ campaignColumns }) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>General information</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <p className={styles.formSubGroupTitle}>General</p>
          <div className={classnames([styles.formGroupSection, styles.formGroupSectionVertical])}>
            <div className={styles.formControl}>
              <FormField
                {...campaignColumns.VENDOR}
              />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.MERCHANT_INVESTMENT} />
            </div>
            <div className={styles.formControl}>
              <FormField
                {...campaignColumns.VERTICAL}
              />
            </div>
          </div>
        </div>
        <div className={styles.formGroupContent}>
          <p className={styles.formSubGroupTitle}>Options</p>
          <div className={classnames([styles.formGroupSection, styles.formGroupSectionVertical])}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.IS_VSSM} />
              <FormField {...campaignColumns.IS_EARLY_BIRD} />
            </div>
            <div className={styles.formControl}>
              <FormField
                {...campaignColumns.MEDIA_PACKAGE}
              />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.MARKETING_MANAGER} />
            </div>
          </div>
        </div>
        <div
          className={classnames([
            styles.formGroupContent,
            styles.formGroupContentGeneral,
          ])}
        >
          <p className={styles.formSubGroupTitle}>Notes</p>
          <div className={classnames([styles.formGroupSection, styles.formGroupSectionVertical])}>
            <div className={styles.formControl}>
              <FormField
                {...campaignColumns.NOTE}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GeneralInfo.propTypes = {
  ...formCampaignColumns,
};

export default GeneralInfo;
