import { connect } from 'react-redux';
import { onCreateCsCampaignSetCampaignData } from 'src/actionCreators';

import Description from './Description';

export const mapStateToProps = (state) => {
  const { ContentSymphony: { creativeData: { description = '' } } } = state;
  return {
    description,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onChange(description) {
      dispatch(onCreateCsCampaignSetCampaignData({ creativeData: { description } }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);
