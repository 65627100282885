import { connect } from 'react-redux';
import NonOmnichannelPackageTabs from './NonOmnichannelPackageTabs';
import { onPackageFormSetTab } from '../../../actionCreators';
import { getNonomnichannelPackageTab } from '../../../selectors/packageTabs';
import { getCommentsList } from '../../../selectors/commentsSelector';
import {
  PackageTabTypes,
  nonOmnichannelPackageTabList,
} from '../../../constants';

const getTabList = (shouldShowComments = true, commentsCount = 0) => {
  // eslint-disable-next-line no-unused-vars
  return Object.entries(nonOmnichannelPackageTabList).map(([_, content]) => {
    const { name, display } = content;

    const isCommentsTab = name === nonOmnichannelPackageTabList.COMMENTS.name;
    if (isCommentsTab) {
      return shouldShowComments
        ? {
          name,
          display: `${display} (${commentsCount})`,
        }
        : null;
    }

    return content;
  }).filter((isNotNull) => isNotNull);
};

export const mapStateToProps = (state, { isAssetEditMode }) => {
  const commentsCount = getCommentsList(state).length;
  return {
    tabs: getTabList(!isAssetEditMode, commentsCount),
    currentTab: getNonomnichannelPackageTab(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchTab(tabName) {
      dispatch(onPackageFormSetTab(tabName, PackageTabTypes.NON_OMNICHANNEL));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonOmnichannelPackageTabs);
