import { onCreateCsCampaignSetMetadata } from 'src/actionCreators';
import { BusinessTypes } from 'src/constants';
import intentData from 'src/fixtures/symphonyIntents';
import objectiveData from 'src/fixtures/symphonyObjectives';

const accessControlGroups = {
  [BusinessTypes.FRESH.name]: ['F3_Symphony_Access'], // 'RemoteSearchAggregatorService_Beta' can be used in Beta for testing
  [BusinessTypes.WFM.name]: ['F3_Symphony_Access'],
  [BusinessTypes.WFMC_NA.name]: ['F3_Symphony_Access'],
  [BusinessTypes.NA_3P.name]: ['F3_Symphony_Access'],
  [BusinessTypes.FRESH_UK.name]: ['RBS_Vulcan'],
  [BusinessTypes.FRESH_DE.name]: ['RBS_Vulcan'],
  [BusinessTypes.FRESH_ES.name]: ['RBS_Vulcan'],
  [BusinessTypes.FRESH_IT.name]: ['RBS_Vulcan'],
  [BusinessTypes.FRESH_JP.name]: ['JP-F3-Merchandising-ACG2'],
  [BusinessTypes.UK_3P.name]: ['RBS_Vulcan'],
  [BusinessTypes.DE_3P.name]: ['RBS_Vulcan'],
  [BusinessTypes.IT_3P.name]: ['RBS_Vulcan'],
  [BusinessTypes.ES_3P.name]: ['RBS_Vulcan'],
  [BusinessTypes.FR_3P.name]: ['RBS_Vulcan'],
  [BusinessTypes.JP_3P.name]: ['JP-F3-Merchandising-ACG2'],
};

// business groups
const GW_AMAZON_FRESH = {
  label: 'GW-AmazonFresh',
  value: 'GW-AmazonFresh', // 'ember-alr-dev' can be used in Beta for testing
};

const GW_PRIME_NOW = {
  label: 'GW-PrimeNow',
  value: 'GW-PrimeNow',
};

const GW_WHOLE_FOODS_MARKET = {
  label: 'GW-WholeFoodsMarket',
  value: 'GW-WholeFoodsMarket',
};

const RBS_VULCAN = {
  label: 'RBS_Vulcan',
  value: '978e3c1f-cfc1-4305-b8af-6e3a0bd9e994',
};

const JP_F3_FRESH_MERCHANDISING = {
  label: 'JP-F3-Fresh-Merchandising',
  value: 'b6bd7ef5-962b-441a-90d7-7c67c8b4ee82',
};

const businessGroups = {
  [BusinessTypes.FRESH.name]: [GW_AMAZON_FRESH],
  [BusinessTypes.WFM.name]: [GW_PRIME_NOW, GW_WHOLE_FOODS_MARKET],
  [BusinessTypes.WFMC_NA.name]: [GW_PRIME_NOW],
  [BusinessTypes.NA_3P.name]: [GW_PRIME_NOW],
  [BusinessTypes.FRESH_UK.name]: [RBS_VULCAN],
  [BusinessTypes.FRESH_DE.name]: [RBS_VULCAN],
  [BusinessTypes.FRESH_ES.name]: [RBS_VULCAN],
  [BusinessTypes.FRESH_IT.name]: [RBS_VULCAN],
  [BusinessTypes.FRESH_JP.name]: [JP_F3_FRESH_MERCHANDISING],
  [BusinessTypes.UK_3P.name]: [RBS_VULCAN],
  [BusinessTypes.DE_3P.name]: [RBS_VULCAN],
  [BusinessTypes.IT_3P.name]: [RBS_VULCAN],
  [BusinessTypes.ES_3P.name]: [RBS_VULCAN],
  [BusinessTypes.FR_3P.name]: [RBS_VULCAN],
  [BusinessTypes.JP_3P.name]: [JP_F3_FRESH_MERCHANDISING],
};

export const getIntents = (data) => {
  if (!data || !data.options) {
    return [];
  }
  const intents = [];

  const getNestedIntents = (label, options) => {
    options.forEach((option) => {
      const { id, childOptions, displayText } = option;
      const newId = label ? `${label}-${id}` : `${id}`;
      if (!childOptions) {
        const newIntent = {
          value: newId,
          label: displayText,
        };
        intents.push(newIntent);
        return;
      }
      getNestedIntents(newId, childOptions);
    });
  };

  getNestedIntents('', data.options);

  return intents;
};

export const getObjectiveData = (data) => {
  if (!data || !data.objectives) { return []; }
  return data.objectives.map((objective) => {
    return {
      label: objective.objectiveName,
      value: objective.objectiveId,
    };
  });
};

export default (dispatch) => {
  const intents = getIntents(intentData);
  const objectives = getObjectiveData(objectiveData);

  dispatch(onCreateCsCampaignSetMetadata({
    intents,
    objectives,
    accessControlGroups,
    businessGroups,
  }));
};
