import _ from 'lodash';
import { onNotificationsSet } from '../../actionCreators';
import { parseGraphQLResponse } from '../../helpers/api';
import { getUsername } from '../../selectors/user';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { MetricLocations, MetricNames, MetricTypes } from '../../constants';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { notification } } = action;

  if (!notification || _.isEmpty(notification)) {
    return next(action);
  }

  const username = getUsername(state);

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.NOTIFICATIONS_ON_CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED,
    username,
  };

  submitStringMetric(
    MetricNames.NOTIFICATIONS_ON_CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED,
    metricContext,
  );

  try {
    const newNotification = parseGraphQLResponse(notification.value, 'onCreateNotification');
    if (!newNotification
      || _.isEmpty(newNotification)
      || newNotification.toUsername !== username) {
      return next(action);
    }

    dispatch(onNotificationsSet({ notifications: [newNotification] }));
  } catch (error) {
    submitAppSyncError(error, metricContext);
  }

  return next(action);
};
