import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import RadioButton from '@amzn/meridian/radio-button';
import Alert from '@amzn/meridian/alert';
import readFile from '../../helpers/readFile';
import styles from './RateCardAssetsManager.module.scss';
import { BulkImportTypes, Language } from '../../constants';
import { RateCardReportType } from '../../constants/rateCard';

const RateCardAssetsManager = (props) => {
  const {
    hasAssets,
    onAssetsPublish,
    onAssetsExport,
    onAssetsImport,
    onProcessFile,
    onClear,
    showCancelButton,
    shouldImportButtonBeAvailable,
    isPublishButtonAvailable,
    shouldShowLoader,
    shouldShowImportWarning,
  } = props;

  const [reportType, setReportType] = useState(RateCardReportType.EXTERNAL.name);
  const inputRef = useRef(null);

  const resetFileInput = () => {
    inputRef.current.value = null;
  };

  const getLoader = () => {
    if (!shouldShowLoader) {
      return null;
    }

    return (
      <div className={styles.loader}>
        <span className={styles.loaderText}>Parsing the file... Please, wait...</span>
      </div>
    );
  };

  const getImportWarningMessage = () => {
    if (!shouldShowImportWarning) {
      return null;
    }

    return (
      <div className={styles.importWarningMessage}>
        <Alert type="warning">{Language.RATE_CARD_IMPORT_WARNING}</Alert>
      </div>
    );
  };

  const getOptionsBlock = () => {
    const reportTypes = Object.entries(RateCardReportType).map(([key, data]) => {
      const { name, display } = data;

      return (
        <li key={key}>
          <RadioButton
            checked={reportType === name}
            onChange={setReportType}
            name="reportType"
            value={name}
          >
            {`${display} use`}
          </RadioButton>
        </li>
      );
    });

    return (
      <div className={styles.amOptionsButton}>
        Report type:

        <ul className={styles.amReportList}>
          {reportTypes}
        </ul>
      </div>
    );
  };

  const options = getOptionsBlock();

  const exportAssets = (
    <li className={styles.amItem}>
      <h2 className={styles.amCategoryTitle}>Export Assets</h2>
      <p className={styles.amDescription}>
        Export current Assets collection to a spreadsheet.
      </p>
      <div className={styles.amControls}>
        {options}
      </div>
      <div className={styles.amButtons}>
        <Button
          size="small"
          onClick={() => onAssetsExport(reportType)}
          disabled={!hasAssets}
        >
          Download
        </Button>
      </div>
    </li>
  );

  const handleFile = (file) => {
    const processFile = (fileContents) => {
      onProcessFile(fileContents, BulkImportTypes.FILE);
    };

    readFile(file, processFile);
  };

  const onInputFileChange = (evt) => {
    const file = evt.target.files && evt.target.files[0];
    if (!file) {
      return;
    }
    handleFile(file);
    resetFileInput();
  };

  const cancelButton = showCancelButton
    ? <Button type="secondary" size="small" onClick={onClear}>Cancel</Button>
    : null;

  const getImportAssets = () => {
    const uploadButton = shouldImportButtonBeAvailable
      ? null
      : <input type="file" ref={inputRef} onChange={onInputFileChange} />;

    return (
      <li className={styles.amItem}>
        <h2 className={styles.amCategoryTitle}>Import Assets</h2>
        <p className={styles.amDescription}>
          Import a spreadseet with an existing Assets collection.
        </p>
        <div className={styles.amButtons}>
          {uploadButton}
          <Button
            size="small"
            onClick={onAssetsImport}
            disabled={!shouldImportButtonBeAvailable}
          >
            Validate & Upload
          </Button>
          {cancelButton}
        </div>
      </li>
    );
  };

  const publishAssets = (
    <li className={styles.amItem}>
      <h2 className={styles.amCategoryTitle}>Publish Assets to MC</h2>
      <p className={styles.amDescription}>
        When finalized assets look good, publish them to the Marketing Calendar
      </p>
      <div className={styles.amButtons}>
        <Button
          size="small"
          onClick={onAssetsPublish}
          disabled={!hasAssets || !isPublishButtonAvailable}
        >
          Publish
        </Button>
      </div>
    </li>
  );

  const loader = getLoader();
  const importWarningMessage = getImportWarningMessage();
  const importAssets = getImportAssets();

  return (
    <div className={styles.amContainer}>
      {loader}
      <div className={styles.mainDescription}>
        <h2 className={styles.amTitle}>Assets management</h2>
        <p>
          Import, Export or Publish your assets.
          In this section you can control how your assets should look like.
        </p>
      </div>
      <ul className={styles.amList}>
        {exportAssets}
        {importAssets}
        {publishAssets}
      </ul>
      {importWarningMessage}
    </div>
  );
};

RateCardAssetsManager.propTypes = {
  hasAssets: PropTypes.bool.isRequired,
  onAssetsPublish: PropTypes.func.isRequired,
  onAssetsExport: PropTypes.func.isRequired,
  onAssetsImport: PropTypes.func.isRequired,
  onProcessFile: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  shouldImportButtonBeAvailable: PropTypes.bool.isRequired,
  isPublishButtonAvailable: PropTypes.bool.isRequired,
  shouldShowLoader: PropTypes.bool.isRequired,
  shouldShowImportWarning: PropTypes.bool.isRequired,
};

export default RateCardAssetsManager;
