import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import LimitedPlacements from './LimitedPlacements';
import {
  RateCardColumns,
  RateCardPlacementColumnNames,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';
import { onRateCardRefreshPlacements } from '../../../../actionCreators';
import RateCardRenderFields from '../../../../helpers/RateCardRenderFields';
import { Form } from '../../../../constants';
import { getTotalLimitedPlacementsMoneyUsed } from '../../../../selectors/rateCardSelectors';
import { getMoneyFormatter } from '../../../../helpers/money';

export const columns = [
  RateCardPlacementColumnNames.CLASS,
  RateCardPlacementColumnNames.PLACEMENT_NAME,
  {
    ...RateCardPlacementColumnNames.DOLLARS,
    render: RateCardRenderFields.dollarsInputField,
  },
  {
    ...RateCardPlacementColumnNames.PLANNED_PLACEMENTS,
    render: RateCardRenderFields.numberInputField,
  },
  RateCardPlacementColumnNames.NET_PLACEMENTS,
  RateCardPlacementColumnNames.VENDOR_MAX,
  RateCardPlacementColumnNames.UFF_PLACEMENT,
  RateCardPlacementColumnNames.AFS_PLACEMENT,
];

export const mapStateToProps = (state) => {
  return {
    columns,
    defaultRender: RateCardRenderFields.renderTextField,
    isVSSM: formValueSelector(Form.RATE_CARD_NAME)(state, RateCardColumns.IS_VSSM.name) || false,
    totalLimitedPlacementsMoneyUsed: getMoneyFormatter().format(getTotalLimitedPlacementsMoneyUsed(state)),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onRefreshPlacements() {
      dispatch(onRateCardRefreshPlacements(RateCardPlacementTypes.LIMITED));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitedPlacements);
