import moment from 'moment';
import { readString } from 'react-papaparse';
import { BooleanCampaignFields } from 'src/constants/campaign';
import { CampaignColumns, Delimiters } from '../../../constants';

const parseBulkImportString = (importedString, columnOrder) => {
  const timeColumns = [CampaignColumns.START_TIME.name, CampaignColumns.END_TIME.name];
  const cleanImportedString = importedString
    .split('\r\n')
    .filter((row) => !row.split(Delimiters.COMMA).every((value) => value.trim() === ''))
    .join('\r\n');

  const parsedData = readString(cleanImportedString, {
    header: true,
    transformHeader: (value) => {
      let columnName;
      Object.keys(columnOrder).forEach((key) => {
        if (columnOrder[key].display === value) {
          columnName = columnOrder[key].name;
        }
      });
      return columnName ?? value;
    },
    transform: (value, column) => {
      if (timeColumns.includes(column)) {
        // eslint-disable-next-line no-param-reassign
        value = moment(value, 'H:mm').format('HH:mm');
      }
      if (BooleanCampaignFields.includes(column)) {
        // eslint-disable-next-line no-param-reassign
        value = value.toLowerCase() === 'true';
      }
      if (column === CampaignColumns.SOV.name) {
        // eslint-disable-next-line no-param-reassign
        value = parseFloat(value) || 0;
      }
      if (column === CampaignColumns.CATEGORY.name) {
        if (typeof value === 'string') {
          // eslint-disable-next-line no-param-reassign
          value = [value];
        }
      }
      return value;
    },
  });
  return parsedData;
};

export default parseBulkImportString;
