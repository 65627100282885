import { InStoreUtilizationActions } from '../../constants/inStoreUtilization';

export const initialState = {
  selectedFilters: {},
  filterOptions: {},
  selectedDateRange: [],
  startTime: '00:00',
  endTime: '00:00',
  selectedTimezone: '',
  utilization: [],
  timeFrame: '',
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const { type } = action;

  switch (type) {
    case InStoreUtilizationActions.SET_FILTER_OPTIONS: {
      const { payload: { filterOptions } } = action;

      return {
        ...state,
        filterOptions,
      };
    }
    case InStoreUtilizationActions.SET_FILTER: {
      const {
        payload: {
          filterGroupName,
          optionName,
          isChecked,
        },
      } = action;

      const newFilters = {
        ...state.selectedFilters,
        [filterGroupName]: {
          ...state.selectedFilters[filterGroupName],
          [optionName]: isChecked,
        },
      };

      return {
        ...state,
        selectedFilters: newFilters,
      };
    }
    case InStoreUtilizationActions.SET_FILTERS: {
      const { payload: { filterGroupName, selectedFilters } } = action;

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [filterGroupName]: selectedFilters,
        },
      };
    }
    case InStoreUtilizationActions.SELECT_DATE_RANGE: {
      const { payload: { selectedDateRange } } = action;

      return {
        ...state,
        selectedDateRange,
      };
    }
    case InStoreUtilizationActions.SELECT_START_TIME: {
      const { payload: { startTime } } = action;
      return {
        ...state,
        startTime,
      };
    }
    case InStoreUtilizationActions.SELECT_END_TIME: {
      const { payload: { endTime } } = action;

      return {
        ...state,
        endTime,
      };
    }
    case InStoreUtilizationActions.SELECT_TIMEZONE: {
      const { payload: { selectedTimezone } } = action;

      return {
        ...state,
        selectedTimezone,
      };
    }
    case InStoreUtilizationActions.SET_RESULTS: {
      const { payload: { utilization } } = action;

      return {
        ...state,
        utilization,
      };
    }
    case InStoreUtilizationActions.SELECT_TIME_FRAME: {
      const { payload: { timeFrame } } = action;

      return {
        ...state,
        timeFrame,
      };
    }
    default: {
      return state;
    }
  }
};
