import { getUsername } from '../../selectors/user';
import {
  GraphQLLabels,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphql';
import { onOpenToast } from '../../actionCreators';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { deleteCampaign } from '../../graphql/mutations';
import { getCampaign } from '../../selectors/campaign';
import { formatCampaignForMutationInput, localToAppsync } from '../../helpers/translateCampaigns';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const { payload: { campaign: { id, packageId }, options } } = action;
  const { isBulkAction } = options;

  const campaignToDelete = getCampaign(state, id, packageId);
  if (!campaignToDelete) {
    dispatch(onOpenToast('Error while deleting campaign!'));
    return next(action);
  }

  const dataToSend = {
    input: {
      campaign: formatCampaignForMutationInput(localToAppsync(campaignToDelete)),
      username,
    },
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: JSON.stringify(dataToSend),
    action: MetricNames.DELETE_CAMPAIGN,
    username,
  };

  submitStringMetric(MetricNames.DELETE_CAMPAIGN, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: deleteCampaign,
    data: dataToSend,
    label: GraphQLLabels.DELETE_CAMPAIGN,
    onSuccessFunction({ data: { deleteCampaign: result } }) {
      if (!isBulkAction) {
        const { campaignName } = result;
        dispatch(onOpenToast(`Campaign '${campaignName}' has been deleted!`));
      }
    },
    onFailureFunction(error) {
      dispatch(onOpenToast('Error while deleting campaign!'));
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};
