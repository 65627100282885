import moment from 'moment';

import { AuditLogActions } from '../../constants';

export const initialState = {
  id: null,
  isLoading: false,
  isLoaded: false,
  type: null,
  logs: [],
  untranslatedLogs: [],
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case AuditLogActions.SET_ITEM: {
      const { payload: { id, auditLogType } } = action;
      return {
        ...state,
        id,
        type: auditLogType,
      };
    }
    case AuditLogActions.CLEAR: {
      return initialState;
    }
    case AuditLogActions.SET_LOGS: {
      return {
        ...state,
        logs: action.payload.data.map((log) => {
          return {
            ...log,
            modificationTimestamp: moment(log.modificationTimestamp).format('MM/DD/YYYY, h:mm:ssa'),
          };
        }),
      };
    }
    case AuditLogActions.SET_UNTRANSLATED_LOGS: {
      return {
        ...state,
        untranslatedLogs: action.payload.data,
      };
    }
    case AuditLogActions.CLEAR_UNTRANSLATED_LOGS: {
      return {
        ...state,
        untranslatedLogs: [],
      };
    }
    default: {
      return state;
    }
  }
};
