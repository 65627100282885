import { Delimiters } from '../constants';

export const parseCampaignFormField = (campaignField) => {
  if (!campaignField || !campaignField.includes(Delimiters.DOT)) {
    return {
      groupName: '',
      activeCampaignIndex: '',
      fieldName: campaignField,
    };
  }

  const campaignFieldNameParts = campaignField.split(Delimiters.DOT);
  if (!campaignFieldNameParts || !campaignFieldNameParts.length) {
    return {
      groupName: '',
      activeCampaignIndex: '',
      fieldName: '',
    };
  }

  const [
    groupName,
    activeCampaignIndex,
  ] = campaignFieldNameParts;

  const fieldName = campaignFieldNameParts[campaignFieldNameParts.length - 1];

  return {
    groupName,
    activeCampaignIndex: parseInt(activeCampaignIndex),
    fieldName,
  };
};
