import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ColorCodes } from '../../constants';

import styles from './CircleChart.module.scss';

const CircleChart = ({ percent, color }) => {
  return (
    <svg
      viewBox="0 0 36 36"
      className={classNames({
        [styles.circularChart]: true,
        [styles[color]]: true,
      })}
    >
      <path
        className={styles.circleBackground}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        className={styles.circle}
        strokeDasharray={`${percent}, 100`}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" className={styles.percentage}>
        {percent}
        %
      </text>
    </svg>
  );
};

CircleChart.propTypes = {
  percent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
};

CircleChart.defaultProps = {
  percent: 0,
  color: ColorCodes.BLUE,
};

export default CircleChart;
