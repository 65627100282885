import {
  CommentActions,
  SubscriptionActions,
} from '../../constants';
import loadComments from './loadComments';
import addComment from './addComment';
import deleteComment from './deleteComment';
import sortComments from './sortComments';
import pollComments from './pollComments';
import modalWindow from './modalWindow';
import { onCommentsLoad } from '../../actionCreators';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === CommentActions.LOAD) {
    return loadComments(store)()(action);
  }

  if (type === CommentActions.SORT) {
    return sortComments(dispatch, getState, action);
  }

  if (type === CommentActions.ADD) {
    addComment(dispatch, getState, action);

    return next(action);
  }

  if (type === CommentActions.DELETE) {
    next(action);

    return deleteComment(dispatch, getState, action);
  }

  if (type === SubscriptionActions.UPDATED_COMMENTS_RECIEVED) {
    const { payload: { commentData: { value: { data: { onUpdateComments: { campaignId } } } } } } = action;
    dispatch(onCommentsLoad({ campaignId }));
  }

  if (type === CommentActions.START_POLLING) {
    next(action);

    return pollComments(dispatch, getState, action);
  }

  if ([
    CommentActions.OPEN_MODAL,
    CommentActions.CLOSE_MODAL,
  ].includes(type)) {
    next(action);

    return modalWindow(dispatch, getState, action);
  }

  return next(action);
};
