import React from 'react';
import PropTypes from 'prop-types';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import RateCardAssetsListButtons from '../RateCardAssetsListButtons';
import { campaignProps } from '../../proptypes';
import styles from './RateCardAssetsList.module.scss';
import RateCardAssetsListPaginator from '../RateCardAssetsListPaginator';
import RateCardAssetsManager from '../RateCardAssetsManager';
import RateCardFinalizedMessage from '../RateCardFinalizedMessage';
import RateCardAssetsMenu from '../RateCardAssetsMenu';
import BulkImporter from '../BulkImporter';
import { RateCardAssetsHeadersNames } from '../../constants/rateCard';

const RateCardAssetsList = (props) => {
  const {
    assets,
    rateCardId,
    shouldShowNewAssetsList,
    isReadOnlyMode,
  } = props;

  const getHeaderRow = () => {
    const headerCells = RateCardAssetsHeadersNames.map((headerRow) => {
      return <TableCell key={headerRow}>{headerRow}</TableCell>;
    });
    return (
      <TableRow
        key="headerRow"
        highlightOnHover
      >
        {headerCells}
      </TableRow>
    );
  };

  const getRows = () => {
    if (!assets.length) {
      const columnSpan = RateCardAssetsHeadersNames.length;

      return (
        <TableRow
          highlightOnHover
        >
          <TableCell columnSpan={columnSpan}>No assets to show. yet.</TableCell>
        </TableRow>
      );
    }
    return assets.map((asset) => {
      const {
        id,
        campaign: {
          packageName,
          placementInBundle,
          channel,
          startDate,
          endDate,
          widget,
          pageType,
          placement,
          marketingManagerNotes,
        },
      } = asset;

      return (
        <TableRow
          key={id}
          highlightOnHover
        >
          <TableCell>
            <RateCardAssetsListButtons
              rateCardId={rateCardId}
              asset={asset}
              isReadOnlyMode={isReadOnlyMode}
            />
          </TableCell>
          <TableCell>{packageName}</TableCell>
          <TableCell>{placementInBundle}</TableCell>
          <TableCell>{channel}</TableCell>
          <TableCell>{startDate}</TableCell>
          <TableCell>{endDate}</TableCell>
          <TableCell>{widget}</TableCell>
          <TableCell>
            {pageType}
            {' '}
            {placement}
          </TableCell>
          <TableCell>{marketingManagerNotes}</TableCell>
        </TableRow>
      );
    });
  };

  const getCardsList = () => {
    const headerRow = getHeaderRow();
    const rows = getRows();

    return (
      <div className={styles.cardListContainer}>
        <Table
          headerRows={1}
          showDividers
          showStripes
        >
          {headerRow}
          {rows}
        </Table>
      </div>
    );
  };

  const getPagination = () => {
    return (
      <div className={styles.pagination}>
        <RateCardAssetsListPaginator />
      </div>
    );
  };

  const getExistingAssetsList = () => {
    const cardsList = getCardsList();
    const pagination = getPagination();

    return (
      <>
        {cardsList}
        {pagination}
      </>
    );
  };

  const getNewAssetsList = () => {
    return (
      <div className={styles.bulkImporter}>
        <BulkImporter isRateCardCompatibleMode />
      </div>
    );
  };

  const rateCardFinalizedMessage = isReadOnlyMode ? <RateCardFinalizedMessage /> : null;

  const assetsList = shouldShowNewAssetsList
    ? getNewAssetsList()
    : getExistingAssetsList();

  const assetsManager = isReadOnlyMode
    ? null
    : <RateCardAssetsManager rateCardId={rateCardId} />;

  return (
    <div className={styles.container}>
      <RateCardAssetsMenu rateCardId={rateCardId} />
      {rateCardFinalizedMessage}
      {assetsList}
      {assetsManager}
    </div>
  );
};

RateCardAssetsList.propTypes = {
  assets: PropTypes.arrayOf(campaignProps).isRequired,
  rateCardId: PropTypes.string.isRequired,
  shouldShowNewAssetsList: PropTypes.bool.isRequired,
  isReadOnlyMode: PropTypes.bool.isRequired,
};

export default RateCardAssetsList;
