import { connect } from 'react-redux';
import GridViewCell from './GridViewCell';
import {
  onFlashFillPackage,
  onFlashFillPage,
  onSelectCell,
} from '../../actionCreators';
import ActiveCellInputs from '../ActiveCellInputs';
import {
  CampaignColumns,
  InputTypes,
  LinkTypes,
} from '../../constants';
import { isValidUrl } from '../../helpers/common';
import getBusinessSpecificLink from '../../helpers/getBusinessSpecificLink';

const isPopOverEnabled = (columnName) => {
  if (!columnName) {
    return false;
  }

  return {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: true,
    [CampaignColumns.TERMS_AND_CONDITIONS.name]: true,
    [CampaignColumns.SCHEDULING_INSTRUCTIONS.name]: true,
    [CampaignColumns.CBR_COMMENTS.name]: true,
    [CampaignColumns.STOREFRONT_REVIEW_NOTES.name]: true,
    [CampaignColumns.FUNCTIONAL_QA_NOTES.name]: true,
    [CampaignColumns.IMAGE_PATHS.name]: true,
  }[columnName] || false;
};

export const mapStateToProps = (state, props) => {
  const {
    GridViewPage: {
      activeCell,
    },
    Meta: {
      metadata: { columnOrder },
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const {
    item,
    columnName,
    children,
  } = props;

  const isCellActive = () => {
    if (!activeCell) {
      return false;
    }

    const { item: activeItem, columnName: activeColumnName } = activeCell;

    return (
      item.id === activeItem.id
      && columnName === activeColumnName
    );
  };

  const { inputType } = columnOrder.find(({ name }) => (
    name === columnName
  )) || {};

  const getInputComponent = () => {
    switch (inputType) {
      case InputTypes.INPUT:
        return ActiveCellInputs.Input;
      case InputTypes.TEXT_AREA_INPUT:
        return ActiveCellInputs.TextAreaInput;
      case InputTypes.SINGLE_SELECT:
        return ActiveCellInputs.SingleSelect;
      case InputTypes.MULTI_SELECT:
        return ActiveCellInputs.MultiSelect;
      case InputTypes.DATE_TIME:
        return ActiveCellInputs.DateTime;
      case InputTypes.TIME_INPUT:
        return ActiveCellInputs.TimeInput;
      case InputTypes.SINGLE_CHECKBOX:
        return ActiveCellInputs.Checkbox;
      case InputTypes.UNEDITABLE:
        return ActiveCellInputs.Uneditable;
      case InputTypes.JSON_INPUT:
        return ActiveCellInputs.JsonInput;
      default:
        return ActiveCellInputs.Input;
    }
  };

  const getLink = () => {
    if (!children) { return null; }
    switch (columnName) {
      case CampaignColumns.WEBLAB.name: {
        let link;
        try {
          [link] = children.split(' ');
        } catch {
          return null;
        }
        return `https://weblab.amazon.com/wl/${link}`;
      }
      case CampaignColumns.TARGETING.name: {
        const id = parseInt(children, 10);
        if (Number.isNaN(id)) { return null; }
        const args = {
          id,
        };
        return getBusinessSpecificLink(LinkTypes.BULLSEYE, selectedBusiness, args);
      }
      default:
        return isValidUrl(children) ? children : null;
    }
  };

  return {
    inputComponent: getInputComponent(),
    isActive: isCellActive(),
    link: getLink(),
    isPopOverEnabled: isPopOverEnabled(columnName),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelectCell() {
      const { item, columnName } = ownProps;
      dispatch(onSelectCell({ item, columnName }));
    },
    onFlashFillCells() {
      const { item: { id, packageId }, children, columnName } = ownProps;
      dispatch(onFlashFillPage(
        id,
        packageId,
        children,
        columnName,
      ));
    },
    onFlashFillPackageCells() {
      const { item: { id, packageId }, children, columnName } = ownProps;
      dispatch(onFlashFillPackage(
        id,
        packageId,
        children,
        columnName,
      ));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewCell);
