import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { getSelectedBusiness } from './sitewide';

export const isBulletinBoardLoading = (state) => state.BulletinBoard.isLoading;
export const getAnnouncements = (state) => state.BulletinBoard.announcements;

export const getFilteredActiveAnnouncements = createSelector(
  [
    getAnnouncements,
    getSelectedBusiness,
    (state, showOnlyGlobalAnnouncements) => showOnlyGlobalAnnouncements,
  ],
  (announcements, selectedBusiness, showOnlyGlobalAnnouncements) => {
    if (!selectedBusiness || !announcements || !announcements.length) {
      return [];
    }

    return announcements.filter((announcement) => {
      const {
        isGlobalAnnouncement,
        business,
        startDateTime,
        endDateTime,
      } = announcement;

      const isWithinDateRange = moment()
        .range(startDateTime, endDateTime)
        .contains(moment());

      const isActiveAnnouncement = isWithinDateRange && business === selectedBusiness;

      if (showOnlyGlobalAnnouncements) {
        return isActiveAnnouncement && isGlobalAnnouncement;
      }

      return isActiveAnnouncement;
    });
  },
);

export const getFilteredGlobalActiveAnnouncements = createSelector(
  [
    (state) => getFilteredActiveAnnouncements(state, true),
  ],
  (announcements) => {
    return announcements;
  },
);
