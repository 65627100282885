import { connect } from 'react-redux';
import { onCommentsModalClose } from '../../actionCreators';
import CommentsModal from './CommentsModal';

export const mapStateToProps = (state) => {
  const {
    Comments: {
      activeCampaignId,
      activePackageId,
      isModalOpen,
    },
  } = state;

  return {
    isOpen: isModalOpen,
    campaignId: activeCampaignId,
    packageId: activePackageId,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onModalClose() {
      dispatch(onCommentsModalClose());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsModal);
