import moment from 'moment-timezone/index';
import {
  ON_BUSINESS_RECEIVED,
  ON_YEARS_RECEIVED,
  PAGE_INIT,
  PerformanceTimerNames,
} from 'src/constants';
import {
  onBulletinBoardLoad,
  onBusinessSelect,
  onLoadFilterViewsFromStorage,
  onLoadNotifications,
  onNotificationsEnableExperience,
  onPerformanceTimerStart,
  onSetNewUserExperienceShown,
  onStartYearsSelect,
  onThirdPartyIFrameEnableExperience,
} from 'src/actionCreators';
import { getSelectedBusiness } from 'src/helpers/businessSelector';
import { getSelectedYear } from 'src/helpers/startYearsSelector';

export default ({ getState, dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === PAGE_INIT) {
    const { router: { location: { query } } } = getState();
    if (query.enableComposer) {
      dispatch(onThirdPartyIFrameEnableExperience());
    }
    dispatch(onNotificationsEnableExperience());

    getSelectedYear(dispatch, getState, action, next);
    getSelectedBusiness(dispatch, getState, action, next);

    dispatch(onLoadFilterViewsFromStorage());
    dispatch(onLoadNotifications());
    dispatch(onBulletinBoardLoad());
    dispatch(onPerformanceTimerStart(PerformanceTimerNames.APP_INIT));
  }
  if (action.type === ON_BUSINESS_RECEIVED) {
    if (action.data) {
      dispatch(onBusinessSelect({
        business: action.data,
        forceReload: false,
      }));
    } else {
      dispatch(onSetNewUserExperienceShown(true));
    }
  }

  if (action.type === ON_YEARS_RECEIVED) {
    if (action.payload) {
      const { payload: { startYears } } = action;
      try {
        const parsedYears = JSON.parse(startYears);
        if (!parsedYears) {
          dispatch(onStartYearsSelect([moment().year().toString()]));
        } else {
          dispatch(onStartYearsSelect(parsedYears));
        }
      } catch (e) {
        dispatch(onStartYearsSelect([moment().year().toString()]));
      }
    }
  }
  return null;
};
