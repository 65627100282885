import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import closeLargeTokens from '@amzn/meridian-tokens/base/icon/close-large';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import { useHistory } from 'react-router-dom';
import GridViewHeader from '../GridViewHeader';
import GridViewRow from '../GridViewRow';
import styles from '../GridView/GridView.module.scss';
import { campaignColumnProps, campaignProps } from '../../proptypes';
import { GridViewDisplayModes } from '../../constants';

const AuditLog = (props) => {
  const history = useHistory();

  const {
    onSelectItem,
    columnsToCompare,
    logs,
    columnOrder,
    id,
  } = props;

  useEffect(() => {
    onSelectItem(id);
  }, [onSelectItem, id]);

  const onCloseButton = () => {
    history.goBack();
  };

  const buttonGoBack = () => (
    <span className={styles.closeButton}>
      <Button type="icon" onClick={onCloseButton} backdropColor="white">
        <Icon tokens={closeLargeTokens}>Close</Icon>
      </Button>
    </span>
  );

  const rows = logs.map((elem, index) => {
    const key = (elem.id || elem.tempId) + elem.version;
    return (
      <GridViewRow
        key={key}
        item={elem}
        columnOrder={columnOrder}
        stripe={index % 2 === 0}
        showActionBar={false}
        columnsToCompare={columnsToCompare}
        comparedRow={logs[index - 1]}
        displayMode={GridViewDisplayModes.PREVIEW_MODE}
      />
    );
  });
  const goBackButton = buttonGoBack();

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        {goBackButton}
        <div className={styles.wrapperInner}>
          <table className={styles.gridViewTable}>
            <tbody>
              <GridViewHeader columns={columnOrder} showActionBar={false} />
              {rows}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

AuditLog.propTypes = {
  onSelectItem: PropTypes.func.isRequired,
  logs: PropTypes.arrayOf(campaignProps).isRequired,
  columnsToCompare: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnOrder: campaignColumnProps.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }),
};
AuditLog.defaultProps = {
  match: null,
};

export default AuditLog;
