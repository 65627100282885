import { connect } from 'react-redux';
import { defaultDispatchToProps, defaultStateToProps } from '../helpers';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import MultiSelect from '../../StyledInputs/MultiSelect';
import { getMetadataOptionsForInput } from '../../../selectors/metadata';

export const mapStateToProps = (state) => {
  return {
    ...defaultStateToProps(state),
    options: getMetadataOptionsForInput(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
