import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Loader from '@amzn/meridian/loader';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Alert from '@amzn/meridian/alert';
import { Language, Urls } from '../../../constants';
import sitewideStyles from '../../../styles/sitewide.module.scss';
import styles from './BulletinBoardForm.module.scss';

import Main from './Sections/Main';
import SubmitButtons from './Sections/SubmitButtons';

const BulletinBoardForm = (props) => {
  const {
    onCreate,
    onUpdate,
    handleSubmit,
    submitting,
    isNew,
    isLoading,
    isNotFound,
    business,
  } = props;

  const history = useHistory();

  const getLoader = () => (
    <div className={sitewideStyles.centerWrapper}>
      <Loader />
      <p>Loading...</p>
    </div>
  );

  const getMessage = (text) => {
    return (
      <div className={sitewideStyles.centerWrapper}>
        <Alert>{text}</Alert>
      </div>
    );
  };

  const getForm = () => {
    return (
      <div className={styles.wrapperInner}>
        <div className={styles.breadcrumbs}>
          <BreadcrumbGroup>
            <Breadcrumb href="#dashboard">Dashboard</Breadcrumb>
            <Breadcrumb href="#board">Bulletin Board</Breadcrumb>
            <Breadcrumb href="#">Announcements manager</Breadcrumb>
          </BreadcrumbGroup>
        </div>
        <form
          className={styles.form}
          onSubmit={handleSubmit((data) => {
            if (isNew) {
              onCreate(data);
            } else {
              onUpdate(data);
            }
            history.push(Urls.BULLETIN_BOARD);
          })}
        >
          <Main business={business} />
          <SubmitButtons submitting={submitting} />
        </form>
      </div>
    );
  };

  let content = '';
  if (isLoading) {
    content = getLoader();
  } else if (isNotFound) {
    content = getMessage(Language.BULLETIN_BOARD_ERROR_LOAD_ANNOUNCEMENT);
  } else {
    content = getForm();
  }

  return (
    <div className={styles.wrapper}>
      {content}
    </div>
  );
};

BulletinBoardForm.propTypes = {
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  business: PropTypes.string.isRequired,
};

BulletinBoardForm.defaultProps = {
  isNew: false,
};

export default BulletinBoardForm;
