import { connect } from 'react-redux';
import { isWebsiteOmnichannelViewMode } from '../../../../../selectors/sitewide';
import Placement from './Placement';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';

export const mapStateToProps = (state) => {
  return {
    currentTab: getCurrentPackageActiveTab(state),
    shouldShowMarketingManagerNotes: isWebsiteOmnichannelViewMode(state),
  };
};

export default connect(mapStateToProps)(Placement);
