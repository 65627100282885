import moment from 'moment';
import { createSelector } from 'reselect';
import { filterPackages } from 'src/selectors/campaignFiltersSelectors';
import {
  CampaignColumns,
  Comparators,
  UTILIZATION_DATE_FORMAT,
} from '../constants';
import { getAllDaysInDateRange } from '../helpers/dateTime';
import {
  generateSlotPlacementDataFresh,
  generateSlotUtilizationDataFresh,
  generateSlotUtilizationDataWFM,
  getReorderedSlots,
  getSlotsOrder,
  mergeSlots,
} from '../helpers/utilization';
import {
  is3PNABusiness,
  isEvergreen,
  isFreshJPBusiness,
  isGeneric3PBusiness,
  isGenericFreshBusiness,
  isWFMBusiness,
  trimAndLowerCase,
} from '../helpers/common';
import { getDefaultSlots, getSavedSlotsList } from '../middleware/UtilizationPage/helpers';
import { UtilizationAlmOnlyPages, UtilizationSlotType } from '../constants/utilization';
import { getPackagesForCurrentBusinessStartYears } from './package';
import { getCampaignsForCurrentBusinessStartYears } from './campaign';

export const getSlotUtilization = (state) => {
  return state.UtilizationPage.slotUtilization;
};

export const getDesktopSlotUtilization = (state) => {
  return getSlotUtilization(state).desktopSlotUtilization;
};

export const getMobileSlotUtilization = (state) => {
  return getSlotUtilization(state).mobileSlotUtilization;
};

export const getUtilizationSlotsOrder = (state) => {
  return state.UtilizationPage.slotsOrder;
};

export const getSelectedFilters = (state) => {
  return state.UtilizationPage.selectedFilters;
};

export const getSelectedPageType = (state) => {
  return getSelectedFilters(state).pageType;
};

export const getSelectedBusiness = (state) => {
  return state.Sitewide.selectedBusiness;
};

const hasEnoughFiltersToCalculateSlotUtilization = (
  selectedFilters,
  selectedDateRange,
  selectedTimezone,
  business,
) => {
  if (isGenericFreshBusiness(business)) {
    return selectedFilters.pageName
      && selectedDateRange
      && selectedDateRange[0]
      && selectedDateRange[1]
      && selectedTimezone;
  }
  return selectedFilters.channel
    && selectedFilters.pageType
    && selectedFilters.pageId
    && selectedDateRange
    && selectedDateRange[0]
    && selectedDateRange[1]
    && selectedTimezone;
};

const findSelectedMetadataOption = (metadata, fieldName, selectedFilters) => {
  let fieldOptionNode = metadata.fields[fieldName].options;
  const fieldDependsOn = metadata.fields[fieldName].dependsOn || [];
  fieldDependsOn.forEach((dependency) => {
    const selectedFilter = selectedFilters[dependency];
    if (selectedFilter) {
      if (Array.isArray(selectedFilter)) {
        selectedFilter.forEach((filter) => {
          const options = fieldOptionNode[filter];

          fieldOptionNode = Array.isArray(options)
            ? {
              ...fieldOptionNode,
              [selectedFilter]: options,
            }
            : [
              ...fieldOptionNode,
              options,
            ];
        });
      } else {
        const options = fieldOptionNode[selectedFilter];

        fieldOptionNode = Array.isArray(options)
          ? {
            [selectedFilter]: options,
          }
          : options;
      }
    }
  });
  return fieldOptionNode;
};

const getPlacementOptions = (metadata, selectedFilters) => {
  const placements = findSelectedMetadataOption(
    metadata,
    CampaignColumns.PLACEMENT.name,
    selectedFilters,
  );

  if (!placements) {
    return [];
  }

  const keys = Object.keys(placements);
  if (!keys || !keys.length) {
    return [];
  }
  const [pageType] = keys;

  return placements[pageType];
};

const getFilteredPlacementOptions = (placementOptions, selectedFilters, showFmcSlots) => {
  if (!selectedFilters) {
    return placementOptions;
  }

  const { pageType, pageName } = selectedFilters;
  if (!pageType || !pageName) {
    return placementOptions;
  }

  if (showFmcSlots) {
    return placementOptions;
  }

  const isAlmOnlyPage = UtilizationAlmOnlyPages[pageType]
    && UtilizationAlmOnlyPages[pageType].includes(pageName);
  if (isAlmOnlyPage || !showFmcSlots) {
    return placementOptions.filter((placementOption) => {
      return trimAndLowerCase(placementOption) !== trimAndLowerCase(UtilizationSlotType.FMC_SLOTS);
    });
  }

  return placementOptions;
};

const doesColumnValueMatchFilter = (columnValue, filterValue) => {
  if (!columnValue || !filterValue) {
    return false;
  }

  if (Array.isArray(columnValue)) {
    const formattedColumnValues = trimAndLowerCase(columnValue);

    if (Array.isArray(filterValue)) {
      return formattedColumnValues.find((formattedColumnValue) => {
        return trimAndLowerCase(filterValue).includes(formattedColumnValue);
      });
    }

    return formattedColumnValues.includes(trimAndLowerCase(filterValue));
  }

  if (Array.isArray(filterValue)) {
    return trimAndLowerCase(filterValue).includes(trimAndLowerCase(columnValue));
  }

  return trimAndLowerCase(columnValue).indexOf(trimAndLowerCase(filterValue)) > -1;
};

export const getSlotUtilizationData = (state) => new Promise((resolve, reject) => {
  const {
    UtilizationPage: {
      selectedFilters,
      selectedDateRange,
      selectedTimezone,
      showFmcSlots,
      startTime,
      endTime,
      isAggregateByWeeksMode,
    },
    Meta: { metadata },
    Sitewide: { selectedBusiness: business },
  } = state;
  const packages = getPackagesForCurrentBusinessStartYears(state);
  const campaigns = getCampaignsForCurrentBusinessStartYears(state);

  if (hasEnoughFiltersToCalculateSlotUtilization(
    selectedFilters,
    selectedDateRange,
    selectedTimezone,
    business,
  )
  ) {
    const startDateTime = moment(`${selectedDateRange[0]} ${startTime}`);
    const endDateTime = moment(`${selectedDateRange[1]} ${endTime}`);
    const daysStep = isAggregateByWeeksMode ? 7 : 1;
    const daysInDateRange = getAllDaysInDateRange(startDateTime, endDateTime, daysStep);
    const {
      pageType,
      pageName,
      channel,
      marketingManager,
      status,
      category,
      placement,
      language,
      campaignType,
    } = selectedFilters;

    let pageID = '';
    if (selectedFilters.pageId) {
      pageID = selectedFilters.pageId;
    } else if (metadata.fields.pageId
      && metadata.fields.pageId.options[pageName]
      && metadata.fields.pageId.options[pageName] instanceof Array) {
      // eslint-disable-next-line prefer-destructuring
      pageID = metadata.fields.pageId.options[pageName][0];
    }

    const isWFM = isWFMBusiness(business);
    const is3P = isGeneric3PBusiness(business);
    const isFreshJp = isFreshJPBusiness(business);
    const filteredPackages = marketingManager
      ? filterPackages(packages, [
        {
          column: CampaignColumns.MARKETING_MANAGER.name,
          comparator: Comparators.EQUALS,
          value: marketingManager,
        },
      ])
      : packages;
    const filteredPackageIds = Object.keys(filteredPackages);

    const filteredCampaigns = campaigns.filter((campaign) => {
      const doesCampaignStatusMatchFilter = status
        ? doesColumnValueMatchFilter(
          campaign.status,
          status,
        )
        : true;

      const doesCategoryMatchFilter = category
        ? doesColumnValueMatchFilter(
          campaign.category,
          category,
        )
        : true;

      const doPageIdAndPageTypeMatchFilter = (
        campaign.pageId === pageID
        && campaign.pageType === pageType
      );

      const doesPlacementMatchFilter = placement
        ? doesColumnValueMatchFilter(
          campaign.placement,
          placement,
        )
        : true;

      const doesLanguageMatchFilter = language && campaign.language
        ? doesColumnValueMatchFilter(
          campaign.language,
          language,
        )
        : true;

      const doesCampaignTypeMatchFilter = campaignType
        ? doesColumnValueMatchFilter(
          campaign.campaignType,
          campaignType,
        )
        : true;

      const campaignStartDateTimeUTC = campaign.startDateTime;
      const campaignEndDateTimeUTC = campaign.endDateTime;
      const isWithinDateTimeRange = (
        campaignEndDateTimeUTC >= startDateTime.format()
        && campaignStartDateTimeUTC <= endDateTime.format()
      ) || isEvergreen(campaign);

      const passesBaseFilters = (
        filteredPackageIds.includes(campaign.packageId)
        && doPageIdAndPageTypeMatchFilter
        && doesCampaignStatusMatchFilter
        && doesCategoryMatchFilter
        && doesPlacementMatchFilter
        && doesLanguageMatchFilter
        && doesCampaignTypeMatchFilter
        && isWithinDateTimeRange
      );
      if (isWFM || is3P || isFreshJp) {
        return (
          passesBaseFilters
          && campaign.channel === channel
        );
      }

      return (
        passesBaseFilters
        && campaign.pageName === pageName
      );
    });

    let desktopSlotUtilization = {};
    let mobileSlotUtilization = {};
    let groupedDesktopPlacements = {};
    let groupedMobilePlacements = {};
    const desktopSlots = findSelectedMetadataOption(metadata, 'desktopSlot', selectedFilters);
    const mobileSlots = findSelectedMetadataOption(metadata, 'mobileSlot', selectedFilters);

    if (isWFMBusiness(business) || is3PNABusiness(business)) {
      desktopSlotUtilization = generateSlotUtilizationDataWFM(
        desktopSlots,
        CampaignColumns.DESKTOP_SLOT.name,
        daysInDateRange,
        filteredCampaigns,
        pageID,
      );

      mobileSlotUtilization = generateSlotUtilizationDataWFM(
        mobileSlots,
        CampaignColumns.MOBILE_SLOT.name,
        daysInDateRange,
        filteredCampaigns,
        pageID,
      );
    } else {
      const placements = getPlacementOptions(metadata, selectedFilters);
      const filteredPlacements = getFilteredPlacementOptions(
        placements,
        selectedFilters,
        showFmcSlots,
      );

      desktopSlotUtilization = generateSlotUtilizationDataFresh(
        desktopSlots,
        CampaignColumns.DESKTOP_SLOT.name,
        daysInDateRange,
        filteredCampaigns,
        pageID,
        filteredPlacements,
        daysStep,
      );

      mobileSlotUtilization = generateSlotUtilizationDataFresh(
        mobileSlots,
        CampaignColumns.MOBILE_SLOT.name,
        daysInDateRange,
        filteredCampaigns,
        pageID,
        filteredPlacements,
        daysStep,
      );

      groupedDesktopPlacements = generateSlotPlacementDataFresh(
        desktopSlots,
        CampaignColumns.DESKTOP_SLOT.name,
        filteredCampaigns,
        filteredPlacements,
        selectedDateRange,
      );

      groupedMobilePlacements = generateSlotPlacementDataFresh(
        mobileSlots,
        CampaignColumns.MOBILE_SLOT.name,
        filteredCampaigns,
        filteredPlacements,
        selectedDateRange,
      );
    }

    const slotUtilization = {
      desktopSlotUtilization,
      mobileSlotUtilization,
      groupedDesktopPlacements,
      groupedMobilePlacements,
    };

    resolve({
      slotUtilization,
      daysInDateRange,
      dateRange: {
        startDate: startDateTime.format(UTILIZATION_DATE_FORMAT),
        endDate: endDateTime.format(UTILIZATION_DATE_FORMAT),
      },
    });
  }

  reject(new Error('Some of the required parameters for calculations are missing!'));
});

export const getCustomOrderedSlots = createSelector(
  [
    getDesktopSlotUtilization,
    getMobileSlotUtilization,
    getUtilizationSlotsOrder,
    getSelectedPageType,
    getSelectedBusiness,
  ],
  (
    desktopSlotUtilization,
    mobileSlotUtilization,
    slotsOrder,
    pageType,
    selectedBusiness,
  ) => {
    const desktopSlotsOrder = getSlotsOrder(
      slotsOrder,
      selectedBusiness,
      pageType,
      CampaignColumns.DESKTOP_SLOT.name,
    );
    const mobileSlotsOrder = getSlotsOrder(
      slotsOrder,
      selectedBusiness,
      pageType,
      CampaignColumns.MOBILE_SLOT.name,
    );

    const desktopSlot = getReorderedSlots(desktopSlotUtilization, desktopSlotsOrder);
    const mobileSlot = getReorderedSlots(mobileSlotUtilization, mobileSlotsOrder);

    return {
      desktopSlot,
      mobileSlot,
    };
  },
);

/**
 * Returns default preset slots order or load customer's custom one.
 *
 * @param {Object} state
 * @returns {Object[]}
 */
export const getInitialSlotsList = (state) => {
  const {
    UtilizationPage: {
      selectedFilters: {
        pageType,
        channel,
      },
    },
    Sitewide: {
      selectedBusiness,
    },
    Meta: {
      metadata: {
        fields: {
          desktopSlot: { options: desktopSlotsList },
          mobileSlot: { options: mobileSlotsList },
        },
      },
    },
  } = state;

  // Load previously saved slots from localStorage
  const savedDesktopSlots = getSavedSlotsList(
    selectedBusiness,
    pageType,
    CampaignColumns.DESKTOP_SLOT.name,
  );
  const savedMobileSlots = getSavedSlotsList(
    selectedBusiness,
    pageType,
    CampaignColumns.MOBILE_SLOT.name,
  );

  // Merge default and previously saved slots
  // If any new slots appear in the default slots list, they will be added added
  // to the previously saved slots by a customer.
  const desktopSlots = mergeSlots(
    getDefaultSlots(desktopSlotsList, pageType, channel, selectedBusiness),
    savedDesktopSlots,
  );
  const mobileSlots = mergeSlots(
    getDefaultSlots(mobileSlotsList, pageType, channel, selectedBusiness),
    savedMobileSlots,
  );

  return {
    desktopSlots,
    mobileSlots,
  };
};
