import { CampaignColumns } from '../constants';
import { createRequiredFields } from '../helpers/createRequiredFields';

const PACKAGE_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
  ],
  STATUS_APPROVED: [],
};

const CAMPAIGN_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.PAGETYPE.name,
  ],
  STATUS_APPROVED: [
    CampaignColumns.CAMPAIGN_NAME.name,
    CampaignColumns.COPY.name,
  ],
};

const DE_3P_REQUIRED_FIELDS = createRequiredFields(
  PACKAGE_FIELDS,
  CAMPAIGN_FIELDS,
);

export default DE_3P_REQUIRED_FIELDS;
