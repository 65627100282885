import { ON_METADATA_RECEIVED } from '../../constants';
import { InStoreUtilizationActions } from '../../constants/inStoreUtilization';
import loadFilterOptions from './loadFilterOptions';
import loadPresets from './loadPresets';
import calculateResults from './calculateResults';

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;

  if (type === ON_METADATA_RECEIVED) {
    next(action);

    return loadFilterOptions(dispatch, getState, action, next);
  }

  if (type === InStoreUtilizationActions.LOAD_PRESETS) {
    return loadPresets(dispatch, getState, action, next);
  }

  if (type === InStoreUtilizationActions.CALCULATE_RESULTS) {
    return calculateResults(dispatch, getState, action, next);
  }

  return next(action);
};
