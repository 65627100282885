import { hasItemChanged } from '../../helpers/common';
import { onActiveCellInputSubmit } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  if (!hasItemChanged(getState())) {
    return next(action);
  }
  dispatch(onActiveCellInputSubmit());

  return next(action);
};
