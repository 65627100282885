import {
  LOG_OUT_USER,
  SET_NEW_USER_EXPERIENCE_SHOWN,
  SET_USER,
  SET_USER_TIMEZONE,
  SET_USER_UNABLE_TO_SIGN_IN,
} from 'src/constants';

export const onSetUser = (payload) => ({
  type: SET_USER,
  payload,
});
export const onSetUserTimezone = (timeZone) => ({
  type: SET_USER_TIMEZONE,
  payload: { timeZone },
});
export const onSetUserUnableToSignIn = () => ({
  type: SET_USER_UNABLE_TO_SIGN_IN,
});
export const onSignOut = () => ({
  type: LOG_OUT_USER,
});
export const onSetNewUserExperienceShown = (isShown) => ({
  type: SET_NEW_USER_EXPERIENCE_SHOWN,
  payload: isShown,
});
