import { BulletinBoardActions } from '../../constants';
import { getSortedResults } from '../../helpers/bulletinBoard';

const initialState = {
  isLoading: false,
  announcements: [],
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case BulletinBoardActions.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BulletinBoardActions.ADD: {
      const { data } = action;

      const newAnnouncements = [
        ...state.announcements,
        data,
      ];

      return {
        ...state,
        announcements: getSortedResults(newAnnouncements),
      };
    }
    case BulletinBoardActions.EDIT: {
      const { data: newAnnouncement } = action;

      const newAnnouncements = state.announcements.map((announcement) => {
        if (announcement.id === newAnnouncement.id) {
          return newAnnouncement;
        }

        return announcement;
      });

      return {
        ...state,
        announcements: getSortedResults(newAnnouncements),
      };
    }
    case BulletinBoardActions.DELETE: {
      const { data: { id } } = action;

      const newAnnouncements = state.announcements.filter((announcement) => announcement.id !== id);

      return {
        ...state,
        announcements: getSortedResults(newAnnouncements),
      };
    }
    case BulletinBoardActions.SET: {
      const {
        data: {
          items,
        },
      } = action;

      return {
        ...state,
        isLoading: false,
        announcements: items,
      };
    }
    default: {
      return state;
    }
  }
};
