import React, { Component } from 'react';

const accordion = (OriginalComponent) => class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openItemsIds: {},
    };
  }

  toggleOpenItem = (id) => {
    this.setState((prevState) => {
      return {
        openItemsIds: {
          ...prevState.openItemsIds,
          [id]: !prevState.openItemsIds[id],
        },
      };
    });
  };

  render() {
    return (
      <OriginalComponent
        {...this.props}
        {...this.state}
        toggleOpenItem={this.toggleOpenItem}
      />
    );
  }
};

export { accordion };
