import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const Scheduling = ({
  campaignColumns,
  currentTab,
}) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getTrafficScheduling = () => {
    return (
      <div className={styles.formGroupContent}>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.SCHEDULER)} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.SYMPHONY_URL)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.SYMPHONY_URL)} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.FREQUENCY)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl)}>
            <FormField {...campaignField(campaignColumns.CBR_SIM)} />
          </div>
          <div className={classnames(styles.formControl)}>
            <FormField {...campaignField(campaignColumns.DESIGN_SIM)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.SCHEDULING_INSTRUCTIONS)} />
          </div>
        </div>
      </div>
    );
  };

  const trafficScheduling = getTrafficScheduling();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Traffic Scheduling</div>
      <div className={styles.formGroupWrapper}>
        {trafficScheduling}
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scheduling;
