import React from 'react';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';
import {
  getEndDatePickerPresets,
  getStartDatePickerPresets,
} from '../../../../../helpers/dateTime';

const Scheduling = ({
  campaignColumns,
  campaignId,
  isNew,
  onOpenComposer,
  composerEnabled,
  currentTab,
}) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  const openComposer = () => {
    onOpenComposer(campaignId);
  };
  const composerIntegration = !isNew && campaignId ? (
    <div className={styles.formControl}>
      <Button
        size="small"
        onClick={openComposer}
        data-testid="composer"
        minWidth="100%"
      >
        Composer
      </Button>
    </div>
  ) : null;

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Scheduling</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlFullWidth)}>
              <FormField {...campaignField(campaignColumns.ALM_STORES)} showAutoSelect />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.SCHEDULER)} />
            </div>
            {composerEnabled && (
              <div className={styles.formControl}>
                <FormField {...campaignField(campaignColumns.SYMPHONY_URL)} />
              </div>
            )}
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlFullWidth)}>
              <FormField {...campaignField(campaignColumns.IMAGE_PATHS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            {composerEnabled && composerIntegration}
          </div>
          {!composerEnabled && (
            <div className={styles.formGroupSection}>
              <div className={styles.formControl}>
                <FormField {...campaignField(campaignColumns.SYMPHONY_URL)} />
              </div>
            </div>
          )}
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField
                {...campaignField(campaignColumns.START_DATE)}
                presets={getStartDatePickerPresets()}
              />
            </div>
            <div className={styles.formControl}>
              <FormField
                {...campaignField(campaignColumns.END_DATE)}
                presets={getEndDatePickerPresets()}
              />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.START_TIME)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.END_TIME)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.TIMEZONE_ID)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.SCHEDULING_INSTRUCTIONS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.FUNCTIONAL_QA_NOTES)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.IS_CBR_APPROVED)} />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
Scheduling.propTypes = formCampaignColumns;

export default Scheduling;
