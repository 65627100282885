import { isGenericFreshBusiness } from './common';
import { CampaignColumns } from '../constants';

// eslint-disable-next-line no-prototype-builtins
const columnExists = (item, column) => item.hasOwnProperty(column);

const getColumnValue = (item, column) => item[column];

export const getDependentOptionsForFieldOnItem = (
  options,
  item,
  dependsOn,
  index = 0,
) => {
  if (index >= dependsOn.length) { return options; }
  if (!options) { return []; }

  const parentColumnName = dependsOn[index];
  if (!columnExists(item, parentColumnName)) {
    return [];
  }

  const value = getColumnValue(item, parentColumnName);
  if (options[value]) {
    return getDependentOptionsForFieldOnItem(
      options[value],
      item,
      dependsOn,
      index + 1,
    );
  }

  return [];
};

const shouldAddDependencyToChain = (name, metadata, dependencyChain) => {
  let shouldInsert = true;
  dependencyChain.forEach((dependency) => {
    if (dependency[name]) {
      shouldInsert = false;
    }
  });
  return shouldInsert;
};

const getDisplayFromName = (name, metadata) => {
  const displayName = 'Unknown column';
  if (!metadata || !metadata.columnOrder || metadata.columnOrder.length === 0) {
    return displayName;
  }

  return metadata.columnOrder.find((column) => column.name === name).display || displayName;
};

const generateDependency = (name, field, metadata) => {
  return {
    [name]: {
      display: getDisplayFromName(name, metadata),
      dependsOn: field.dependsOn,
      options: field.options,
    },
  };
};

const recursiveFindAllParents = (name, field, metadata, dependencyChain = []) => {
  if (!field) {
    return dependencyChain;
  }
  if (!field.dependsOn || field.dependsOn.length === 0) {
    if (shouldAddDependencyToChain(name, metadata, dependencyChain)) {
      dependencyChain.push(generateDependency(name, field, metadata));
    }
    return dependencyChain;
  }

  field.dependsOn.forEach((parent) => {
    recursiveFindAllParents(parent, metadata.fields[parent], metadata, dependencyChain);

    if (shouldAddDependencyToChain(parent, metadata, dependencyChain)) {
      dependencyChain.unshift(generateDependency(parent, metadata.fields[parent], metadata));
    }
  });
  if (shouldAddDependencyToChain(name, metadata, dependencyChain)) {
    dependencyChain.push(generateDependency(name, field, metadata));
  }

  return dependencyChain;
};

// Map retains insert order, object does not necessarily retain insert order
const convertArrayToMap = (dependencyChain) => {
  const dependencyMap = new Map();
  dependencyChain.forEach((dependency) => {
    Object.entries(dependency).forEach(([name, value]) => {
      dependencyMap.set(name, value);
    });
  });
  return dependencyMap;
};

export const getDependencyChainForField = (name, field, metadata) => {
  const dependencyChain = recursiveFindAllParents(name, field, metadata);
  const dependencyMap = convertArrayToMap(dependencyChain);
  return dependencyMap;
};

/* eslint-disable no-param-reassign */
const populateChildren = (dependencyMap) => {
  dependencyMap.forEach((dependency, key) => {
    if (dependency.dependsOn && dependency.dependsOn.length > 0) {
      dependency.dependsOn.forEach((parent) => {
        if (dependencyMap.get(parent)) {
          dependencyMap.get(parent).children = dependencyMap.get(parent).children || [];
          dependencyMap.get(parent).children.push(key);
        }
      });
    }
  });
};

export const getDependentOptionsForUtilization = (metadata, selectedBusiness) => {
  let filterOptions = new Map();

  if (metadata.fields) {
    if (isGenericFreshBusiness(selectedBusiness)) {
      const pageName = CampaignColumns.PAGE_NAME.name;
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(pageName, metadata.fields[pageName], metadata),
      ]);

      const status = CampaignColumns.STATUS.name;
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(status, metadata.fields[status], metadata),
      ]);

      const category = CampaignColumns.CATEGORY.name;
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(category, metadata.fields[category], metadata),
      ]);

      const placement = CampaignColumns.PLACEMENT.name;
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(placement, metadata.fields[placement], metadata),
      ]);

      const marketingManager = CampaignColumns.MARKETING_MANAGER;
      filterOptions.set(marketingManager.name, {
        display: marketingManager.display,
        options: [],
        dependsOn: [],
      });
    } else {
      const channel = CampaignColumns.CHANNEL.name;
      const pageType = CampaignColumns.PAGETYPE.name;
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(channel, metadata.fields[channel], metadata),
      ]);
      filterOptions = new Map([
        ...filterOptions,
        ...getDependencyChainForField(pageType, metadata.fields[pageType], metadata),
      ]);
      const pageID = CampaignColumns.PAGE_ID;
      filterOptions.set(pageID.name, { display: pageID.display, options: [], dependsOn: [] });
    }
  }
  populateChildren(filterOptions);

  return filterOptions;
};

export const getDependentOptionsForInStoreUtilization = (metadata, options) => {
  if (!metadata || !metadata.fields) {
    return [];
  }

  const { fields } = metadata;

  const filterOptions = options
    .reduce((filters, filterName) => [
      ...filters,
      ...getDependencyChainForField(filterName, fields[filterName], metadata),
    ], []);

  populateChildren(filterOptions);

  return filterOptions;
};

export const getDependencyAwareOptions = (filterOptionValue, selectedFilters) => {
  let options = [];
  if (Array.isArray(filterOptionValue.options)) {
    options = filterOptionValue.options;
  }
  if (filterOptionValue
    && filterOptionValue.dependsOn
    && filterOptionValue.dependsOn.length > 0) {
    let iterator = filterOptionValue.options;
    filterOptionValue.dependsOn.forEach((dependency) => {
      if (selectedFilters[dependency]) {
        iterator = iterator[selectedFilters[dependency]];
      }
    });
    if (Array.isArray(iterator)) {
      options = iterator;
    }
  }
  return options;
};

export const getMetadataFieldOptions = (campaign, columnName, metadata) => {
  const dependencyChain = getDependencyChainForField(
    columnName,
    metadata.fields[columnName],
    metadata,
  );
  const { options, dependsOn } = dependencyChain.get(columnName);

  return getDependentOptionsForFieldOnItem(
    options,
    campaign,
    dependsOn,
  );
};
