import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import styles from '../../RateCardForm.module.scss';
import { RateCardColumns } from '../../../../constants/rateCard';
import RateCardPlacementList from '../../../RateCardPlacementList';
import usePrevious from '../../../../hooks/usePrevious';

const LimitedPlacements = (props) => {
  const {
    isNew,
    isVSSM,
    onRefreshPlacements,
    columns,
    defaultRender,
    totalLimitedPlacementsMoneyUsed,
    bundlesConfig,
    placementsConfig,
  } = props;

  const previousIsVSSM = usePrevious(isVSSM);

  useEffect(() => {
    if (isNew) {
      onRefreshPlacements();
    }
  }, [isNew, onRefreshPlacements]);

  useEffect(() => {
    if (!isNew && previousIsVSSM !== isVSSM && previousIsVSSM !== undefined) {
      onRefreshPlacements();
    }
  }, [isNew, isVSSM, onRefreshPlacements, previousIsVSSM]);

  const fieldArrayConfigProps = {
    defaultRender,
    columns,
    bundlesConfig,
    placementsConfig,
  };

  const getRefreshPlacementsButton = () => (
    <div className={styles.refreshContainer}>
      <Button
        type="icon"
        onClick={onRefreshPlacements}
        size="small"
      >
        Refresh placements
        {' '}
        <Icon tokens={refreshTokens}>Refresh</Icon>
      </Button>
    </div>
  );

  const refreshPlacementsButton = getRefreshPlacementsButton();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>
        <p>
          <span>Limited Placements</span>
          <span className={styles.titleCounter}>
            (
            {totalLimitedPlacementsMoneyUsed}
            )
          </span>
        </p>
        {refreshPlacementsButton}
      </div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={classnames(styles.formGroupSection, styles.formGroupSectionNonFlex)}>
            <FieldArray
              name={RateCardColumns.LIMITED_PLACEMENTS.name}
              component={RateCardPlacementList}
              props={fieldArrayConfigProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

LimitedPlacements.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isVSSM: PropTypes.bool.isRequired,
  totalLimitedPlacementsMoneyUsed: PropTypes.string,
  onRefreshPlacements: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.elementType,
  })).isRequired,
  defaultRender: PropTypes.elementType.isRequired,
};

LimitedPlacements.defaultProps = {
  totalLimitedPlacementsMoneyUsed: '',
};

export default LimitedPlacements;
