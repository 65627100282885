import {
  MetricLocations,
  MetricNames,
  MetricTypes,
  UtilizationStorage,
} from '../../constants';
import { submitError, submitStringMetric } from '../../metrics';
import { getTranslatedSlots } from '../../helpers/utilization';
import { isFreshDEBusiness, isWFMBusiness } from '../../helpers/common';

/**
 * Get all slots.
 *
 * @param {[]|{}} data
 * @returns {[]}
 */
export const getAllSlots = (data) => {
  const res = [];
  if (Array.isArray(data)) {
    data.forEach((item) => res.push(item));
  } else {
    if (typeof data !== 'object') {
      return [];
    }

    Object.keys(data).forEach((key) => {
      const item = data[key];

      res.push(...getAllSlots(item));
    });
  }

  return Array.from(new Set(res));
};

export const getDefaultSlots = (slots, pageType, channel, selectedBusiness) => {
  // WFM stores metadata for slots under channel
  // Fresh stores it under pageType
  const key = isWFMBusiness(selectedBusiness) || isFreshDEBusiness(selectedBusiness)
    ? channel
    : pageType;

  return (
    key
    && slots
    && slots[key]
    && Object.keys(slots[key]).length
      ? getTranslatedSlots(getAllSlots(slots[key]))
      : []
  );
};

export const getSlotsOrderFromStorage = () => {
  const metricContext = {
    location: MetricLocations.UTILIZATION_PAGE_MIDDLEWARE,
    type: MetricTypes.LOCAL_STORAGE,
    data: {},
    action: 'getSlotsOrderFromStorage',
  };

  try {
    const data = localStorage.getItem(UtilizationStorage.SLOTS_ORDER);
    if (!data) {
      return [];
    }
    const slotsOrderList = JSON.parse(data);
    if (!Array.isArray(slotsOrderList)) {
      return [];
    }

    submitStringMetric(MetricNames.UTILIZATION_MIDDLEWARE_STORAGE_LOAD, metricContext);

    return slotsOrderList;
  } catch (error) {
    const newMetricContext = {
      ...metricContext,
      data: error,
    };
    submitError('[UtilizationPage] cannot get slots order from storage', newMetricContext);
  }

  return [];
};

export const getNewSlotsListOrder = (savedSlotsListOrder, currentSlotsListOrder) => {
  const indexPosition = savedSlotsListOrder.findIndex((slot) => {
    return (
      slot.business === currentSlotsListOrder.business
      && slot.pageType === currentSlotsListOrder.pageType
      && slot.slotType === currentSlotsListOrder.slotType
    );
  });

  if (indexPosition > -1) {
    return savedSlotsListOrder.map((slot, index) => {
      if (indexPosition === index) {
        return currentSlotsListOrder;
      }

      return slot;
    });
  }

  return [
    ...savedSlotsListOrder,
    currentSlotsListOrder,
  ];
};

export const setSlotsOrderInStorage = (currentSlotsListOrder) => {
  const savedSlotsListOrder = getSlotsOrderFromStorage();

  const newSlotsListOrder = savedSlotsListOrder.length
    ? getNewSlotsListOrder(savedSlotsListOrder, currentSlotsListOrder)
    : [currentSlotsListOrder];

  return window.localStorage.setItem(
    UtilizationStorage.SLOTS_ORDER,
    JSON.stringify(newSlotsListOrder),
  );
};

export const getSavedSlotsList = (business, pageType, slotType) => {
  const filteredSlotOrder = getSlotsOrderFromStorage()
    .filter((slot) => {
      return (
        business === slot.business
        && pageType === slot.pageType
        && slotType === slot.slotType
      );
    });

  // There should be only one element in this array as we filter by business, pageType and slotType
  // e.g. desktopSlot/mobileSlot
  if (!filteredSlotOrder[0]
    || !filteredSlotOrder[0].slots
    || !filteredSlotOrder[0].slots.length
  ) {
    return [];
  }

  return filteredSlotOrder[0].slots;
};
