import {
  RateCardMerchTypes,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';

const Conversion = [
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Deal Shoveler (1-week)',
    dollars: 25000,
    isUff: 0,
    isAfs: 1,
    vendorMax: 50,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Deal Shoveler (4-weeks)',
    dollars: 100000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Deal Shoveler (2-weeks)',
    dollars: 50000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 78,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Prime Day (Event) Landing Page - Shoveler Inclusion',
    dollars: 20000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 5,
    numberPlacements: 2,
    description: 'Please note that this placement will not be available for incremental pitching',
  },
];

export default Conversion;
