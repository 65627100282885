import { appsyncToLocal } from '../../helpers/translateCampaigns';
import { onUpdatedCampaignReceived } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { Meta: { metadata } } = state;
  const {
    payload: { campaignData: { value: { data: { onUpdateCampaign } } } },
  } = action;

  dispatch(onUpdatedCampaignReceived(appsyncToLocal(onUpdateCampaign, metadata)));

  return next(action);
};
