import React from 'react';
import BulletinBoardForm from '../BulletinBoard/BulletinBoardForm';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const NewAnnouncementPage = () => {
  return (
    <BulletinBoardForm isNew />
  );
};

export default WithHistoryTiming(NewAnnouncementPage, { pageName: PageNames.NEW_ANNOUNCEMENT });
