import styles from './utilization.module.scss';
import { CampaignStatuses } from '../../constants';

export const getUtilizationCellStyles = (status) => ({
  [styles.approved]: status === CampaignStatuses.APPROVED,
  [styles.inputsComplete]: status === CampaignStatuses.INPUTS_COMPLETE,
  [styles.missingInputs]: status === CampaignStatuses.MISSING_INPUTS,
  [styles.canceled]: status === CampaignStatuses.CANCELED,
  [styles.hold]: status === CampaignStatuses.HOLD,
  [styles.copyRevisionNeeded]: status === CampaignStatuses.COPY_REVISION_NEEDED,
  [styles.newEdits]: status === CampaignStatuses.NEW_EDITS,
});
