import { RateCardMerchTypes } from '../../../../constants/rateCard';

const VSSM = {
  'VSSM Storefront Shoveler - Standard (1-week)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler - Standard (1-week)',
    ],
  },
  'VSSM Storefront Shoveler - Standard (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler - Standard (2-weeks)',
    ],
  },
  'VSSM Storefront Shoveler - Standard (4-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler - Standard (4-weeks)',
    ],
  },
  'VSSM Category Shoveler (1-quarter)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Page Shoveler',
    ],
  },
  'VSSM Category Shoveler (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Shoveler (2-weeks)',
    ],
  },
  'VSSM Category Shoveler (4-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Shoveler (4-weeks)',
    ],
  },
};

export default VSSM;
