import { connect } from 'react-redux';
import GridViewPackageRow from './GridViewPackageRow';
import { isRowExpanded } from '../../selectors/gridView';

export const mapStateToProps = (state, { currentPackage }) => {
  const rowId = currentPackage ? currentPackage.id : null;

  return {
    isRowExpanded: isRowExpanded(state, rowId),
  };
};

export default connect(mapStateToProps)(GridViewPackageRow);
