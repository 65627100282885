import { GridViewActions } from 'src/constants';

export const onExportCurrentView = (payload) => ({
  type: GridViewActions.EXPORT_CURRENT_VIEW,
  payload,
});
export const onOpenExportModal = () => ({
  type: GridViewActions.OPEN_EXPORT_MODAL,
});
export const onCloseExportModal = () => ({
  type: GridViewActions.CLOSE_EXPORT_MODAL,
});
export const onSetExportSettings = ({ pageSelection, exportType, exportFilters }) => ({
  type: GridViewActions.SET_EXPORT_SETTINGS,
  payload: { pageSelection, exportType, exportFilters },
});
export const onGetExportSettings = () => ({
  type: GridViewActions.GET_EXPORT_SETTINGS,
});
export const onUploadExportSettings = ({ pageSelection, exportType, exportFilters }) => ({
  type: GridViewActions.UPLOAD_EXPORT_SETTINGS,
  payload: { pageSelection, exportType, exportFilters },
});
