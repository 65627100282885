import React from 'react';
import Package from '../Package';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const NewPackagePage = () => {
  return (
    <Package isNew />
  );
};

export default WithHistoryTiming(NewPackagePage, { pageName: PageNames.NEW_PACKAGE });
