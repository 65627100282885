import { arrayPush, formValueSelector } from 'redux-form';
import {
  createInStoreCampaignShell,
  createOnlineCampaignShell,
} from '../../helpers/campaign';
import { CampaignColumns, CampaignTypes, Form } from '../../constants';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { campaignType } } = action;
  const selector = formValueSelector(Form.NAME);

  const genericOverrides = {
    [CampaignColumns.BUSINESS_START_YEAR.name]: selector(state, CampaignColumns.BUSINESS_START_YEAR.name),
    [CampaignColumns.TIMEZONE_ID.name]: selector(state, CampaignColumns.TIMEZONE_ID.name),
    [CampaignColumns.IS_DEAL.name]: selector(state, CampaignColumns.IS_DEAL.name),
    [CampaignColumns.END_DATE.name]: selector(state, CampaignColumns.END_DATE.name),
    [CampaignColumns.END_TIME.name]: selector(state, CampaignColumns.END_TIME.name),
    [CampaignColumns.START_DATE.name]: selector(state, CampaignColumns.START_DATE.name),
    [CampaignColumns.START_TIME.name]: selector(state, CampaignColumns.START_TIME.name),
  };

  const campaignShell = campaignType === CampaignTypes.IN_STORE
    ? createInStoreCampaignShell(genericOverrides)
    : createOnlineCampaignShell(genericOverrides);

  dispatch(arrayPush(Form.NAME, 'campaigns', campaignShell));

  return next(action);
};
