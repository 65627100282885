import { connect } from 'react-redux';
import MerchandisingIntent from './MerchandisingIntent';
import {
  getLimitedPlacementsMoneyUsedPerClass,
  getMainPlacementsMoneyUsedPerClass,
  getTotalInvestment,
  getTotalMerchantClassSpreadPercentage,
} from '../../../../selectors/rateCardSelectors';

export const mapStateToProps = (state) => {
  return {
    totalInvestment: getTotalInvestment(state),
    limitedPlacementsMoneyUsedPerClass: getLimitedPlacementsMoneyUsedPerClass(state),
    mainPlacementsMoneyUsedPerClass: getMainPlacementsMoneyUsedPerClass(state),
    totalMerchantClassSpreadPercentage: getTotalMerchantClassSpreadPercentage(state),
  };
};

export default connect(mapStateToProps)(MerchandisingIntent);
