import { connect } from 'react-redux';
import Notification from './Notification';
import {
  onNotificationMark,
  onNotificationOpen,
} from '../../actionCreators';
import { NotificationsTypes, Urls } from '../../constants';

export const getPackageLink = (packageId, notificationType, campaignId) => {
  if (packageId && campaignId) {
    return notificationType === NotificationsTypes.DELETE_CAMPAIGN
      ? `${Urls.CAMPAIGN_AUDIT_LOG}/${campaignId}`
      : `${Urls.EDIT_PACKAGE}/${packageId}`;
  }
  return notificationType === NotificationsTypes.DELETE_PACKAGE
    ? `${Urls.PACKAGE_AUDIT_LOG}/${packageId}`
    : `${Urls.EDIT_PACKAGE}/${packageId}`;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    id, isRead, campaignId, packageId, notificationType,
  } = ownProps;
  const { Notifications: { readList, markedList } } = state;

  const isMarkedAsRead = readList && readList[id] !== undefined
    ? readList[id]
    : isRead;

  return {
    isRead: !!isMarkedAsRead,
    isMarked: !!(markedList && markedList[id] && markedList[id].isMarked),
    packageLink: getPackageLink(packageId, notificationType, campaignId),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onOpen: (id) => {
      dispatch(onNotificationOpen(id));
    },
    onMark: (notification) => {
      dispatch(onNotificationMark([notification]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
