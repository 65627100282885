import { getPaginatedPackages } from 'src/selectors/gridView';
import {
  onAddErrorToCampaign,
  onHideLoadingSpinner,
  onShowLoadingSpinner,
} from '../../actionCreators';
import { getUtilizationForSlot } from '../../helpers/utilization';
import { CampaignColumns } from '../../constants';

// TODO: Is this feature used at all? Need to add metrics and see. If not, we should probably
//  remove it later
export default (dispatch, getState, action, next) => {
  const state = getState();

  const campaignsToUpdate = getPaginatedPackages(state);
  const { GridViewPage: { campaigns } } = state;

  dispatch(onShowLoadingSpinner());

  const promises = [];
  campaignsToUpdate.forEach((campaign) => {
    const {
      pageID,
      mobileSlot,
      desktopSlot,
      startDateTime,
      endDateTime,
    } = campaign;
    // These are long-running functions. Using setTimeouts to prevent blocking of the UI
    promises.push(new Promise((resolve) => {
      setTimeout(() => {
        const desktop = getUtilizationForSlot(
          campaigns,
          pageID,
          desktopSlot,
          startDateTime,
          endDateTime,
          CampaignColumns.DESKTOP_SLOT.name,
        );
        if (desktop.totalSOV > 1) {
          const value = Math.round((desktop.totalSOV + Number.EPSILON) * 100) / 100;
          dispatch(onAddErrorToCampaign(
            campaign,
            CampaignColumns.DESKTOP_SLOT.name,
            `SOV Value of ${value} exceeds allowed value`,
          ));
        }
        resolve();
      });
    }));
    promises.push(new Promise((resolve) => {
      setTimeout(() => {
        const mobile = getUtilizationForSlot(
          campaigns,
          pageID,
          mobileSlot,
          startDateTime,
          endDateTime,
          CampaignColumns.MOBILE_SLOT.name,
        );
        if (mobile.totalSOV > 1) {
          const value = Math.round((mobile.totalSOV + Number.EPSILON) * 100) / 100;
          dispatch(onAddErrorToCampaign(
            campaign,
            CampaignColumns.MOBILE_SLOT.name,
            `SOV Value of ${value} exceeds allowed value`,
          ));
        }
        resolve();
      });
    }));
  });
  Promise.all(promises).then(() => {
    dispatch(onHideLoadingSpinner());
  });

  return next(action);
};
