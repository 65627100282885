import _ from 'lodash';
import {
  onAddCampaign,
  onDeleteCampaign,
  onEditCampaign,
  onSendPackageEdit,
} from '../../actionCreators';
import { getPackage } from '../../selectors/package';

const getCampaignIdsToDelete = (originalCampaigns, newCampaigns) => {
  const originalCampaignIds = originalCampaigns.map((campaign) => campaign.id);
  const newCampaignIds = newCampaigns.map((campaign) => campaign.id);

  return _.difference(
    originalCampaignIds,
    newCampaignIds,
  );
};

export const getCampaignsToCreate = (newCampaigns) => {
  if (!Array.isArray(newCampaigns)) {
    return [];
  }
  return newCampaigns.filter((campaign) => !campaign.id);
};

export const getCampaignsToUpdate = (originalCampaigns, newCampaigns) => {
  if (!Array.isArray(originalCampaigns) || !Array.isArray(newCampaigns)) {
    return [];
  }
  const campaignIdsToDelete = getCampaignIdsToDelete(
    originalCampaigns,
    newCampaigns,
  );

  return newCampaigns.filter((campaign) => {
    // eslint-disable-next-line no-shadow
    const originalCampaign = originalCampaigns.find((originalCampaign) => originalCampaign.id === campaign.id);
    if (!originalCampaign) {
      return false;
    }
    const hasAnyDiff = !_.isEqual(campaign, originalCampaign);
    const shouldKeep = campaign.id && !campaignIdsToDelete.includes(campaign.id);

    return hasAnyDiff && shouldKeep;
  });
};

export const getCampaignsToDelete = (originalCampaigns, newCampaigns) => {
  if (!Array.isArray(originalCampaigns) || !Array.isArray(newCampaigns)) {
    return [];
  }
  const campaignIdsToDelete = getCampaignIdsToDelete(
    originalCampaigns,
    newCampaigns,
  );

  return originalCampaigns.filter((campaign) => {
    return campaignIdsToDelete.includes(campaign.id);
  });
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { updatedPackage, options } } = action;
  // eslint-disable-next-line camelcase
  const { id, business_startYear } = updatedPackage;

  const currentPackage = getPackage(state, id);
  if (!currentPackage) {
    return next(action);
  }

  next({
    ...action,
    payload: {
      updatedPackage: {
        ...action.payload.updatedPackage,
        version: currentPackage.version,
      },
    },
  });

  dispatch(onSendPackageEdit(id, options));

  if (options && options.skipCampaignsUpdate) {
    return next(action);
  }

  const campaignsToCreate = getCampaignsToCreate(updatedPackage.campaigns);
  const campaignsToUpdate = getCampaignsToUpdate(
    currentPackage.campaigns,
    updatedPackage.campaigns,
  );
  const campaignsToDelete = getCampaignsToDelete(
    currentPackage.campaigns,
    updatedPackage.campaigns,
  );

  if (campaignsToCreate && campaignsToCreate.length) {
    campaignsToCreate.forEach((campaign) => {
      dispatch(onAddCampaign(id, business_startYear, campaign));
    });
  }

  if (campaignsToUpdate && campaignsToUpdate.length) {
    campaignsToUpdate.forEach((campaign) => {
      dispatch(onEditCampaign(campaign));
    });
  }

  if (campaignsToDelete && campaignsToDelete.length) {
    campaignsToDelete.forEach((campaign) => {
      dispatch(onDeleteCampaign(campaign));
    });
  }

  return next(action);
};
