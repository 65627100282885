import {
  InStoreUtilizationFilterGroupNames,
  InStoreUtilizationGroupByCategories,
  InStoreUtilizationPlacementTypes,
} from '../constants/inStoreUtilization';
import { PlacementType, VendorTypes } from '../constants';
import { getStringWith } from './common';

const hasAllFilterOptionsSelected = (filterOptions, selectedOptions) => {
  return Object.keys(filterOptions).length === Object.keys(selectedOptions).length;
};
const hasFilters = (filters) => filters && (Object.keys(filters).length > 0);
const getNormalizedStr = getStringWith(['toLowerCase', 'trim']);

export const getFilteredInStoreCampaigns = (
  packages,
  campaigns,
  startDateTime,
  endDateTime,
  filterOptions,
  selectedFilters,
) => {
  const inStorePlacement = getNormalizedStr(PlacementType.IN_STORE);
  const {
    PLACEMENT_TYPES, VERTICAL, PLACEMENT, STATUS,
  } = InStoreUtilizationFilterGroupNames;

  const {
    [PLACEMENT_TYPES.name]: placementTypesFilterOptions,
  } = filterOptions;

  const {
    [PLACEMENT_TYPES.name]: selectedPlacementTypes,
    [VERTICAL.name]: selectedVerticals,
    [PLACEMENT.name]: selectedPlacements,
    [STATUS.name]: selectedStatuses,
  } = selectedFilters;
  const campaignsWithPackageFields = campaigns.map((campaign) => {
    const campaignPackage = packages[campaign.packageId];
    const {
      vendor,
      vertical,
    } = campaignPackage;
    return {
      ...campaign,
      vendor,
      vertical,
    };
  });
  return campaignsWithPackageFields.filter((campaign) => {
    const {
      pageType,
      pageName,
      placement,
      startDateTime: campaignStartDateTimeUTC,
      endDateTime: campaignEndDateTimeUTC,
      vendor,
      vertical,
      campaignType,
      status,
    } = campaign;

    const isWithinDateTimeRange = (
      campaignEndDateTimeUTC >= startDateTime.format()
      && campaignStartDateTimeUTC <= endDateTime.format()
    );
    const hasBasicPageFiltersMatch = (
      getNormalizedStr(pageType) === inStorePlacement
      && getNormalizedStr(pageName) === inStorePlacement
      && getNormalizedStr(campaignType) === inStorePlacement
    );
    const hasBasicCriteriaMatch = (
      isWithinDateTimeRange
      && hasBasicPageFiltersMatch
    );

    const { VFCC, HOUSE } = InStoreUtilizationPlacementTypes;

    const hasPlacementTypesFilters = hasFilters(selectedPlacementTypes);
    const hasVFCCPlacementType = hasPlacementTypesFilters && selectedPlacementTypes[VFCC.name];
    const hasHousePlacementType = hasPlacementTypesFilters && selectedPlacementTypes[HOUSE.name];

    // If no placement types are selected, we shouldn't display anything
    if (!hasPlacementTypesFilters || !(hasVFCCPlacementType || hasHousePlacementType)) {
      return false;
    }

    /**
     * Filtering campaigns based on filters selected on In-Store Utilization page
     * (@see Urls.IN_STORE_UTILIZATION in browser)
     *
     * Example:
     *  In order to apply filter criteria by "Placement", it first checks whether any of the
     *  filters related to "Placements" have been selected (isCriteriaSelected) and then determines
     *  whether the current campaign matches the selected filters (matchesCriteria).
     * */
    const criteriaList = [
      {
        isCriteriaSelected: !hasAllFilterOptionsSelected(
          placementTypesFilterOptions,
          selectedPlacementTypes,
        ),
        matchesCriteria: () => {
          return (hasHousePlacementType
            ? getNormalizedStr(vendor) === getNormalizedStr(VendorTypes.HOUSE)
            : getNormalizedStr(vendor) !== getNormalizedStr(VendorTypes.HOUSE));
        },
      },
      {
        isCriteriaSelected: hasFilters(selectedVerticals),
        matchesCriteria: () => selectedVerticals[vertical],
      },
      {
        isCriteriaSelected: hasFilters(selectedPlacements),
        matchesCriteria: () => {
          if (selectedPlacements[placement]) {
            return true;
          }

          const { ENDCAP_INCLUSION, ENDCAP } = InStoreUtilizationGroupByCategories;

          const normalizedPlacement = getNormalizedStr(placement);
          const normalizedEndcapInclusion = getNormalizedStr(ENDCAP_INCLUSION.label);
          const normalizedEndcap = getNormalizedStr(ENDCAP.label);

          if (
            selectedPlacements[ENDCAP_INCLUSION.label]
            && normalizedPlacement.startsWith(normalizedEndcapInclusion)
          ) {
            return true;
          }

          return (
            selectedPlacements[ENDCAP.label]
            && normalizedPlacement.includes(normalizedEndcap)
            && !normalizedPlacement.startsWith(normalizedEndcapInclusion)
          );
        },
      },
      {
        isCriteriaSelected: hasFilters(selectedStatuses),
        matchesCriteria: () => selectedStatuses[status],
      },
    ];

    const hasMainCriteriaMatch = criteriaList.every((criteria) => {
      const { isCriteriaSelected, matchesCriteria } = criteria;

      return isCriteriaSelected ? matchesCriteria() : true;
    });
    return hasBasicCriteriaMatch && hasMainCriteriaMatch;
  });
};
