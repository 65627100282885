import { connect } from 'react-redux';

import { onActiveCellInputSubmit, onActiveCellInputUpdate } from 'src/actionCreators';
import { defaultDispatchToProps, defaultStateToProps } from '../helpers';

import TimeInput from '../../StyledInputs/TimeInput';

export const mapStateToProps = (state) => {
  return {
    ...defaultStateToProps(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeInput);
