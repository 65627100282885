import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import {
  ASIN_DELIMETER,
  CARET,
  DEFAULT_TIMEZONE_ID,
  Language,
  SovTypes,
  Validation,
} from '../constants';
import { NonStringCampaignFields } from '../constants/campaign';
import extractAsins from './extractAsins';
import { parseDateAndTimeToISOString } from './dateTime';
import { convertDecimalToPercent, getNumber, isGenericFreshBusiness } from './common';
import { isPackage } from './package';

const splitDateAndTime = (dateTime, timezoneId = DEFAULT_TIMEZONE_ID) => {
  if (_.isEmpty(dateTime)) {
    return [];
  }
  const dateTimeZone = momentTimezone.tz(dateTime, timezoneId);

  return {
    date: dateTimeZone.format('YYYY-MM-DD'),
    time: dateTimeZone.format('HH:mm'),
  };
};

// TODO: Check this function correctly formats ASINs
const extractAppSyncAsinsString = (string, enforceFormatting = true) => {
  if (!string) { return ''; }
  try {
    // This is for formatting improper strings from appsync's subscription updates
    // We can remove once appsync is updated properly
    const formattedString = string.replace(/\\/g, '').replace(/^"/g, '').replace(/"$/g, '');
    return JSON.parse(formattedString).join(ASIN_DELIMETER);
  } catch (e) {
    // TODO: log error here
    if (enforceFormatting) {
      return '';
    }
    return string;
  }
};

const extractAsinsFromAppsyncFormat = (item) => {
  return Array.isArray(item) ? item.join(ASIN_DELIMETER) : extractAppSyncAsinsString(item);
};

const convertAsinsToAppsyncFormat = (item) => {
  return item && item.length ? extractAsins(item) : [];
};

export const convertCsCampaignIds = (csCampaignIds) => {
  // If a campaign already has csCampaignIds and it's a map, then we're good
  // If it doesn't, then we have to create an empty map for it as the back-end
  // expects for a map and doesn't accept a string

  if (_.isObject(csCampaignIds) && !_.isEmpty(csCampaignIds)) {
    const { creativeId, desktopPlacementId, mobilePlacementId } = csCampaignIds;
    return {
      creativeId: creativeId || '',
      desktopPlacementId: desktopPlacementId || '',
      mobilePlacementId: mobilePlacementId || '',
    };
  }
  return {
    creativeId: '',
    desktopPlacementId: '',
    mobilePlacementId: '',
  };
};

const parseSovToPercent = (sov) => {
  const sovValue = convertDecimalToPercent(getNumber(sov));

  return sovValue > -1 ? sovValue : 0;
};

const parseSovToDecimal = (sov, sovType) => {
  const sovValue = sovType === SovTypes.RANDOM ? 0 : sov;

  if (!sovValue) {
    return 0;
  }

  const value = parseFloat(sovValue);
  if (!value) {
    return 0;
  }

  return Number.isInteger(value)
    ? sov / 100
    : value;
};

const determineAutoFields = (item, metadata) => {
  const { fields: { pageGroup } } = metadata;
  const autoFields = {};
  if (item.pageName && pageGroup && pageGroup.options) {
    autoFields.pageGroup = pageGroup.options[item.pageName];
  }
  return autoFields;
};

const createError = (text, preventApproval = false) => {
  return {
    text,
    preventApproval,
  };
};

export const getItemErrors = (campaign) => {
  const errors = {};
  if (campaign && !_.isEmpty(campaign.asins) && !_.isEmpty(campaign.browseNodeIds)) {
    errors.asins = createError(Language.VALIDATION_ASINS_NODE_IDS_BOTH_SET, true);
  }
  if (
    isGenericFreshBusiness(campaign.business)
    && campaign.copy
    && campaign.copy.indexOf(CARET) !== -1
  ) {
    errors.copy = createError(Language.CARET_PRESENT);
  }
  if (
    isGenericFreshBusiness(campaign.business)
    && campaign.copy
    && campaign.copy.length > Validation.COPY_MAX_LENGTH
  ) {
    errors.copy = createError(Language.VALIDATION_MAX_LENGTH);
  }
  if (
    isGenericFreshBusiness(campaign.business)
    && campaign.subHeadline
    && campaign.subHeadline.length > Validation.SUB_HEADLINE_MAX_LENGTH
  ) {
    errors.subHeadline = createError(Language.VALIDATION_MAX_LENGTH);
  }
  return errors;
};

export const appsyncToLocal = (item, metadata) => {
  const {
    startDateTime,
    endDateTime,
    timezoneId,
  } = item;
  const currentTimezoneId = timezoneId || DEFAULT_TIMEZONE_ID;
  const { date: startDate, time: startTime } = splitDateAndTime(startDateTime, currentTimezoneId);
  const { date: endDate, time: endTime } = splitDateAndTime(endDateTime, currentTimezoneId);

  const dateTimeColumns = {
    startDate,
    startTime,
    endDate,
    endTime,
  };

  const { asins, heroAsins, sov, browseNodeIds, inventoryAwareness } = item;

  const itemFields = isPackage(item)
    ? dateTimeColumns
    : {
      ...dateTimeColumns,
      sov: parseSovToPercent(sov),
      asins: extractAsinsFromAppsyncFormat(asins),
      heroAsins: extractAsinsFromAppsyncFormat(heroAsins),
      browseNodeIds: extractAsinsFromAppsyncFormat(browseNodeIds),
      inventoryAwareness: extractAsinsFromAppsyncFormat(inventoryAwareness),
      ...determineAutoFields(item, metadata),
    };

  const translatedItem = {
    ...item,
    ...itemFields,
  };

  translatedItem.errors = getItemErrors(translatedItem);
  const nonNulledTranslatedItem = {};
  // TODO: Remove this once we can conclusively test other components dont break when some fields are null instead of ""
  Object.keys(translatedItem).forEach((column) => {
    if (NonStringCampaignFields.includes(column)) {
      nonNulledTranslatedItem[column] = translatedItem[column];
    } else {
      nonNulledTranslatedItem[column] = translatedItem[column] || '';
    }
  });

  return nonNulledTranslatedItem;
};

export const localToAppsync = (item) => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    timezoneId,
    csCampaignIds,
    sov,
    sovType,
    asins,
    browseNodeIds,
    heroAsins,
    inventoryAwareness,
  } = item;

  const currentTimezoneId = timezoneId || DEFAULT_TIMEZONE_ID;

  const dateTimeColumns = {
    startDateTime: parseDateAndTimeToISOString(startDate, startTime, currentTimezoneId),
    endDateTime: parseDateAndTimeToISOString(endDate, endTime, currentTimezoneId),
    timezoneId: currentTimezoneId,
  };

  const itemFields = isPackage(item)
    ? dateTimeColumns
    : {
      ...dateTimeColumns,
      csCampaignIds: convertCsCampaignIds(csCampaignIds),
      sov: parseSovToDecimal(sov, sovType),
      asins: convertAsinsToAppsyncFormat(asins),
      browseNodeIds: convertAsinsToAppsyncFormat(browseNodeIds),
      heroAsins: convertAsinsToAppsyncFormat(heroAsins),
      inventoryAwareness: convertAsinsToAppsyncFormat(inventoryAwareness),
    };

  const translatedItem = {
    ...item,
    ...itemFields,
  };

  delete translatedItem.pageGroup;
  delete translatedItem.startDate;
  delete translatedItem.endDate;
  delete translatedItem.startTime;
  delete translatedItem.endTime;
  delete translatedItem.errors;
  delete translatedItem.tempId;

  return translatedItem;
};

/* eslint-disable no-param-reassign */
export const formatCampaignForMutationInput = (campaign) => {
  return {
    ...campaign,
    version: campaign.version ? campaign.version + 1 : 1,
  };
};
export const formatPackageForMutationInput = (packageToUpdate, isClone) => {
  const formattedPackage = {
    ...packageToUpdate,
    version: packageToUpdate.version ? packageToUpdate.version + 1 : 1,
  };

  delete formattedPackage.campaigns;
  delete formattedPackage.isPackage;
  delete formattedPackage.changes;
  if (isClone) {
    delete formattedPackage.refMarker;
  }

  return formattedPackage;
};

export const getExcludedFields = (extraFieldsToExclude = []) => {
  const baseExcludes = [
    'rowId',
    'errors',
    'expectedVersion',
    // Auto-generated field
    'createdAt',
    'updatedAt',
    'refId',
  ];

  if (extraFieldsToExclude.length) {
    return [
      ...baseExcludes,
      ...extraFieldsToExclude,
    ];
  }

  return baseExcludes;
};
