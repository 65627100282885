import { createSelector } from 'reselect';
import {
  Comparators,
  FilterGroupTypes,
  SortOrder,
  defaultSortColumns,
} from '../constants';
import { isEmptyValue } from '../helpers/common';
import { getPackagesForCurrentBusinessStartYears } from './package';

const defaultSort = [{
  column: defaultSortColumns.CREATED_AT,
  order: SortOrder.DESCENDING.value,
}];

export const getFilters = (state) => {
  return state.GridViewPage.filters;
};

export const getSorts = (state) => {
  const { GridViewPage: { sorts } } = state;
  return sorts.length > 0 ? sorts : defaultSort;
};

export const checkFilterMatch = (item, filter) => {
  const { column, comparator, value } = filter;
  // eslint-disable-next-line no-prototype-builtins
  if (!item.hasOwnProperty(column)) {
    return false;
  }

  const columnValue = !isEmptyValue(item[column])
    ? String(item[column]).toUpperCase().trim()
    : '';
  const filterValue = (!isEmptyValue(value) || parseInt(value) === 0)
    ? String(value).toUpperCase().trim()
    : '';

  switch (comparator) {
    case Comparators.GREATER_THAN:
      if (!columnValue && parseInt(columnValue) !== 0) {
        return false;
      }
      return columnValue > filterValue;
    case Comparators.LESS_THAN:
      if (!columnValue && parseInt(columnValue) !== 0) {
        return false;
      }
      return columnValue < filterValue;
    case Comparators.EQUALS:
      return columnValue === filterValue;
    case Comparators.DOES_NOT_EQUAL:
      return columnValue !== filterValue;
    case Comparators.CONTAINS:
      return columnValue.includes(String(filterValue));
    case Comparators.DOES_NOT_CONTAIN:
      return !columnValue.includes(String(filterValue));
    case Comparators.EMPTY:
      return columnValue === '' || columnValue === null || columnValue === undefined;
    case Comparators.NOT_EMPTY:
      return columnValue !== '' && columnValue !== null && columnValue !== undefined;
    default:
      return false;
  }
};

export const matchesGroupFilter = (pkg, filterRulesGroup) => {
  const { type, rules } = filterRulesGroup;

  if (type === FilterGroupTypes.AND) {
    // eslint-disable-next-line no-use-before-define
    return rules.every((rule) => matchesFilter(pkg, rule));
  } if (type === FilterGroupTypes.OR) {
    // eslint-disable-next-line no-use-before-define
    return rules.some((rule) => matchesFilter(pkg, rule));
  }

  return false;
};

export const matchesFilter = (pkg, filter) => {
  if (filter.type && filter.rules) {
    return matchesGroupFilter(pkg, filter);
  }

  const matchesPackage = checkFilterMatch(pkg, filter);
  const matchesCampaigns = pkg.campaigns && pkg.campaigns.some((campaign) => checkFilterMatch(campaign, filter));

  return matchesPackage || matchesCampaigns;
};

export const filterPackages = (packages, filters) => {
  const filteredPackages = {};

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in packages) {
    const pkg = packages[key];

    const matchesAllFilters = filters.every((filter) => matchesFilter(pkg, filter));

    if (matchesAllFilters) {
      filteredPackages[key] = pkg;
    }
  }

  return filteredPackages;
};

export const createSortFunction = (fn, sort) => {
  // If the two comparisons are equal, go into the next sort until you find
  // an unequal column, and sort on that.
  const { column, order } = sort;
  return (a, b) => {
    if (a[column] === b[column]) { return fn(a, b); }
    const sortValue = a[column] > b[column] ? -1 : 1;
    // reverse the order if descending
    return sortValue * (order === SortOrder.DESCENDING.value ? 1 : -1);
  };
};

export const getFilteredPackages = createSelector(
  [getFilters, getPackagesForCurrentBusinessStartYears],
  (filters, packages) => {
    return filterPackages(packages, filters);
  },
);

export const getSortedPackages = createSelector(
  [getSorts, getFilteredPackages],
  (sorts, packages) => {
    const noSort = () => 0;
    const sortFunction = [...sorts].reverse().reduce(createSortFunction, noSort);
    return Object.values(packages).sort(sortFunction);
  },
);
