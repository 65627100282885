import { onUpdateCampaignColumn } from '../../actionCreators';
import extractAsins from '../../helpers/extractAsins';
import {
  ASINColumns,
  ASIN_DELIMETER,
} from '../../constants';
import { getCampaign } from '../../selectors/campaign';

export default (dispatch, getState, action, next) => {
  const { payload: { id, packageId } } = action;
  const state = getState();

  const item = getCampaign(state, id, packageId);
  if (!item) {
    return next(action);
  }

  ASINColumns.forEach((columnName) => {
    const ASINColumn = item && item[columnName];
    if (!ASINColumn) {
      return;
    }
    const parsedAsins = extractAsins(ASINColumn).join(ASIN_DELIMETER);
    dispatch(onUpdateCampaignColumn(
      item,
      columnName,
      parsedAsins,
    ));
  });

  return next(action);
};
