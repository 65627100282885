/* eslint-disable no-shadow */
import _ from 'lodash';
import { createSelector } from 'reselect';
import { CachedCampaignsConfig } from 'src/constants';
import { areCampaignsLoaded } from 'src/selectors/campaign';
import { getSortedPackages } from 'src/selectors/campaignFiltersSelectors';
import { arePackagesLoaded } from 'src/selectors/package';

export const areRowsExpanded = (state) => {
  return state.GridViewPage.areRowsExpanded;
};

export const getExpandedRowOverrides = (state) => {
  return state.GridViewPage.expandedRowOverrides;
};

export const isRowExpanded = createSelector(
  [areRowsExpanded, getExpandedRowOverrides, (state, packageId) => packageId],
  (areRowsExpanded, expandedRowOverrides, packageId) => {
    // eslint-disable-next-line no-prototype-builtins
    if (expandedRowOverrides.hasOwnProperty(packageId)) {
      return expandedRowOverrides[packageId];
    }

    return areRowsExpanded;
  },
);
export const isLoadingFinished = createSelector(
  [arePackagesLoaded, areCampaignsLoaded],
  (arePackagesLoaded, areCampaignsLoaded) => {
    return arePackagesLoaded && areCampaignsLoaded;
  },
);
export const getActiveCell = (state) => {
  return state.GridViewPage.activeCell;
};
export const getActiveCellValue = (state) => {
  return state.GridViewPage.activeCellValue;
};
export const getPaginationBounds = (state) => {
  const {
    GridViewPage: { paginationPage, paginationItemsPerPage },
  } = state;

  const currentPackages = getSortedPackages(state);

  const minPage = 0;
  const maxPage = currentPackages.length <= paginationItemsPerPage
    ? minPage
    : Math.ceil(currentPackages.length / paginationItemsPerPage);

  let currentPage = paginationPage - 1;
  if (currentPage < minPage) {
    currentPage = minPage;
  }
  if (currentPage > maxPage) {
    currentPage = maxPage;
  }

  return [
    paginationItemsPerPage * currentPage,
    paginationItemsPerPage * (currentPage + 1),
  ];
};
export const getLastUsedPackageIds = (getState) => {
  return getSortedPackages(getState())
    .slice(0, CachedCampaignsConfig.COUNT)
    .map((currentPackage) => currentPackage.id);
};
export const isActiveRow = createSelector(
  [getActiveCell, (state, id) => id],
  (activeCell, id) => {
    if (!activeCell || _.isEmpty(activeCell.item)) {
      return false;
    }
    const { item } = activeCell;

    return (item.id && item.id === id);
  },
);
export const createGetPaginatedPackages = () => {
  let lastPackages = null;
  let lastPaginationBounds = null;
  let lastResult = null;

  return (state) => {
    const packages = getSortedPackages(state);
    const paginationBounds = getPaginationBounds(state);
    if (!_.isEqual(lastPackages, packages)
      || !_.isEqual(lastPaginationBounds, paginationBounds)) {
      lastPackages = packages;
      lastPaginationBounds = paginationBounds;

      lastResult = packages.slice(paginationBounds[0], paginationBounds[1]);
    }
    return lastResult;
  };
};
export const getPaginatedPackages = createGetPaginatedPackages();
