import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InputGroup from '@amzn/meridian/input-group';
import TextAreaInput from '@amzn/meridian/textarea';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import { textAreaControl } from '../../../helpers/formRenders';
import StyledInput from '../../StyledInputs/Input';
import { isValidUrl } from '../../../helpers/common';
import styles from './JsonInput.module.scss';

const JsonInputControl = (props) => {
  const {
    input,
    placeholder,
    customOnBlur,
    onInputChange,
    isGridViewControl,
  } = props;
  const { name, value: inputValue } = input;
  const [json, setJson] = useState(inputValue);

  useEffect(() => {
    setJson(inputValue);
  }, [inputValue]);

  const items = [];

  // gridview requires different props for standard input field
  const defaultControl = isGridViewControl
    ? StyledInput({
      inputValue,
      onInputChange,
      onBlur: customOnBlur,
      placeholder,
    })
    : textAreaControl(props);

  const onBlur = isGridViewControl
    ? () => {}
    : () => customOnBlur(name, json);

  let parsedJson = '';

  try {
    parsedJson = JSON.parse(json);
    if (Object.keys(parsedJson).length === 0) {
      return defaultControl;
    }
    Object.entries(parsedJson).forEach(([label, value]) => {
      items.push({ label, value });
    });
  } catch {
    return defaultControl;
  }

  const getLink = (link) => {
    if (!link || !isValidUrl(link)) { return null; }
    return (
      <div className={styles.floatingLink}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon tokens={exportSmallTokens} />
        </Link>
      </div>
    );
  };

  const inputList = items.map((item) => {
    const itemLabel = placeholder
      ? `${placeholder} - ${item.label}`
      : item.label;
    return (
      <div key={item.label} className={styles.itemContainer}>
        {getLink(item.value)}
        <TextAreaInput
          key={item.label}
          value={item.value}
          label={itemLabel}
          onBlur={onBlur}
          onChange={
            (newValue) => {
              const newJson = JSON.stringify({
                ...parsedJson,
                [item.label]: newValue,
              });
              onInputChange(newJson);
              setJson(newJson);
            }
          }
        />
      </div>
    );
  });

  const fieldList = (
    <InputGroup direction="column">
      {inputList}
    </InputGroup>
  );

  return (
    <div className={styles.multiTextContainer}>
      {fieldList}
    </div>
  );
};

JsonInputControl.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  customOnBlur: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  isGridViewControl: PropTypes.bool,
};

JsonInputControl.defaultProps = {
  placeholder: null,
  isGridViewControl: false,
  onInputChange: () => {},
};

export default JsonInputControl;
