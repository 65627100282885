import { connect } from 'react-redux';
import { getFormattedDateTime } from '../../helpers/dateTime';
import { onCommentsDelete } from '../../actionCreators';
import Comment from './Comment';

export const mapStateToProps = (state, { createdAt }) => {
  const {
    User: {
      currentUser: { username },
      timeZone,
    },
  } = state;

  return {
    isOwnComment: (createdBy) => {
      return createdBy === username;
    },
    formattedCreatedAt: getFormattedDateTime(createdAt, {
      timeZone,
    }),
    createdAt,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteComment: ({ campaignId, createdAt }) => {
      dispatch(onCommentsDelete({ campaignId, createdAt }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
