import {
  BulkImportActions, BulkImportWorkMode, InputTypes,
  PerformanceTimerNames,
} from '../../constants';
import {
  onBulkImportStartFileParsing,
  onPerformanceTimerStart,
  onPerformanceTimerStop,
} from '../../actionCreators';

import performImport from './performImport';
import processBulkImportData from './processBulkImportData';
import actionCompleted from './actionCompleted';
import performAssetsImport from './performAssetsImport';
import createColumn from '../../helpers/createColumn';

export default ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  if (action.type === BulkImportActions.PROCESS_FILE) {
    const { payload: { data, workMode } } = action;
    const { Meta: { metadata: { columnOrder } } } = state;
    const isRateCardWorkMode = workMode === BulkImportWorkMode.RATE_CARD;
    dispatch(onBulkImportStartFileParsing());

    const newColumnOrder = [...columnOrder];
    if (isRateCardWorkMode) {
      newColumnOrder.push(createColumn('assetId', 'Asset ID', InputTypes.INPUT));
      newColumnOrder.push(createColumn('rateCardId', 'Rate Card ID', InputTypes.INPUT));
    }

    setTimeout(() => {
      processBulkImportData(dispatch, state, data, newColumnOrder, isRateCardWorkMode);
    }, 10);
  }
  if (action.type === BulkImportActions.PERFORM_IMPORT) {
    performImport(dispatch, getState);
  }
  if (action.type === BulkImportActions.ACTION_COMPLETED) {
    actionCompleted(dispatch, getState, action.payload);
  }
  if (action.type === BulkImportActions.START_IMPORTING) {
    dispatch(onPerformanceTimerStart(PerformanceTimerNames.BULK_IMPORT));
  }
  if (action.type === BulkImportActions.COMPLETE_IMPORTING) {
    dispatch(onPerformanceTimerStop(PerformanceTimerNames.BULK_IMPORT));
  }

  if (action.type === BulkImportActions.PERFORM_ASSETS_IMPORT) {
    performAssetsImport(dispatch, state, action);
  }

  return next(action);
};
