import { connect } from 'react-redux';
import moment from 'moment';
import {
  onDashboardDateRangeSet,
  onDashboardEndTimeSet,
  onDashboardModeToggle,
  onDashboardRunCalc,
  onDashboardStartTimeSet,
  onUtilizationSelectDateRange,
  onUtilizationShowResults,
} from '../../../actionCreators';
import { DateIntervals } from '../../../constants/index';
import SearchPanel from './SearchPanel';

const presetsList = [
  {
    label: 'Next 1 week',
    value: DateIntervals.ONE_WEEK,
  },
  {
    label: 'Next 2 weeks',
    value: DateIntervals.ONE_WEEK * 2,
  },
  {
    label: 'Next 3 weeks',
    value: DateIntervals.ONE_WEEK * 3,
  },
  {
    label: 'Next 4 weeks',
    value: DateIntervals.ONE_WEEK * 4,
  },
];

const getPresets = (presets) => {
  const today = moment().format('Y-MM-DD');
  return presets.map(({ label, value }) => {
    const targetDate = moment().add(value, 'days').format('Y-MM-DD');
    return {
      label,
      value: [today, targetDate],
    };
  });
};

const presets = getPresets(presetsList);

export const mapStateToProps = (state) => {
  const {
    Dashboard: {
      isOverlapMode,
      dateRange,
      startTime,
      endTime,
    },
  } = state;

  return {
    dateRange,
    startTime,
    endTime,
    presets,
    isOverlapMode,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onToggleMode() {
      dispatch(onDashboardModeToggle());
      dispatch(onDashboardRunCalc());
    },
    onDateRangeSelect(value) {
      dispatch(onDashboardDateRangeSet(value));
      dispatch(onUtilizationSelectDateRange(value));
    },
    onButtonClick() {
      dispatch(onDashboardRunCalc());
      dispatch(onUtilizationShowResults());
    },
    onStartTimeSet(value) {
      dispatch(onDashboardStartTimeSet({ startTime: value }));
    },
    onEndTimeSet(value) {
      dispatch(onDashboardEndTimeSet({ endTime: value }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
