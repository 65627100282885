import moment from 'moment';
import { connect } from 'react-redux';
import {
  onInStoreUtilizationCalculateResults,
  onInStoreUtilizationEndTimeSet,
  onInStoreUtilizationSelectDateRange,
  onInStoreUtilizationSetFilter,
  onInStoreUtilizationStartTimeSet,
} from 'src/actionCreators';
import InStoreUtilizationForm from './InStoreUtilizationForm';
import { MomentJsDayNames } from '../../../constants';

export const isDateDisabled = (selectedDateRange) => (date) => {
  const [currentStartDate] = selectedDateRange;
  if (!currentStartDate) {
    return false;
  }

  return moment(date).day() !== MomentJsDayNames.WEDNESDAY
    && moment(date).day() !== MomentJsDayNames.TUESDAY;
};

export const mapStateToProps = (state) => {
  const {
    InStoreUtilizationPage: {
      filterOptions,
      selectedFilters,
      selectedDateRange,
      startTime,
      endTime,
    },
  } = state;

  const shouldDisableButton = () => {
    if (!Object.keys(selectedFilters).length) {
      return true;
    }

    return !selectedDateRange.every((date) => date);
  };

  const isButtonDisabled = shouldDisableButton();

  return {
    filterOptions,
    selectedFilters,
    selectedDateRange,
    startTime,
    endTime,
    isButtonDisabled,
    isDateDisabled,
    initialValues: [],
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCalculateResults() {
      dispatch(onInStoreUtilizationCalculateResults());
    },
    onSetFilter(filterGroupName, optionName, isChecked) {
      dispatch(onInStoreUtilizationSetFilter(filterGroupName, optionName, isChecked));
    },
    onSelectDateRange(data) {
      dispatch(onInStoreUtilizationSelectDateRange(data));
    },
    onStartTimeSet(startTime) {
      dispatch(onInStoreUtilizationStartTimeSet(startTime));
    },
    onEndTimeSet(endTime) {
      dispatch(onInStoreUtilizationEndTimeSet(endTime));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InStoreUtilizationForm);
