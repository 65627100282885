/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import { HashLink as Link } from 'react-router-hash-link';
import { BulkImportText, BulkImportViewMode, GridViewDisplayModes } from '../../../constants/index';

import { campaignColumnProps, campaignProps } from '../../../proptypes';
import styles from '../BulkImporter.module.scss';

import GridView from '../../GridView';
import { getTemplateString } from '../../../helpers/common';

const LanguageTranslator = {
  [BulkImportViewMode.BULK_IMPORT]: {
    REJECTED: 'Rejected rows',
    CREATE: 'Packages to be created',
    UPDATE: 'Packages to be updated',
    FORMATTING_ISSUES: 'Formatting issues',
  },
  [BulkImportViewMode.RATE_CARD]: {
    REJECTED: 'Rejected assets',
    CREATE: 'Assets to be created',
    UPDATE: 'Assets to be updated',
    FORMATTING_ISSUES: 'Formatting issues',
  },
};

const BulkImportPreview = ({
  create,
  update,
  rejected,
  formatIssues,
  mismatchedColumnNames,
  columnOrder,
  isImporting,
  isOmnichannelMode,
  onPerformImport,
  onClear,
  percentageCompleted,
  isRateCardCompatibleMode,
}) => {
  const getLoader = () => (isImporting
    ? (
      <div className={styles.loader}>
        <Loader percentage={percentageCompleted} type="linear" size="large" />
      </div>
    )
    : null);
  const language = isRateCardCompatibleMode
    ? LanguageTranslator[BulkImportViewMode.RATE_CARD]
    : LanguageTranslator[BulkImportViewMode.BULK_IMPORT];

  const createView = create.length ? (
    <a id="create">
      <div className={styles.spacer}>
        <Heading level={3}>{language.CREATE}</Heading>
      </div>
      <div className={styles.gridContainer}>
        <GridView
          packages={create}
          columnOrder={columnOrder}
          showActionBar={false}
          isOmnichannelMode={isOmnichannelMode}
          displayMode={GridViewDisplayModes.PREVIEW_MODE}
        />
      </div>
    </a>
  ) : null;

  const updateView = update.length ? (
    <a id="update">
      <div className={styles.spacer}>
        <Heading level={3}>{language.UPDATE}</Heading>
        <small>
          Note: existing packages will be completely overwritten with the
          values below
        </small>
      </div>
      <div className={styles.gridContainer}>
        <GridView
          packages={update}
          columnOrder={columnOrder}
          showActionBar={false}
          isOmnichannelMode={isOmnichannelMode}
          displayMode={GridViewDisplayModes.PREVIEW_MODE}
        />
      </div>
    </a>
  ) : null;

  const rejectedView = rejected.length ? (
    <a id="rejected">
      <div className={styles.spacer}>
        <Heading level={3}>{language.REJECTED}</Heading>
      </div>
      <div className={styles.gridContainer}>
        <GridView
          packages={rejected}
          columnOrder={columnOrder}
          showActionBar={false}
          isOmnichannelMode={isOmnichannelMode}
          displayMode={GridViewDisplayModes.PREVIEW_MODE}
        />
      </div>
    </a>
  ) : null;
  const checkFormatIssues = getTemplateString(BulkImportText.PREVIEW_CHECK_FORMAT, {
    formatIssues,
  });
  const { wrongColumnNames, missingColumnNames } = mismatchedColumnNames;
  const checkMismatchedColumnNames = getTemplateString(BulkImportText.BULK_IMPORT_COLUMN_MISMATCH, {
    wrongColumnNames: wrongColumnNames.toString(),
    missingColumnNames: missingColumnNames.toString(),
  });
  const formattingIssues = formatIssues > 0 ? (
    <a id="formatting">
      <div className={styles.spacer}>
        <Heading level={3}>{language.FORMATTING_ISSUES}</Heading>
      </div>
      <div className={styles.messaging}>
        {`${formatIssues} rows were rejected due to formatting issues. This may be because the file has the wrong number of columns or the file is in the wrong format`}
        {checkFormatIssues}
      </div>
    </a>
  ) : null;

  const columnMismatch = wrongColumnNames.length > 0 ? (
    <a id="formatting">
      <div className={styles.spacer}>
        <Heading level={3}>Incorrect Column Names</Heading>
      </div>
      <div className={styles.messaging}>
        {checkMismatchedColumnNames}
      </div>
    </a>
  ) : null;

  const getLink = (to, name, count) => {
    const content = `${name} (${count})`;
    return count > 0 ? <Tab value={name}><Link smooth to={`#${to}`} className={styles.tabLink}>{content}</Link></Tab> : null;
  };

  const getImportButton = () => {
    const importButton = create.length || update.length
      ? (
        <Button onClick={onPerformImport} className={styles.button} disabled={isImporting}>
          Import
        </Button>
      )
      : null;

    return importButton;
  };

  const getTitleBar = () => {
    const createLink = getLink('create', 'Create', create.length);
    const updateLink = getLink('update', 'Update', update.length);
    const rejectedLink = getLink('rejected', 'Rejected', rejected.length);
    const formatLink = getLink('formatting', 'Formatting Issues', formatIssues);

    return (
      <div className={styles.linkBar}>
        <TabGroup value="" onChange={() => {}}>
          {rejectedLink}
          {formatLink}
          {createLink}
          {updateLink}
        </TabGroup>
      </div>
    );
  };

  const header = isRateCardCompatibleMode
    ? null
    : (
      <>
        {getTitleBar()}
        <div className={styles.noticeWrapper}>
          <div className={styles.notice}>
            {BulkImportText.PREVIEW_NOTE}
            <br />
            <b>{BulkImportText.PREVIEW_CLICK_IMPORT}</b>
            <div className={styles.actions}>
              {getImportButton()}
              <Button onClick={onClear} className={styles.button} disabled={isImporting}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div>
      {getLoader()}
      {header}
      {rejectedView}
      {formattingIssues}
      {columnMismatch}
      {createView}
      {updateView}
    </div>
  );
};

BulkImportPreview.propTypes = {
  onPerformImport: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  create: PropTypes.arrayOf(campaignProps).isRequired,
  update: PropTypes.arrayOf(campaignProps).isRequired,
  rejected: PropTypes.arrayOf(campaignProps).isRequired,
  isImporting: PropTypes.bool.isRequired,
  formatIssues: PropTypes.number.isRequired,
  columnOrder: campaignColumnProps.isRequired,
  isRateCardCompatibleMode: PropTypes.bool,
};

BulkImportPreview.defaultProps = {
  isRateCardCompatibleMode: false,
};

export default BulkImportPreview;
