/* eslint-disable no-underscore-dangle */
import React from 'react';
import RenderFields from './RenderFields';
import { getMoneyFormatter } from './money';
import { getNumber } from './common';
import { RateCardConfig, RateCardPlacementColumnNames } from '../constants/rateCard';
import BundleInfo from '../components/RateCardForm/BundleInfo';
import { getPlacementsByType } from './rateCard';
import { CampaignType } from '../constants/campaign';
import { Delimiters } from '../constants';

const getFieldNameWithoutPrefix = (name) => {
  if (!name) {
    return '';
  }
  const nameParts = name.split(Delimiters.DOT);
  if (!nameParts || !nameParts.length) {
    return '';
  }

  return nameParts[nameParts.length - 1];
};

class RateCardRenderFields extends RenderFields {
  static _getFieldDescriptionValue = (fields, index) => {
    const fieldsContainer = fields.get(index);
    if (!fieldsContainer) {
      return '';
    }

    return fieldsContainer.description;
  };

  static _getTextFieldValue = (bundlesConfig, placementsConfig, fields, index, name) => {
    if (!fields) {
      return '';
    }

    const fieldsContainer = fields.get(index);
    if (!fieldsContainer) {
      return '';
    }

    const nameWithoutPrefix = getFieldNameWithoutPrefix(name);

    if (nameWithoutPrefix === RateCardPlacementColumnNames.PLACEMENT_NAME.name) {
      const { placementName } = fieldsContainer;

      if (bundlesConfig && bundlesConfig[placementName]) {
        return (
          <BundleInfo
            bundleName={placementName}
            bundleConfig={bundlesConfig[placementName]}
            placementsConfig={placementsConfig}
          />
        );
      }
    }

    if ([
      RateCardPlacementColumnNames.UFF_PLACEMENT.name,
      RateCardPlacementColumnNames.AFS_PLACEMENT.name,
      RateCardPlacementColumnNames.NET_PLACEMENTS.name,
    ].includes(nameWithoutPrefix)) {
      const { plannedPlacements, placementName } = fieldsContainer;

      if (bundlesConfig && bundlesConfig[placementName]) {
        const { placements } = bundlesConfig[placementName];
        const placementsByType = getPlacementsByType(placements, placementsConfig);
        const {
          [CampaignType.ONLINE]: onlinePlacements,
          [CampaignType.IN_STORE]: inStorePlacements,
        } = placementsByType;

        if (name.includes(RateCardPlacementColumnNames.UFF_PLACEMENT.name)) {
          return onlinePlacements.length;
        }
        if (name.includes(RateCardPlacementColumnNames.AFS_PLACEMENT.name)) {
          return inStorePlacements.length;
        }
        if (name.includes(RateCardPlacementColumnNames.NET_PLACEMENTS.name)) {
          return plannedPlacements
            ? (onlinePlacements.length + inStorePlacements.length) * plannedPlacements
            : 0;
        }
      }
    }

    if (nameWithoutPrefix === RateCardPlacementColumnNames.VENDOR_MAX.name
      && getNumber(fieldsContainer.vendorMax) >= RateCardConfig.VENDOR_MAX_LIMIT) {
      return 'N/A';
    }

    return '';
  };

  static _getDollarAmountUsedByClass = (placementMoneyUsedByClass, currentClassName) => {
    if (
      !placementMoneyUsedByClass
      || !currentClassName
      || !placementMoneyUsedByClass
      || !Object.keys(placementMoneyUsedByClass).length
    ) {
      return 0;
    }

    return currentClassName && placementMoneyUsedByClass[currentClassName]
      ? placementMoneyUsedByClass[currentClassName]
      : 0;
  };

  static _getMerchIntentDollarFieldValue = (
    fields,
    index,
    input,
    totalSpread,
    limitedPlacementsMoneyUsedPerClass,
    mainPlacementsMoneyUsedPerClass,
    totalInvestment,
  ) => {
    const defaultDisplayValue = '-';

    if (totalSpread > 100) {
      return defaultDisplayValue;
    }

    const currentRow = fields.get(index);
    const { name } = input;

    if (name.indexOf(RateCardPlacementColumnNames.DOLLARS.name) > -1) {
      const { spread } = currentRow;

      return getMoneyFormatter().format((Math.round((spread / 100) * totalInvestment)));
    }
    if (name.indexOf('used') > -1) {
      const { className } = currentRow;

      const usedMainPlacementMoneyByClass = this._getDollarAmountUsedByClass(
        mainPlacementsMoneyUsedPerClass,
        className,
      );
      const usedLimitedPlacementMoneyByClass = this._getDollarAmountUsedByClass(
        limitedPlacementsMoneyUsedPerClass,
        className,
      );

      return getMoneyFormatter().format((usedMainPlacementMoneyByClass + usedLimitedPlacementMoneyByClass));
    }

    return defaultDisplayValue;
  };

  static merchIntentDollarField = (params) => {
    const {
      fields,
      index,
      input,
      totalSpread,
      limitedPlacementsMoneyUsedPerClass,
      mainPlacementsMoneyUsedPerClass,
      totalInvestment,
    } = params;

    return this.textField({
      ...params,
      input: {
        ...params.input,
        value: this._getMerchIntentDollarFieldValue(
          fields,
          index,
          input,
          totalSpread,
          limitedPlacementsMoneyUsedPerClass,
          mainPlacementsMoneyUsedPerClass,
          totalInvestment,
        ),
      },
    });
  };

  static renderTextField = (params) => {
    const {
      bundlesConfig,
      placementsConfig,
      fields,
      index,
      input: { name, value },
    } = params;

    const customTextValue = this._getTextFieldValue(
      bundlesConfig,
      placementsConfig,
      fields,
      index,
      name,
    );
    const inputValue = (fields && customTextValue) || (fields && customTextValue === 0)
      ? customTextValue
      : value;

    const description = fields && name.includes(RateCardPlacementColumnNames.PLACEMENT_NAME.name)
      ? this._getFieldDescriptionValue(fields, index)
      : null;

    return this.textField({
      ...params,
      description,
      input: {
        ...params.input,
        value: inputValue,
      },
    });
  };
}

export default RateCardRenderFields;
