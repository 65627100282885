import { FormActions } from '../../constants';

export const initialState = {
  requiredFields: [],
  utilization: {},
  validation: {
    utilization: {},
  },
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type } = action;

  switch (type) {
    case FormActions.SET_UTILIZATION: {
      const { payload: utilization } = action;

      if (!utilization
        || !Object.keys(utilization).length) {
        return state;
      }

      return {
        ...state,
        utilization,
      };
    }
    case FormActions.SET_VALIDATION: {
      const { payload: { validationType, data } } = action;

      if (!validationType) {
        return state;
      }

      return {
        ...state,
        validation: {
          ...state.validation,
          [validationType]: data,
        },
      };
    }
    default: {
      return state;
    }
  }
};
