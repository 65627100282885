import { connect } from 'react-redux';

import {
  onCustomViewSetColumnOrder,
  onCustomViewSetModalVisible,
  onCustomViewSetUseView,
} from '../../actionCreators';

import CustomViewModal from './CustomViewModal';

export const mapStateToProps = (state) => {
  const {
    Meta: { metadata: { columnOrder } },
    CustomView: { showModal, customColumnOrder },
  } = state;

  const columns = customColumnOrder || columnOrder.map((column) => {
    return {
      ...column,
      isVisible: true,
    };
  });
  return {
    showModal,
    columnData: columns,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal() { dispatch(onCustomViewSetModalVisible(false)); },
    onSetUseAll() {
      dispatch(onCustomViewSetUseView(false));
      dispatch(onCustomViewSetModalVisible(false));
    },
    onSetColumns(columns) {
      dispatch(onCustomViewSetColumnOrder(columns));
      dispatch(onCustomViewSetUseView(true));
      dispatch(onCustomViewSetModalVisible(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomViewModal);
