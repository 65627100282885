import {
  AlmBrandIds,
  BusinessTypes,
  CampaignColumns,
  DedupeTypes,
  JSONBourneUrls,
  LandingTypes,
  MetricLocations,
  MetricNames,
  MetricTypes,
  PageIds,
  PageTypes,
  SHOP_ALL,
  SearchAliases,
  SeeMoreTexts,
  ShovelerTypes,
  US,
  WidgetTypes,
} from 'src/constants';
import {
  onSendCampaignEdit,
  onThirdPartyIFrameClose,
  onThirdPartyIFrameSetDestination,
  onThirdPartyIFrameSetId,
  onUpdateCampaignColumn,
} from 'src/actionCreators';
import extractAsins from 'src/helpers/extractAsins';
import { getPathStartingFromComposer } from 'src/helpers/thirdPartyIFrame/composer';
import {
  getItemBusiness,
  is3PFRBusiness,
  is3PNABusiness,
  isDEBusiness,
  isESBusiness,
  isEUBusiness,
  isFreshBusiness,
  isITBusiness,
  isJPBusiness,
  isUKBusiness,
  isWFMBusiness,
  isWFMCBusiness,
} from 'src/helpers/common';
import { submitError } from 'src/metrics';
import { getCampaign } from 'src/selectors/campaign';
// schema can be found from:
// https://code.amazon.com/packages/IzarHarmony/blobs/mainline/--/src/schema/kepler/formschema.js

const JSONBOURNE_REDIRECT_URL = btoa(`${window.location.protocol}//${window.location.host}/#/redirectLanding/${LandingTypes.JSON_BOURNE}`);

const getAlmBrandIdForCampaign = (campaign) => {
  const {
    merchant,
  } = campaign;
  const business = getItemBusiness(campaign);
  if (isWFMBusiness(business)) {
    return AlmBrandIds[BusinessTypes.WFM.name];
  }
  if (isWFMCBusiness(business)) {
    return AlmBrandIds[BusinessTypes.WFMC_NA.name];
  }
  if (is3PNABusiness(business)) {
    return AlmBrandIds[BusinessTypes.NA_3P.name][merchant];
  }
  // default to FRESH
  return AlmBrandIds[BusinessTypes.FRESH.name];
};

const getSearchAliasForCampaign = (campaign) => {
  const {
    merchant,
  } = campaign;
  const business = getItemBusiness(campaign);
  if (isWFMBusiness(business)) {
    return SearchAliases[BusinessTypes.WFM.name];
  }
  if (is3PNABusiness(business)) {
    return SearchAliases[BusinessTypes.NA_3P.name][merchant];
  }
  // default to FRESH
  return SearchAliases[BusinessTypes.FRESH.name];
};

const getDedupeRulesOrDefault = (campaign, defaultDedupeRule) => {
  const {
    dedupeRules,
  } = campaign;
  return dedupeRules || defaultDedupeRule;
};

const getBrowseNodeString = (campaign) => {
  const {
    browseNodeIds,
  } = campaign;
  return `/alm/category?almBrandId=${getAlmBrandIdForCampaign(campaign)}&node=${browseNodeIds}`;
};

const getBusinessSpecificSeeMoreString = (campaign) => {
  const business = getItemBusiness(campaign);
  let seeMoreString = SeeMoreTexts[business];
  // default to English if all else fails
  if (seeMoreString === undefined) {
    seeMoreString = SeeMoreTexts[BusinessTypes.FRESH_UK.name];
  }
  return seeMoreString;
};

const getTypeSpecificSettingsForFresh = (campaign) => {
  const {
    shovelerContent,
    asins,
  } = campaign;

  const dedupe = getDedupeRulesOrDefault(
    campaign,
    DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
  );

  let jsonBourneSetting;

  switch (shovelerContent) {
    case ShovelerTypes.BROWSE_NODE: {
      const broseNodeString = getBrowseNodeString(campaign);
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.BROWSE_NODE,
        browseNode: {
          nodeId: broseNodeString,
          dedupe,
        },
        seeMoreDropdown: 'Default',
        seeMoreUrlDisplay: broseNodeString,
      };
      break;
    }
    case ShovelerTypes.SEARCH_QUERY: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `field-asin=${asins}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe,
          filtering: 'MINIMAL',
        },
      };
      break;
    }
    case ShovelerTypes.FIXED_ASIN_LIST: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.FIXED_ASIN_LIST,
        fixedAsinList: {
          asinList: extractAsins(asins || '').join(' '),
          dedupe,
        },
        seeMoreDropdown: 'Default',
        seeMoreUrlDisplay: `/s?i=amazonfresh&field-asin=${asins}`,
      };
      break;
    }
    default: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `field-asin=${asins}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe,
          filtering: 'MINIMAL',
        },
      };
    }
  }

  return jsonBourneSetting;
};

const getTypeSpecificSettingsFor3PNA = (campaign) => {
  const {
    asins,
    browseNodeIds,
    shovelerContent,
  } = campaign;

  const moreUrl = getBrowseNodeString(campaign);

  let jsonBourneSetting;

  switch (shovelerContent) {
    case ShovelerTypes.BROWSE_NODE: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.BROWSE_NODE,
        browseNode: {
          nodeId: `node=${browseNodeIds}`,
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'NONE',
        },
        seeMoreDropdown: 'Custom',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        customSeeMoreUrl: moreUrl,
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    case ShovelerTypes.SEARCH_QUERY: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `field-asin=${asins}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe: getDedupeRulesOrDefault(
            campaign,
            DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
          ),
          filtering: 'MINIMAL',
        },
        moreText: SHOP_ALL,
      };
      break;
    }
    case ShovelerTypes.FIXED_ASIN_LIST: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.FIXED_ASIN_LIST,
        fixedAsinList: {
          asinList: extractAsins(asins || '').join(' '),
          asinRanking: 'Ranking provided by user',
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'NONE',
        },
        seeMoreDropdown: 'Default',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        seeMoreBrandUS: getAlmBrandIdForCampaign(campaign),
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }

    default: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `field-asin=${asins}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe: getDedupeRulesOrDefault(
            campaign,
            DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
          ),
          filtering: 'MINIMAL',
        },
        moreText: SHOP_ALL,
      };
    }
  }

  return jsonBourneSetting;
};

const getTypeSpecificSettingsForWFM = (campaign) => {
  const {
    asins,
    browseNodeIds,
    shovelerContent,
  } = campaign;

  const moreUrl = getBrowseNodeString(campaign);

  let jsonBourneSetting;

  switch (shovelerContent) {
    case ShovelerTypes.BROWSE_NODE: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.BROWSE_NODE,
        browseNode: {
          nodeId: `node=${browseNodeIds}`,
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'NONE',
        },
        seeMoreDropdown: 'Default',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        seeMoreBrandUS: getAlmBrandIdForCampaign(campaign),
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    case ShovelerTypes.SEARCH_QUERY: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: moreUrl,
          dedupe: getDedupeRulesOrDefault(
            campaign,
            DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
          ),
          filtering: 'MINIMAL',
        },
        moreText: SHOP_ALL,
        customSeeMoreUrl: moreUrl,
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    case ShovelerTypes.FIXED_ASIN_LIST: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.FIXED_ASIN_LIST,
        fixedAsinList: {
          asinList: extractAsins(asins || '').join(' '),
          asinRanking: 'Ranking provided by user',
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'NONE',
        },
        seeMoreDropdown: 'Default',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        seeMoreBrandUS: getAlmBrandIdForCampaign(campaign),
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    default: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: moreUrl,
          dedupe: getDedupeRulesOrDefault(
            campaign,
            DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
          ),
          filtering: 'MINIMAL',
        },
        moreText: SHOP_ALL,
        customSeeMoreUrl: moreUrl,
      };
    }
  }

  return jsonBourneSetting;
};

const getTypeSpecificSettingsForWFMC = (campaign) => {
  const {
    asins,
    browseNodeIds,
    shovelerContent,
  } = campaign;

  const moreUrl = getBrowseNodeString(campaign);

  let jsonBourneSetting;

  switch (shovelerContent) {
    case ShovelerTypes.BROWSE_NODE: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.BROWSE_NODE,
        browseNode: {
          nodeId: `node=${browseNodeIds}`,
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'NONE',
        },
        seeMoreDropdown: 'Default',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        seeMoreBrandUS: getAlmBrandIdForCampaign(campaign),
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    case ShovelerTypes.FIXED_ASIN_LIST: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.FIXED_ASIN_LIST,
        fixedAsinList: {
          asinList: extractAsins(asins || '').join(' '),
          asinRanking: 'Ranking provided by user',
          dedupe: getDedupeRulesOrDefault(campaign, DedupeTypes.NONE),
          filtering: 'MINIMAL',
        },
        seeMoreDropdown: 'Default',
        moreText: SHOP_ALL,
        seeMoreMarketplace: US,
        seeMoreBrandUS: getAlmBrandIdForCampaign(campaign),
        seeMoreUrlDisplay: moreUrl,
      };
      break;
    }
    default: {
      jsonBourneSetting = {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: moreUrl,
          dedupe: getDedupeRulesOrDefault(
            campaign,
            DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
          ),
          filtering: 'MINIMAL',
        },
        moreText: SHOP_ALL,
        customSeeMoreUrl: moreUrl,
      };
    }
  }

  return jsonBourneSetting;
};

const getTypeSpecificSettingsForInternational = (campaign) => {
  const {
    shovelerContent,
    browseNodeIds,
    asins,
  } = campaign;
  const dedupe = getDedupeRulesOrDefault(campaign, DedupeTypes.IGNORE_PREVIOUS_RESERVATIONS);
  switch (shovelerContent) {
    case ShovelerTypes.FIXED_ASIN_LIST: {
      return {
        widgetContent: ShovelerTypes.FIXED_ASIN_LIST,
        fixedAsinList: {
          asinList: extractAsins(asins || '').join(' '),
          dedupe,
          filtering: 'MINIMAL',
        },
        moreText: getBusinessSpecificSeeMoreString(campaign),
      };
    }
    case ShovelerTypes.BROWSE_NODE: {
      return {
        widgetContent: ShovelerTypes.BROWSE_NODE,
        browseNode: {
          nodeId: `node=${browseNodeIds}`,
          dedupe,
          filtering: 'NONE',
        },
        moreText: getBusinessSpecificSeeMoreString(campaign),
      };
    }
    case ShovelerTypes.SEARCH_QUERY: {
      return {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `node=${browseNodeIds}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe,
          filtering: 'MINIMAL',
        },
        moreText: getBusinessSpecificSeeMoreString(campaign),
      };
    }
    case ShovelerTypes.PERSONALIZATION: {
      return {
        widgetContent: ShovelerTypes.PERSONALIZATION,
        personalization: 'p13n-alm-aisle',
        moreText: getBusinessSpecificSeeMoreString(campaign),
      };
    }
    default: {
      return {
        widgetContent: ShovelerTypes.SEARCH_QUERY,
        searchQuery: {
          query: `node=${browseNodeIds}&search-alias=${getSearchAliasForCampaign(campaign)}`,
          dedupe,
          filtering: 'MINIMAL',
        },
        moreText: getBusinessSpecificSeeMoreString(campaign),
      };
    }
  }
};

const getTypeSpecificSettings = (campaign) => {
  const business = getItemBusiness(campaign);
  if (isFreshBusiness(business)) {
    return getTypeSpecificSettingsForFresh(campaign);
  }

  if (is3PNABusiness(business)) {
    return getTypeSpecificSettingsFor3PNA(campaign);
  }

  if (isWFMBusiness(business)) {
    return getTypeSpecificSettingsForWFM(campaign);
  }

  if (isWFMCBusiness(business)) {
    return getTypeSpecificSettingsForWFMC(campaign);
  }

  if (isUKBusiness(business) || isDEBusiness(business)
      || isESBusiness(business) || isITBusiness(business)
      || isJPBusiness(business) || is3PFRBusiness(business)) {
    return getTypeSpecificSettingsForInternational(campaign);
  }
  // use Fresh for default for now
  return getTypeSpecificSettingsForFresh(campaign);
};

const updateSettingForCheckoutWidget = (setting, campaign) => {
  if (setting && campaign) {
    const business = getItemBusiness(campaign);
    const {
      pageId,
      pageType,
      widget,
    } = campaign;

    if (isFreshBusiness(business)) {
      if (pageType && pageType === PageTypes.CHECKOUT
        && pageId && pageId === PageIds.BEFORE_YOU_GO_PAGE_ID_FOR_FRESH_NA
        && widget && widget === WidgetTypes.SHOV) {
        // eslint-disable-next-line no-param-reassign
        setting.seeMoreDropdown = 'None';
        // eslint-disable-next-line no-param-reassign
        setting.disableLinks = true;
      }
    }
    if (is3PNABusiness(business) || isWFMBusiness(business) || isWFMBusiness(business)) {
      if (pageType && pageType === PageTypes.BYG
        && pageId && pageId === PageIds.BEFORE_YOU_GO_PAGE_ID_FOR_FRESH_NA
        && widget && widget === WidgetTypes.SHOV) {
        // eslint-disable-next-line no-param-reassign
        setting.seeMoreDropdown = 'None';
        // eslint-disable-next-line no-param-reassign
        setting.disableLinks = true;
      }
    }
  }
};
const getJSONBourneSettings = (campaign) => {
  const {
    customSeeMoreUrl,
    imagePaths,
    widget,
    copy,
    promoType,
    refMarker,
  } = campaign;
  const base = {
    title: copy,
    refTag: refMarker,
    isCouponRequired: promoType === 'VPC',
    ...getTypeSpecificSettings(campaign),
  };
  updateSettingForCheckoutWidget(base, campaign);

  if (customSeeMoreUrl && customSeeMoreUrl.length > 0) {
    base.seeMoreDropdown = 'Custom';
    base.customSeeMoreUrl = customSeeMoreUrl;
    base.seeMoreUrlDisplay = customSeeMoreUrl;
  }

  if (widget && widget === WidgetTypes.ENHSHOV) {
    try {
      const images = JSON.parse(imagePaths);
      if (images && (images.desktop || images.mobile)) {
        return {
          ...base,
          displayVendorImage: true,
          vendorSponsoredImage: {
            desktopSource: images.desktop
              ? getPathStartingFromComposer(images.desktop)
              : '',
            mobileSource: images.mobile
              ? getPathStartingFromComposer(images.mobile)
              : '',
          },
        };
      }
    } catch (e) {
      const metricContext = {
        // imagePath is not a valid JSON object
        location: MetricLocations.JSON_BOURNE_MIDDLEWARE,
        type: MetricTypes.THIRD_PARTY,
        data: {
          campaign,
        },
        action: MetricNames.OPEN_JSONBOURNE_IFRAME,
      };
      submitError(e, metricContext);
    }
  }

  return base;
};

const BASE_JSON_BOURNE_URL = process.env.NODE_ENV === 'production' ? JSONBourneUrls.PROD : JSONBourneUrls.DEV;

export const openJSONBourne = ({ getState, dispatch, action }) => {
  const state = getState();
  const { payload: { id, packageId } } = action;
  const campaign = getCampaign(state, id, packageId);
  if (campaign) {
    const settings = JSON.stringify(getJSONBourneSettings(campaign));
    const encodedSettings = encodeURIComponent(btoa(unescape(encodeURIComponent(settings))));
    let jsonBourneLink = `${BASE_JSON_BOURNE_URL}?formParams=${encodedSettings}&callbackUrl=${JSONBOURNE_REDIRECT_URL}&callbackName=Marketing%20Calendar`;
    if (isEUBusiness(getItemBusiness(campaign))) {
      jsonBourneLink = jsonBourneLink.concat('&marketplace=EU');
    }
    dispatch(onThirdPartyIFrameSetId(id, packageId));
    dispatch(onThirdPartyIFrameSetDestination(jsonBourneLink));
  }
};

export const handleJSONBourneEvent = ({ getState, dispatch, action }) => {
  const state = getState();
  const { ThirdPartyIFrame: { id, packageId } } = state;
  const campaign = getCampaign(state, id, packageId);
  if (campaign) {
    const { widgetParameters, percolateParameters } = action.payload;
    if (widgetParameters) {
      dispatch(onUpdateCampaignColumn(
        campaign,
        CampaignColumns.ENCODED_KEPLER_WIDGET_PARAM.name,
        widgetParameters,
      ));
    }
    if (percolateParameters) {
      dispatch(onUpdateCampaignColumn(
        campaign,
        CampaignColumns.PERCOLATE_PARAMETERS.name,
        percolateParameters,
      ));
    }
    dispatch(onSendCampaignEdit(id, packageId));
    dispatch(onThirdPartyIFrameSetId(null, null));
    dispatch(onThirdPartyIFrameClose());
  }
};
