import {
  RateCardMerchTypes,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';

const Awareness = [
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Circular - Custom Banner',
    dollars: 55000,
    isUff: 0,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'In-store sampling',
    dollars: 15000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Storefront Tile (1-week)',
    dollars: 37500,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Storefront Tile (4-weeks)',
    dollars: 150000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Storefront Tile (2-weeks)',
    dollars: 75000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 20,
    numberPlacements: 1,
  },
];

export default Awareness;
