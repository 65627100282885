import _ from 'lodash';
import { CommentActions } from '../../constants';

export const initialState = {
  list: [],
  isPosting: false,
  isAutoScrollingEnabled: true,
  isPollingAllowed: false,
  isModalOpen: false,
  activeCampaignId: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case CommentActions.SET: {
      const { comments, shouldOverride } = payload;

      if (!comments) {
        return state;
      }

      const newComments = shouldOverride
        ? comments
        : _.uniqBy([...state.list, ...comments], (comment) => {
          return comment.createdAt;
        });

      return {
        ...state,
        list: newComments,
      };
    }
    case CommentActions.SET_POSTING: {
      const { flag } = payload;

      return {
        ...state,
        isPosting: flag,
      };
    }
    case CommentActions.DELETE: {
      const { createdAt } = payload;

      return {
        ...state,
        list: state.list.filter((comment) => comment.createdAt !== createdAt),
      };
    }
    case CommentActions.TOGGLE_AUTO_SCROLLING: {
      return {
        ...state,
        isAutoScrollingEnabled: !state.isAutoScrollingEnabled,
      };
    }
    case CommentActions.START_POLLING: {
      return {
        ...state,
        isPollingAllowed: true,
      };
    }
    case CommentActions.STOP_POLLING: {
      return {
        ...state,
        isPollingAllowed: false,
      };
    }
    case CommentActions.SET_ACTIVE_CAMPAIGN: {
      const { campaignId, packageId } = payload;
      return {
        ...state,
        activeCampaignId: campaignId,
        activePackageId: packageId,
      };
    }
    case CommentActions.RESET: {
      return {
        ...initialState,
      };
    }
    case CommentActions.OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      };
    }
    case CommentActions.CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};
