import { createAddPackagesPromiseChain } from 'src/middleware/GridViewPage/addPackage';
import {
  onBulkImportActionCompleted,
  onBulkImportSetForImport,
  onEditPackage,
  onOpenToast,
} from '../../actionCreators';
import { BulkImportActions } from '../../constants';
import { getPackagesForCurrentBusinessStartYears } from '../../selectors/package';

export default (dispatch, getState) => {
  const state = getState();
  const { BulkImport: { accepted: { create, update } } } = state;
  const currentPackages = getPackagesForCurrentBusinessStartYears(state);
  const isBulkAction = true;
  const expectedPackageCount = create.length + update.length;
  dispatch(onBulkImportSetForImport(expectedPackageCount));
  if (create && create.length > 0) {
    createAddPackagesPromiseChain(create, dispatch, getState, { isBulkAction });
  }
  update.forEach((packageToUpdate) => {
    const getStatePackage = currentPackages[packageToUpdate.id];
    if (!getStatePackage) {
      dispatch(onOpenToast(`Update failed: Package with Ref Marker "${packageToUpdate.refMarker}" not found in the Marketing Calendar.`, 0));
      dispatch(onBulkImportActionCompleted(
        packageToUpdate.refMarker,
        BulkImportActions.ERROR,
      ));
      return;
    }
    dispatch(onEditPackage(packageToUpdate, { isBulkAction }));
  });
};
