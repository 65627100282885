import { SubscriptionActions } from 'src/constants';

export const onUpdatedCampaignSubscriptionReceived = (campaignData) => ({
  type: SubscriptionActions.UPDATED_CAMPAIGN_RECEIVED,
  payload: { campaignData },
});
export const onUpdateCommentsSubscriptionRecieved = (commentData) => ({
  type: SubscriptionActions.UPDATED_COMMENTS_RECIEVED,
  payload: { commentData },
});
