import { connect } from 'react-redux';
import { mapStateToProps as originalMapStateToProps } from '../NotificationsList';
import NotificationsList from '../NotificationsList/NotificationsList';
import { onNotificationsCloseWidget } from '../../actionCreators';
import { getWidgetNotifications } from '../../selectors/notificationsSelectors';

export const mapStateToProps = (state) => {
  return {
    ...originalMapStateToProps(state),
    notificationsList: getWidgetNotifications(state),
    isModal: true,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseNotificationsWidget: () => {
      dispatch(onNotificationsCloseWidget());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
