import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import GeneralInfo from './Sections/GeneralInfo';
import MerchandisingIntent from './Sections/MerchandisingIntent';
import LimitedPlacement from './Sections/LimitedPlacements';
import MainPlacements from './Sections/MainPlacements';
import Reports from './Sections/Reports';
import SubmitButtons from './Sections/SubmitButtons';
import sitewideStyles from '../../styles/sitewide.module.scss';
import styles from './RateCardForm.module.scss';
import { formCampaignColumns } from '../../proptypes';

const RateCardForm = (props) => {
  const {
    onModalClose,
    onCreate,
    onClone,
    onUpdate,
    handleSubmit,
    submitting,
    isNew,
    isClone,
    isLoading,
    isNotFound,
    isVSSM,
    campaignColumns,
    bundlesConfig,
    placementsConfig,
  } = props;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Loader type="circular" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Alert>No data to show</Alert>
        </div>
      </div>
    );
  }

  const processCampaign = (data, { hasFailedSlotsValidation = false } = {}) => {
    if (isNew) {
      onCreate(data, { hasFailedSlotsValidation });
    } else if (isClone) {
      onClone(data);
    } else {
      onUpdate(data);
    }
  };

  const processSubmit = (data) => {
    processCampaign(data);

    if (isNew || isClone) {
      onModalClose();
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(processSubmit)}
    >
      <GeneralInfo campaignColumns={campaignColumns} />
      {!isVSSM && <MerchandisingIntent />}
      {!isVSSM && (
      <LimitedPlacement
        isNew={isNew}
        bundlesConfig={bundlesConfig}
        placementsConfig={placementsConfig}
      />
      )}
      <MainPlacements
        isNew={isNew}
        bundlesConfig={bundlesConfig}
        placementsConfig={placementsConfig}
      />
      <Reports />
      <SubmitButtons
        isNew={isNew}
        isClone={isClone}
        submitting={submitting}
      />
    </form>
  );
};

RateCardForm.propTypes = {
  isNew: PropTypes.bool,
  isClone: PropTypes.bool,
  isVSSM: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...formCampaignColumns,
};

RateCardForm.defaultProps = {
  isNew: false,
  isClone: false,
  isVSSM: false,
};

export default RateCardForm;
