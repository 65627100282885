import { FlashFillActions } from '../../constants';
import processFill from './processFill';
import processFillPackage from './processFillPackage';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === FlashFillActions.FILL_PAGE) {
    return processFill(dispatch, getState, action, next);
  }
  if (type === FlashFillActions.FILL_PACKAGE) {
    return processFillPackage(dispatch, getState, action, next);
  }

  return next(action);
};
