import React from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';

const NonOmnichannelPackageTabs = ({
  tabs,
  currentTab,
  onSwitchTab,
}) => {
  const getTabList = () => {
    return tabs.map(({ name, display }) => {
      return <Tab value={name}>{display}</Tab>;
    });
  };

  return (
    <TabGroup
      value={currentTab}
      onChange={onSwitchTab}
      fill="tabs"
    >
      {getTabList()}
    </TabGroup>
  );
};

export default NonOmnichannelPackageTabs;
