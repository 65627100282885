import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@amzn/meridian/icon';
import caretRightTokens from '@amzn/meridian-tokens/base/icon/caret-right';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import GridViewCell from '../GridViewCell';
import GridViewActionCell from '../GridViewActionCell';
import { campaignColumnProps, campaignProps } from '../../proptypes';
import styles from './GridViewRow.module.scss';
import { getFirstOnlineCampaign } from '../../helpers/campaign';

const GridViewRow = (props) => {
  const {
    item,
    parentPackage,
    columnOrder,
    isCellDisabled,
    getCellValue,
    getComparedItem,
    getErrorText,
    columnsToCompare,
    comparedRow,
    supportedCells,
    campaignsCount,
    isActive,
    isPackageRow,
    isRowExpanded,
    isOmnichannelMode,
    onSetExpandedRowsOverride,
    displayMode,
    stripe,
    showActionBar,
  } = props;

  const getExpandButton = () => {
    if (!isPackageRow) {
      return <td>&nbsp;</td>;
    }

    return (
      <td>
        <button
          type="button"
          onClick={onSetExpandedRowsOverride(item.id, !isRowExpanded)}
          className={styles.expandButton}
        >
          <span>({campaignsCount})</span>
          <Icon tokens={(isRowExpanded ? caretDownTokens : caretRightTokens)} />
        </button>
      </td>
    );
  };

  // eslint-disable-next-line no-shadow
  const getCell = (item, column, isSticky) => {
    const isDisabled = isCellDisabled(item, column.name, supportedCells);
    const cellValue = getCellValue(item, column.name, isPackageRow, parentPackage);
    const comparedItem = getComparedItem(comparedRow, column);

    return (
      <GridViewCell
        key={`${item.id}-${column.name}`}
        item={item}
        columnName={column.name}
        errorText={getErrorText(item, column)}
        isSticky={isSticky}
        showComparison={columnsToCompare.includes(column.name)}
        comparedItem={comparedItem}
        name={column.name}
        isDisabled={isDisabled}
        displayMode={displayMode}
      >
        {cellValue}
      </GridViewCell>
    );
  };

  const getCells = () => {
    return columnOrder.map((column, index) => {
      const isSticky = index === 0;

      if (isOmnichannelMode) {
        return getCell(item, column, isSticky);
      }

      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(column.name)) {
        return getCell(item, column, isSticky);
      }
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty('campaigns')) {
        const firstOnlineCampaign = getFirstOnlineCampaign(item.campaigns);
        const [firstCampaign] = item.campaigns || [];
        const targetCampaign = firstOnlineCampaign || firstCampaign;

        if (targetCampaign) {
          return getCell(targetCampaign, column, isSticky);
        }
      }

      return getCell(item, column, isSticky);
    });
  };

  const getActionBar = () => {
    if (!showActionBar) {
      return null;
    }

    return (
      <GridViewActionCell
        item={item}
        isPackage={isPackageRow}
      />
    );
  };

  const actionBar = getActionBar();
  const cells = getCells();
  const expandButton = getExpandButton();

  return (
    <tr
      className={classnames({
        [styles.row]: true,
        [styles.even]: !!stripe,
        [styles.odd]: !stripe,
        [styles.highlight]: isActive,
      })}
    >
      {actionBar}
      {expandButton}
      {cells}
    </tr>
  );
};

GridViewRow.propTypes = {
  item: campaignProps.isRequired,
  columnOrder: campaignColumnProps,
  stripe: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  isPackageRow: PropTypes.bool,
  showActionBar: PropTypes.bool.isRequired,
  comparedRow: campaignProps,
  columnsToCompare: PropTypes.arrayOf(PropTypes.string),
  supportedCells: PropTypes.array,
  campaignsCount: PropTypes.number,
};

GridViewRow.defaultProps = {
  columnOrder: [],
  columnsToCompare: [],
  isActive: false,
  isPackageRow: false,
  comparedRow: {},
  supportedCells: [],
  campaignsCount: 0,
};

export default GridViewRow;
