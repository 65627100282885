import { connect } from 'react-redux';
import { getActiveCellValue } from 'src/selectors/gridView';
import { defaultDispatchToProps, defaultStateToProps } from '../helpers';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import SingleSelect from '../../StyledInputs/SingleSelect';
import { getMetadataOptionsForInput } from '../../../selectors/metadata';

export const mapStateToProps = (state) => {
  const options = getMetadataOptionsForInput(state);

  const activeCellValue = getActiveCellValue(state);
  // if an input has an overridden value, add it as an option for styling fixes
  if (activeCellValue && !options.includes(activeCellValue)) {
    options.push(activeCellValue);
  }

  return {
    ...defaultStateToProps(state),
    allowOverride: true,
    options,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSelect);
