import { connect } from 'react-redux';
import { getSelectedBusiness } from 'src/selectors/sitewide';
import { isCurrentUserMetadataAdmin } from 'src/selectors/user';
import BusinessSelector from './BusinessSelector';
import { onBusinessSelect } from '../../actionCreators';
import { submitStringMetric } from '../../metrics';
import { MetricLocations, MetricTypes } from '../../constants';

const mapStateToProps = (state) => {
  return {
    selectedBusiness: getSelectedBusiness(state),
    isAdmin: isCurrentUserMetadataAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange(business) {
      submitStringMetric(
        business,
        {
          location: MetricLocations.BUSINESS_SELECTOR,
          type: MetricTypes.CLICK,
        },
      );
      dispatch(onBusinessSelect({
        business,
        forceReload: true,
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSelector);
