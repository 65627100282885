import { DeployLocations, Hosts } from '../constants';

export const getDeployedLocation = () => {
  const { location: { host } } = window;

  switch (host) {
    case Hosts.DEV:
      return DeployLocations.DEV;
    case Hosts.BETA:
      return DeployLocations.BETA;
    case Hosts.PROD:
      return DeployLocations.PROD;
    case Hosts.PROD_READABLE:
      return DeployLocations.PROD;
    default:
      return DeployLocations.DEV;
  }
};
export const isProduction = () => DeployLocations.PROD === getDeployedLocation();
