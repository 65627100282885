import { appsyncToLocal, localToAppsync } from './translateCampaigns';

export const translateAssetFromAppsyncToLocal = (rawAsset, metadata) => {
  const translatedAsset = appsyncToLocal(rawAsset, metadata);
  if (translatedAsset.campaigns && Array.isArray(translatedAsset.campaigns)) {
    translatedAsset.campaigns = translatedAsset.campaigns.map((campaign) => {
      return appsyncToLocal(campaign, metadata);
    });
  }

  return translatedAsset;
};

export const translateAssetFromLocalToAppsync = (asset) => {
  const translatedAsset = localToAppsync(asset);
  if (translatedAsset.campaigns && Array.isArray(translatedAsset.campaigns)) {
    translatedAsset.campaigns = translatedAsset.campaigns.map((campaign) => {
      return localToAppsync(campaign);
    });
  }

  return translatedAsset;
};
