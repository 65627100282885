import { API, graphqlOperation } from 'aws-amplify';
import {
  appsyncToLocal,
  formatCampaignForMutationInput,
  getExcludedFields,
  localToAppsync,
} from '../../helpers/translateCampaigns';
import {
  BulkImportActions,
  CampaignColumns,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
  VirtualFieldsRemovedOnClone,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import {
  onAddCampaign,
  onBulkImportActionCompleted,
  onCreatedCampaignReceived,
  onOpenToast,
} from '../../actionCreators';
import { getUsername } from '../../selectors/user';
import * as mutatations from '../../graphql/mutations';
import { parseGraphQLResponse } from '../../helpers/api';
import { isOnlineCampaign } from '../../helpers/campaign';

const createAddCampaignPromise = (dispatch, getState, action) => {
  const state = getState();
  const { Meta: { metadata } } = state;
  const username = getUsername(state);

  const {
    payload: {
      packageId,
      // eslint-disable-next-line camelcase
      business_startYear,
      campaign,
      options,
    },
  } = action;

  const { isClone, isBulkAction, hasFailedSlotsValidation } = options;

  const campaignToSend = {
    ...localToAppsync(campaign),
    // eslint-disable-next-line camelcase
    [CampaignColumns.BUSINESS_START_YEAR.name]: business_startYear,
    [CampaignColumns.PACKAGE_ID.name]: packageId,
  };

  if (isOnlineCampaign(campaign)) {
    // eslint-disable-next-line no-prototype-builtins
    if (campaignToSend.hasOwnProperty(CampaignColumns.SLOTS_OVERBOOKED_VALIDATION_BYPASSED.name)) {
      campaignToSend[CampaignColumns.SLOTS_OVERBOOKED_VALIDATION_BYPASSED.name] = !!hasFailedSlotsValidation;
    }
    if (isClone) {
      VirtualFieldsRemovedOnClone.forEach((field) => {
        delete campaignToSend[field];
      });
    }
  }

  const baseExcludeFields = [
    'id',
    'version',
  ];

  getExcludedFields(baseExcludeFields)
    .forEach((excludedField) => {
      delete campaignToSend[excludedField];
    });

  const dataToSend = {
    input: {
      campaign: formatCampaignForMutationInput(campaignToSend),
      username,
    },
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: dataToSend,
    action: MetricNames.ADD_CAMPAIGN,
    username,
  };

  submitStringMetric(MetricNames.ADD_CAMPAIGN, metricContext);

  const addCampaign = (params) => {
    return API.graphql(graphqlOperation(mutatations.createCampaign, params));
  };

  const onParseResponse = (response) => {
    const rawPackage = parseGraphQLResponse(response, 'createCampaign');

    return appsyncToLocal(rawPackage, metadata);
  };

  const onAddCampaignToState = (translatedCampaign) => {
    dispatch(onCreatedCampaignReceived(translatedCampaign));

    return translatedCampaign;
  };

  const onError = (error) => {
    submitAppSyncError(error, metricContext);

    if (isBulkAction) {
      dispatch(onBulkImportActionCompleted(
        campaign.refMarker,
        BulkImportActions.ERROR,
      ));
    } else {
      dispatch(onOpenToast(Language.CAMPAIGN_ADD_ERROR));
    }
  };

  return addCampaign(dataToSend)
    .then(onParseResponse)
    .then(onAddCampaignToState)
    .catch(onError);
};

// eslint-disable-next-line camelcase
export const createAddCampaignsPromiseChain = (campaigns, packageId, business_startYear, dispatch, getState, options) => {
  const addCampaignsPromiseChain = campaigns.map((campaign) => () => createAddCampaignPromise(dispatch, getState, onAddCampaign(packageId, business_startYear, campaign, options)));
  return addCampaignsPromiseChain.reduce((prev, cur) => prev.then(cur), Promise.resolve());
};

export default (dispatch, getState, action, next) => {
  createAddCampaignPromise(dispatch, getState, action);
  return next(action);
};
