import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import MainPlacements from './MainPlacements';
import { Form } from '../../../../constants';
import { RateCardColumns, RateCardPlacementColumnNames } from '../../../../constants/rateCard';
import { onRateCardRefreshPlacements } from '../../../../actionCreators';
import RateCardRenderFields from '../../../../helpers/RateCardRenderFields';
import { getMoneyFormatter } from '../../../../helpers/money';
import { getTotalMainPlacementsMoneyUsed } from '../../../../selectors/rateCardSelectors';

const selector = formValueSelector(Form.RATE_CARD_NAME);

export const columns = [
  RateCardPlacementColumnNames.CLASS,
  RateCardPlacementColumnNames.PLACEMENT_NAME,
  {
    ...RateCardPlacementColumnNames.DOLLARS,
    render: RateCardRenderFields.dollarsInputField,
  },
  {
    ...RateCardPlacementColumnNames.PLANNED_PLACEMENTS,
    render: RateCardRenderFields.numberInputField,
  },
  RateCardPlacementColumnNames.NET_PLACEMENTS,
  RateCardPlacementColumnNames.VENDOR_MAX,
  RateCardPlacementColumnNames.UFF_PLACEMENT,
  RateCardPlacementColumnNames.AFS_PLACEMENT,
];

export const mapStateToProps = (state) => ({
  isVSSM: selector(state, RateCardColumns.IS_VSSM.name) || false,
  columns,
  totalMainPlacementsMoneyUsed: getMoneyFormatter().format(getTotalMainPlacementsMoneyUsed(state)),
  defaultRender: RateCardRenderFields.renderTextField,
});

export const mapDispatchToProps = (dispatch) => {
  return {
    onRefreshPlacements(placementType) {
      dispatch(onRateCardRefreshPlacements(placementType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPlacements);
