import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Masthead, { MastheadLink, MastheadTitle } from '@amzn/meridian/masthead';
import { useHistory } from 'react-router-dom';
import Icon from '@amzn/meridian/icon';
import Tag from '@amzn/meridian/tag';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import Announcement from '../Announcement';
import UserPanel from '../UserPanel';
import WebsiteViewSwitcher from '../WebsiteViewSwitcher';
import BusinessSelector from '../BusinessSelector';
import styles from './Header.module.scss';
import { submitStringMetric } from '../../metrics';
import { MetricLocations, MetricTypes } from '../../constants';
import UtilizationMenu from './UtilizationMenu';
import { isUtilizationRoute } from '../../helpers/router';
import StartYearsSelector from '../StartYearsSelector';

const Header = (props) => {
  const {
    route: currentRoute,
    linkList,
    onMenuButtonClick,
    environmentLabel,
  } = props;

  const history = useHistory();

  const onClickLink = useCallback((route) => () => {
    submitStringMetric(
      route,
      {
        location: MetricLocations.HEADER,
        type: MetricTypes.CLICK,
      },
    );
    history.push(route);
  }, [history]);

  const utilizationButtonRef = useRef();
  const [isUtilizationMenuOpen, setUtilizationMenuOpen] = useState(false);
  const onOpenUtilizationMenu = () => setUtilizationMenuOpen(true);
  const onCloseUtilizationMenu = () => setUtilizationMenuOpen(false);

  const getUtilizationMenu = () => (
    <UtilizationMenu
      buttonRef={utilizationButtonRef}
      isOpen={isUtilizationMenuOpen}
      onClose={onCloseUtilizationMenu}
      onClick={onClickLink}
    />
  );

  const getLinks = () => linkList.map((linkContainer) => {
    const { name, route: linkRoute, iconComponent: IconComponent } = linkContainer;

    const hasUtilizationRouteMatch = isUtilizationRoute(linkRoute);
    const onClickHandler = (route) => (
      hasUtilizationRouteMatch
        ? onOpenUtilizationMenu
        : onClickLink(route)
    );

    return (
      <MastheadLink
        key={linkRoute}
        selected={currentRoute === linkRoute}
        onClick={onClickHandler(linkRoute)}
        ref={(hasUtilizationRouteMatch ? utilizationButtonRef : null)}
      >
        <div className={styles.linkContainer}>
          {IconComponent ? <IconComponent /> : null}
          <span className={styles.link}>{name}</span>
        </div>
      </MastheadLink>
    );
  });

  const getTitle = () => {
    const environmentTag = environmentLabel
      ? (
        <sup className={styles.logoText}>
          <Tag>{environmentLabel}</Tag>
        </sup>
      )
      : null;

    return (
      <MastheadTitle href="#">
        <div className={styles.logoContainer}>
          <Button type="icon" onClick={onMenuButtonClick}>
            <Icon tokens={menuTokens}>Menu</Icon>
          </Button>
          <Heading
            className={styles.logoText}
            level={4}
          >
            Marketing Calendar
            {environmentTag}
          </Heading>
        </div>
      </MastheadTitle>
    );
  };

  const getBusinessSelector = () => (
    <MastheadLink>
      <BusinessSelector />
    </MastheadLink>
  );

  const getYearSelector = () => (
    <MastheadLink>
      <StartYearsSelector />
    </MastheadLink>
  );

  const title = getTitle();
  const links = getLinks();
  const businessSelector = getBusinessSelector();
  const yearSelector = getYearSelector();
  const utilizationMenu = getUtilizationMenu();

  return (
    <div className={styles.headerContainer}>
      {utilizationMenu}
      <Announcement />
      <Masthead size="small">
        {title}
        {businessSelector}
        {yearSelector}
        {links}
        <WebsiteViewSwitcher />
        <UserPanel />
      </Masthead>
    </div>
  );
};

Header.propTypes = {
  environmentLabel: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  linkList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
};

export default Header;
