import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { DateTime } from 'luxon';
import { timeRange } from '@amzn/meridian/time-select';
import {
  CAMPAIGN_FORM_DATE_FORMAT,
  DEFAULT_TIMEZONE_ID,
  DateFormats,
} from '../constants';

const parseTimeString = (string) => {
  if (!string) { return null; }
  const parsedTime = string.match(/\d{1,2}:\d{2}/g);
  if (!parsedTime) { return null; }
  // Add a 0 padding for times < 10
  const formattedTime = parsedTime[0].length === 4 ? `0${parsedTime[0]}` : parsedTime[0];
  return formattedTime;
};

export const getDefaultTimezoneFromState = (state) => {
  const defaultForBusinesses = DEFAULT_TIMEZONE_ID;
  if (!state.Meta.metadata.fields) { return defaultForBusinesses; }
  const {
    Meta: { metadata: { fields: { timezoneId: { options: timezoneOptions } } } },
  } = state;
  // Fresh UK has no timezone and defaults to Pacific
  const defaultTimezone = timezoneOptions[0] || defaultForBusinesses;
  return defaultTimezone;
};

export const parseDateAndTimeToISOString = (date, time, timezoneId) => {
  if (!date) {
    return null;
  }
  let dateString = date;
  if (date.includes('/')) {
    dateString = date.split('/').join('-');
  }
  const parsedTime = parseTimeString(time);
  const dateObj = new Date(dateString);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  const monthString = month < 10 ? `0${month}` : month;
  const dayString = day < 10 ? `0${day}` : day;

  const dateTimeString = `${year}-${monthString}-${dayString}T${parsedTime || '00:00'}:00Z`;
  const timezoneOffset = momentTimezone.tz(dateTimeString, timezoneId).utcOffset();
  return momentTimezone.tz(dateTimeString, timezoneId).subtract(timezoneOffset, 'minutes').utc().format();
};

export const parseYearFromDate = (date, timezoneId = DEFAULT_TIMEZONE_ID) => {
  if (!date) {
    return null;
  }
  return momentTimezone.tz(date, timezoneId).year();
};

export const getAllDaysInDateRange = (startDate, endDate, daysInterval = 7) => {
  const currentDate = startDate.clone();

  const daysInRange = [];
  while (currentDate <= endDate) {
    daysInRange.push(currentDate.clone());
    currentDate.add(daysInterval, 'days');
  }

  /*
    Days on the interval (between "start date" and "end date")
    shouldn't inherit any originally time set by a customer. They should be fully included
    in the calculations (from 00:00 to 23:59). Only "start date" and "end date" should
    preserve their originally time set.
   */
  const filteredDaysInRange = [];
  const { length } = daysInRange;
  const lastIndex = length - 1;
  for (let i = 0; i < length; i++) {
    const newDayInRange = daysInRange[i].clone();
    if (i === 0 || i === lastIndex) {
      filteredDaysInRange.push(newDayInRange);
    } else {
      filteredDaysInRange.push(newDayInRange.startOf('day'));
    }
  }

  return filteredDaysInRange;
};

export const safeDateFromNow = (dateTime) => {
  if (!dateTime || typeof dateTime !== 'string') {
    return '';
  }

  return moment.utc(dateTime).fromNow();
};

export const subtractDateTimeUnit = (number, unit, formatPattern) => {
  const dateTime = momentTimezone.tz('America/Los_Angeles').subtract(number, unit);
  if (formatPattern) {
    return dateTime.format(formatPattern);
  }
  return dateTime.format();
};
export const getFormattedDateTime = (dateTime, options) => {
  const { timeZone, pattern } = options;
  const formatPattern = pattern || DateFormats.MM_DD_YY_hh_mma;

  return (
    timeZone
      ? momentTimezone(dateTime).tz(timeZone)
      : momentTimezone(dateTime)
  ).format(formatPattern);
};

const getTodaysDate = (format = CAMPAIGN_FORM_DATE_FORMAT) => moment().format(format);

const isDayOfWeek = (date, day) => moment(date).day() === day;

export const isNotTuesday = (date) => !isDayOfWeek(date, 2);
export const isNotWednesday = (date) => !isDayOfWeek(date, 3);

export const getDateWeeksFromNow = (weeks, format = CAMPAIGN_FORM_DATE_FORMAT) => moment().add(weeks, 'weeks').format(format);
export const getDateWeeksFromDate = (date, weeks, format = CAMPAIGN_FORM_DATE_FORMAT) => moment(date).add(weeks, 'weeks').format(format);

export const getLastDayOfMonth = (date) => {
  const parsedDate = moment(date);
  return parsedDate.clone().endOf('month').format(CAMPAIGN_FORM_DATE_FORMAT);
};
export const getFirstDayOfNextMonth = (date) => {
  const parsedDate = moment(date);
  return parsedDate.clone().add(1, 'month').startOf('month').format(CAMPAIGN_FORM_DATE_FORMAT);
};

const getQuarterDate = (
  quarter,
  year = moment().year(),
  isStartOfTheQuarterDate = true,
) => moment()
  .year(year)
  .quarter(quarter)
  // eslint-disable-next-line no-unexpected-multiline
  [(isStartOfTheQuarterDate ? 'startOf' : 'endOf')]('quarter')
  .format(CAMPAIGN_FORM_DATE_FORMAT);

export const isDateWithinRange = (
  targetDate,
  startDate,
  endDate,
  inclusivity = '[]',
) => moment(targetDate).isBetween(startDate, endDate, 'days', inclusivity);

export const getQuarterStartDate = (
  quarter,
  year = moment().year(),
) => getQuarterDate(quarter, year);

export const getQuarterEndDate = (
  quarter,
  year = moment().year(),
) => getQuarterDate(quarter, year, false);

export const getYearStartDate = (year) => getQuarterStartDate(1, year);

export const isQuarterStartDate = (startDate) => moment(startDate).startOf('quarter').isSame(moment(startDate));

const getNextFourQuarterPresets = () => {
  const presets = [];
  for (let q = 0; q < 4; q++) {
    const date = moment().add(q, 'quarters');
    const year = date.year();
    const quarter = date.quarter();
    presets.push({ label: `${year} Q${quarter}`, value: getQuarterStartDate(quarter, year) });
  }
  return presets;
};
export const getStartDatePickerPresets = () => [
  { label: 'Today', value: getTodaysDate() },
  { label: 'Next week', value: getDateWeeksFromNow(1) },
  ...getNextFourQuarterPresets(),
];

export const getEndDatePickerPresets = () => [{ label: 'Forever', value: '2099-01-02' }];
export const getTrafficEndDatePickerPresets = (startDate) => [
  { label: '2 weeks', value: getDateWeeksFromDate(startDate, 2) },
  { label: '4 weeks', value: getDateWeeksFromDate(startDate, 4) },
  { label: 'End of month', value: getLastDayOfMonth(startDate) },
  { label: 'Next month', value: getFirstDayOfNextMonth(startDate) },
];

export const isValidHalfHourTime = (time) => {
  if (!time) {
    return false;
  }
  const dateTime = DateTime.fromFormat(time, 'HH:mm:ss');
  if (!dateTime.isValid) {
    return false;
  }

  const { hour } = dateTime;
  const { minute } = dateTime;

  return (hour >= 0 && hour <= 23) && (minute === 0 || minute === 30);
};

export const padTimeWithSeconds = (timeString) => {
  if (!timeString) {
    return '';
  }

  const dateTimeWithSeconds = DateTime.fromFormat(timeString, 'HH:mm:ss');
  if (dateTimeWithSeconds.isValid) {
    return timeString;
  }

  const dateTimeWithoutSeconds = DateTime.fromFormat(timeString, 'HH:mm');

  if (dateTimeWithoutSeconds.isValid) {
    return `${dateTimeWithoutSeconds.toFormat('HH')}:${dateTimeWithoutSeconds.toFormat('mm')}:00`;
  }

  return timeString;
};

export const unpadTimeWithSeconds = (timeString) => {
  if (!timeString) {
    return '';
  }

  const dateTimeWithSeconds = DateTime.fromFormat(timeString, 'HH:mm:ss');
  if (dateTimeWithSeconds.isValid) {
    return `${dateTimeWithSeconds.toFormat('HH')}:${dateTimeWithSeconds.toFormat('mm')}`;
  }

  return timeString;
};

export const getClosestTargetDayWithinRange = (
  targetDay,
  startDate,
  endDate,
  startFromEndDate = false,
) => {
  if (!targetDay || !startDate || !endDate || moment(endDate).isBefore(startDate)) {
    return '';
  }
  const targetDate = startFromEndDate ? endDate : startDate;

  let date = moment(targetDate).isoWeekday(targetDay);

  const isDateToCheckWithinRange = (currentDate) => (startFromEndDate
    ? currentDate.isAfter(startDate)
    : currentDate.isBefore(endDate));

  while (isDateToCheckWithinRange(date)) {
    const formattedDate = date.format(CAMPAIGN_FORM_DATE_FORMAT);
    const matchesTargetDay = date.day() === targetDay;
    const isWithinRange = isDateWithinRange(
      formattedDate,
      startDate,
      endDate,
    );
    if (isWithinRange && matchesTargetDay) {
      return formattedDate;
    }
    date = startFromEndDate
      ? date.subtract(1, 'days')
      : date.add(1, 'days');
  }

  return '';
};

export const getTimeOptions = (time) => {
  const timeOptions = timeRange({
    start: '00:00:00',
    end: '23:30:00',
    step: 1800, // seconds
  });

  if (time && !timeOptions.includes(time)) {
    timeOptions.push(time);
  }

  return timeOptions;
};
