import React from 'react';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';
import RateCardList from '../RateCardList';

const RateCardListPage = () => {
  return (
    <RateCardList />
  );
};

export default WithHistoryTiming(RateCardListPage, { pageName: PageNames.RATE_CARD_LIST });
