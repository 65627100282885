import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import styles from '../../RateCardForm.module.scss';
import {
  RATE_CARD_TOTAL_POINTS_THRESHOLDS,
  RateCardTabsList,
} from '../../../../constants/rateCard';
import { Language } from '../../../../constants';

export const getPointsColor = (pointsUsed, pointsTotal) => {
  const result = Math.ceil(((pointsUsed / pointsTotal) * 100));

  if (result >= RATE_CARD_TOTAL_POINTS_THRESHOLDS.OVER_USED) {
    return styles.pointsOverUsed;
  }
  if (result === RATE_CARD_TOTAL_POINTS_THRESHOLDS.ALL_USED) {
    return styles.pointsAllUsed;
  }
  if (result <= RATE_CARD_TOTAL_POINTS_THRESHOLDS.UNDER_USED) {
    return styles.pointsUnderUsed;
  }

  return styles.pointsUnderUsed;
};

const SubmitButtons = (props) => {
  const {
    totalInvestment,
    totalMoneyAvailable,
    showAuditLogButton,
    showAssetsButton,
    showSaveButton,
    submitting,
    onSwitchTab,
    onCreateAssets,
    hasAssets,
    isMoneyLimitExceeded,
    rateCardId,
  } = props;

  const onAuditLogClick = () => onSwitchTab(RateCardTabsList.AUDIT_LOG.name);
  const onAssetsButtonClick = () => {
    if (hasAssets) {
      onSwitchTab(RateCardTabsList.ASSETS.name);
    } else {
      onCreateAssets(rateCardId);
    }
  };

  const moneyLimitExceedWarning = isMoneyLimitExceeded
    ? <Alert type="warning">{Language.RATE_CARD_AVAILABLE_MONEY_LIMIT_EXCEEDED}</Alert>
    : null;

  const pointsBar = (
    <div>
      <div className={styles.pointsBar}>
        <span className={styles.totalPointsText}>Total dollars available:</span>
        <span
          className={classnames(
            styles.pointsNumber,
            getPointsColor(totalMoneyAvailable, totalInvestment),
          )}
        >
          {totalMoneyAvailable}
          {' '}
          of
          {' '}
          {totalInvestment}
        </span>
      </div>
      <div className={styles.moneyLimitExceededWarning}>
        {moneyLimitExceedWarning}
      </div>
    </div>
  );

  const auditLogButton = showAuditLogButton
    ? (
      <Button
        onClick={onAuditLogClick}
        className={styles.floatingPanelButton}
        type="secondary"
      >
        Show Audit Log
      </Button>
    )
    : null;

  const assetsButtonLabel = !hasAssets ? 'Create assets' : 'Manage assets';
  const assetsButton = showAssetsButton ? (
    <Button
      onClick={onAssetsButtonClick}
      className={styles.floatingPanelButton}
      submit
      type="secondary"
    >
      {assetsButtonLabel}
    </Button>
  )
    : null;

  const saveButton = showSaveButton
    ? (
      <Button
        className={styles.floatingPanelButton}
        submit
        type="primary"
        disabled={submitting}
      >
        Save
      </Button>
    )
    : null;

  return (
    <div className={styles.buttonContainer}>
      <div className={styles.submitButtonContainer}>
        {pointsBar}
        <div className={classnames(styles.wrapperInner, styles.floatingPanelButtonsWrapper)}>
          {auditLogButton}
          {assetsButton}
          {saveButton}
        </div>
      </div>
    </div>
  );
};

SubmitButtons.propTypes = {
  submitting: PropTypes.bool,
  totalMoneyAvailable: PropTypes.string,
  totalInvestment: PropTypes.string,
  rateCardId: PropTypes.string,
  showAuditLogButton: PropTypes.bool.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
  showAssetsButton: PropTypes.bool.isRequired,
  showSaveButton: PropTypes.bool.isRequired,
  hasAssets: PropTypes.bool.isRequired,
  isMoneyLimitExceeded: PropTypes.bool.isRequired,
  onCreateAssets: PropTypes.func.isRequired,
};

SubmitButtons.defaultProps = {
  submitting: false,
  totalMoneyAvailable: '',
  totalInvestment: '',
  rateCardId: '',
};

export default SubmitButtons;
