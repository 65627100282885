import { BulletinBoardActions } from 'src/constants';

export const onBulletinBoardLoad = () => ({
  type: BulletinBoardActions.LOAD,
});
export const onBulletinBoardSet = ({ items }) => ({
  type: BulletinBoardActions.SET,
  data: {
    items,
  },
});
export const onBulletinBoardDelete = (id) => ({
  type: BulletinBoardActions.DELETE,
  data: {
    id,
  },
});
export const onBulletinBoardAdd = (data) => ({
  type: BulletinBoardActions.ADD,
  data,
});
export const onBulletinBoardEdit = (data) => ({
  type: BulletinBoardActions.EDIT,
  data,
});
