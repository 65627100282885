import {
  RateCardMediaPackage,
  RateCardPlacementPrices,
} from '../../constants/rateCard';

const BonusMainPlacements = [
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 8,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Tile Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Category Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Online Deal Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 4,
    numberPlacements: 4,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Online Category Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 5,
    uffPlacement: 1,
    afsPlacement: 0,
  },

  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 12,
    numberPlacements: 12,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Tile Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 3,
    numberPlacements: 3,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Category Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 4,
    numberPlacements: 4,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Online Deal Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 5,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Online Category Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 8,
    uffPlacement: 1,
    afsPlacement: 0,
  },

  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 14,
    numberPlacements: 14,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Storefront Tile Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 6,
    numberPlacements: 6,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Consideration',
    type: 'Main',
    placementName: 'Online Category Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 6,
    numberPlacements: 6,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Online Deal Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 7,
    numberPlacements: 7,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Online Category Shoveler Feature',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 10,
    uffPlacement: 1,
    afsPlacement: 0,
  },
];

export default BonusMainPlacements;
