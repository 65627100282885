import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getPackage,
  getPackagesForCurrentBusinessStartYears,
} from './package';
import { getBusinessStartYears } from './sitewide';

export const getCampaignLoadingStatus = (state) => state.GridViewPage.raw.campaignLoadingStatus;

export const getCampaignsForCurrentBusinessStartYears = createSelector(
  [getPackagesForCurrentBusinessStartYears],
  (packages) => {
    return _.reduce(packages, (campaigns, currentPackage) => {
      if (currentPackage.campaigns) {
        campaigns.push(...currentPackage.campaigns);
      }
      return campaigns;
    }, []);
  },
);

export const getCampaign = createSelector(
  [
    (state, id) => id,
    (state, id, packageId) => getPackage(state, packageId),
  ],
  (id, currentPackage) => {
    if (!id
      || !currentPackage
      || !currentPackage.campaigns
      || !currentPackage.campaigns.length) {
      return null;
    }

    return currentPackage.campaigns.find((campaign) => campaign.id === id);
  },
);

export const areCampaignsLoaded = createSelector(
  [
    getCampaignLoadingStatus,
    getBusinessStartYears,
  ],
  (campaignLoadingStatus, businessStartYears) => {
    if (!campaignLoadingStatus) {
      return null;
    }

    return businessStartYears.every((businessStartYear) => !campaignLoadingStatus[businessStartYear]);
  },
);
