import React from 'react';
import PropTypes from 'prop-types';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import caretRightTokens from '@amzn/meridian-tokens/base/icon/caret-right';
import Icon from '@amzn/meridian/icon';
import GridViewHeaderCell from '../GridViewHeaderCell';
import { campaignColumnProps } from '../../proptypes';
import styles from '../GridViewCell/GridViewCell.module.scss';

const GridViewHeader = (props) => {
  const {
    columns,
    showActionBar,
    isOmnichannelMode,
    areRowsExpanded,
    onToggleRowsExpanded,
  } = props;

  if (!columns) { return null; }
  const cells = columns.map((column, index) => {
    return (
      <GridViewHeaderCell key={column.name} isSticky={index === 0}>
        {column.display}
      </GridViewHeaderCell>
    );
  });

  const getRowExpandToggleButton = () => {
    if (!isOmnichannelMode) {
      return null;
    }

    return (
      <button type="button" onClick={onToggleRowsExpanded}>
        <Icon tokens={(areRowsExpanded ? caretDownTokens : caretRightTokens)} />
      </button>
    );
  };

  if (showActionBar) {
    cells.unshift((
      <th key="rowsExpandControl" className={styles.tableHeader}>
        {getRowExpandToggleButton()}
      </th>
    ));
  }
  cells.unshift(<th key="emptyColumn" className={styles.tableHeader} />);

  return (
    <tr>
      {cells}
    </tr>
  );
};

GridViewHeader.propTypes = {
  columns: campaignColumnProps.isRequired,
  showActionBar: PropTypes.bool.isRequired,
};

export default GridViewHeader;
