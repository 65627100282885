import {
  RateCardMediaPackage,
  RateCardPlacementPrices,
} from '../../../../../constants/rateCard';

const Awareness = [
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Category Shoveler (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 8,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Category Shoveler (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 10,
  },
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Awareness',
    type: 'Main',
    placementName: 'Category Shoveler (1-quarter)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 5,
  },
];

export default Awareness;
