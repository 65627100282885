import { getPaginatedPackages } from 'src/selectors/gridView';
import updateUserSettings from 'src/middleware/UserSettings/updateUserSettings';
import getUserSettings from 'src/middleware/UserSettings/getUserSettings';
import {
  ALL_PAGES,
  COPY,
  CSV,
  CURRENT_PAGE,
  CampaignColumns,
  CloudSettingsDataType,
  Delimiters,
  ExportFilters,
  GridViewActions,
  LanguageTypes,
  MetricLocations,
  MetricNames,
  MetricTypes,
  TOGGLE_SIDE_MENU,
} from '../../constants';
import { getSortedPackages } from '../../selectors/campaignFiltersSelectors';
import {
  onGetExportSettings,
  onOpenToast, onSetExportSettings,
} from '../../actionCreators';
import getNewExportOptions from './getNewExportOptions';
import { submitAppSyncError } from '../../metrics';
import { getCurrentBusinessStartYear, isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';
import {
  getAllOnlineCampaigns,
  getCampaignColumnNames,
  getFirstOnlineCampaign,
} from '../../helpers/campaign';
import { getPackageColumnNames } from '../../helpers/package';

const formatString = (string) => {
  // In the CSV spec, a double quote character is escaped by a second double quote character
  const stringToParse = `${string}` || '';
  return stringToParse.replace(/"/g, '""');
};

const formatValue = (value) => {
  // eslint-disable-next-line no-param-reassign
  if (Array.isArray(value)) { value = value.join(Delimiters.COMMA); }
  if (value !== null && value !== undefined) {
    return `"${formatString(value)}"`;
  }
  return '';
};

const extractColumnValue = (campaign, column) => {
  if (campaign[column.name]) {
    return campaign[column.name];
  }
  if (campaign.csCampaignIds && campaign.csCampaignIds[column.name]) {
    return campaign.csCampaignIds[column.name];
  }
  return '';
};

const extractOmnichannelPackageString = (packages, columnOrder, delimiter) => {
  const exportColumnOrder = [...columnOrder];
  exportColumnOrder.unshift(CampaignColumns.PACKAGE_ID, CampaignColumns.ID, CampaignColumns.IS_PACKAGE);

  const columnString = exportColumnOrder.map((column) => {
    return `"${formatString(column.display)}"`;
  }).join(delimiter);

  const itemString = packages.map((pkg) => {
    // eslint-disable-next-line no-param-reassign
    pkg.isPackage = true;
    // eslint-disable-next-line no-param-reassign
    pkg.packageId = pkg.id; // setting packageId so that CampaignColumns.PACKAGE_ID can correctly export the value by attribute name
    const packageString = exportColumnOrder.map((column) => {
      const value = pkg[column.name];
      return formatValue(value);
    }).join(delimiter);

    const campaigns = pkg.campaigns || [];
    const campaignString = campaigns.map((campaign) => {
      return exportColumnOrder.map((column) => {
        return formatValue(extractColumnValue(campaign, column));
      }).join(delimiter);
    }).join(Delimiters.NEWLINE);

    return campaignString ? `${packageString}${Delimiters.NEWLINE}${campaignString}` : `${packageString}`;
  }).join(Delimiters.NEWLINE);

  return `${columnString}${Delimiters.NEWLINE}${itemString}`;
};

const extractDefaultPackageString = (packages, columnOrder, delimiter) => {
  const exportColumnOrder = [...columnOrder];
  exportColumnOrder.unshift(CampaignColumns.PACKAGE_ID, CampaignColumns.ID);
  const packageColumns = getPackageColumnNames();

  const columnString = exportColumnOrder.map((column) => {
    return `"${formatString(column.display)}"`;
  }).join(delimiter);

  const itemString = packages.map((pkg) => {
    // eslint-disable-next-line no-param-reassign
    pkg.packageId = pkg.id; // setting packageId so that CampaignColumns.PACKAGE_ID can correctly export the value by attribute name
    const firstOnlineCampaign = getFirstOnlineCampaign(pkg.campaigns);
    const [firstCampaign] = pkg.campaigns || [];
    const targetCampaign = firstOnlineCampaign || firstCampaign;

    return exportColumnOrder.map((column) => {
      if (packageColumns.includes(column.name)) {
        return formatValue(pkg[column.name]);
      }
      if (targetCampaign) {
        return formatValue(extractColumnValue(targetCampaign, column));
      }
      // TODO: isn't it supposed to return something in a default case?
      // Putting a placeholder here for now but @rushisp will need to check it

      return '';
    }).join(delimiter);
  }).join(Delimiters.NEWLINE);
  return `${columnString}${Delimiters.NEWLINE}${itemString}`;
};

const extractOnlineEnglishCampaignString = (packages, columnOrder, delimiter) => {
  const packageColumns = getPackageColumnNames();
  const campaignColumns = getCampaignColumnNames();

  const columnString = columnOrder.map((column) => {
    return `"${formatString(column.display)}"`;
  }).join(delimiter);

  let itemString = '';
  packages.forEach((pkg) => {
    const onlineCampaigns = getAllOnlineCampaigns(pkg.campaigns) || [];
    const englishOnlineCampaigns = onlineCampaigns.filter((campaign) => {
      return campaign[CampaignColumns.LANGUAGE.name] === LanguageTypes.ENGLISH;
    });
    if (englishOnlineCampaigns.length) {
      const campaignString = englishOnlineCampaigns.map((campaign) => {
        return columnOrder.map((column) => {
          if (!campaignColumns.includes(column.name) && packageColumns.includes(column.name)) {
            return formatValue(pkg[column.name]);
          }
          return formatValue(extractColumnValue(campaign, column));
        }).join(delimiter);
      }).join(Delimiters.NEWLINE);
      if (campaignString) {
        itemString += campaignString + Delimiters.NEWLINE;
      }
    }
  });
  return `${columnString}${Delimiters.NEWLINE}${itemString}`;
};

const getPackagesToExport = (data, state) => {
  switch (data.pageSelection) {
    // Values in ../constants ExportOptions.PageSelections
    case CURRENT_PAGE: {
      return getPaginatedPackages(state);
    }
    case ALL_PAGES: {
      return getSortedPackages(state);
    }
    default: {
      // TODO: log an error for invalid type?
      return getSortedPackages(state);
    }
  }
};

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type, payload } = action;
  const location = MetricLocations.SIDE_MENU;
  const dataType = CloudSettingsDataType.EXPORT_VIEW_CFG;
  if (type === GridViewActions.UPLOAD_EXPORT_SETTINGS) {
    const newExportOptions = getNewExportOptions(getState, payload);
    dispatch(onSetExportSettings(newExportOptions));
    return updateUserSettings(dispatch, getState, action, next, {
      metricLocation: location,
      dataType,
      data: JSON.stringify(newExportOptions),
    });
  }
  if (type === GridViewActions.GET_EXPORT_SETTINGS) {
    const {
      User: { currentUser: { username } },
    } = getState();
    return getUserSettings(dispatch, getState, action, next, {
      metricLocation: location,
      dataType,
      successFunction: (response) => {
        if (response) {
          try {
            const newExportOptions = getNewExportOptions(getState, JSON.parse(response.data));
            dispatch(onSetExportSettings(newExportOptions));
          } catch (error) {
            const metricContext = {
              location,
              type: MetricTypes.APPSYNC,
              data: action.payload,
              action: MetricNames.GET_USER_SETTING,
              username,
            };
            submitAppSyncError(error, metricContext);
          }
        }
      },
    });
  }
  if (type === TOGGLE_SIDE_MENU) {
    const { Sitewide: { isSideMenuOpen } } = getState();
    if (!isSideMenuOpen) {
      dispatch(onGetExportSettings());
    }
  }
  if (type === GridViewActions.EXPORT_CURRENT_VIEW) {
    const packagesToExport = getPackagesToExport(payload, getState());
    const { Meta: { metadata: { columnOrder } } } = getState();
    const currentBusinessStartYear = getCurrentBusinessStartYear(getState());

    const omnichannelMode = isWebsiteOmnichannelViewMode(getState());
    if (payload.exportType === COPY) {
      let packageString;
      if (payload.exportFilters === ExportFilters.ALL_ROWS) {
        packageString = omnichannelMode
          ? extractOmnichannelPackageString(packagesToExport, columnOrder, Delimiters.TAB)
          : extractDefaultPackageString(packagesToExport, columnOrder, Delimiters.TAB);
      } else if (payload.exportFilters === ExportFilters.ONLINE_ENGLISH_CAMPAIGN_ROWS) {
        packageString = extractOnlineEnglishCampaignString(packagesToExport, columnOrder, Delimiters.TAB);
      }
      navigator.clipboard.writeText(packageString).then(() => {
        dispatch(onOpenToast('View copied to clipboard!'));
      }).catch(() => {
        dispatch(onOpenToast('Unable to copy to clipboard'));
      });
    }
    if (payload.exportType === CSV) {
      let packageString;
      if (payload.exportFilters === ExportFilters.ALL_ROWS) {
        packageString = omnichannelMode
          ? extractOmnichannelPackageString(packagesToExport, columnOrder, Delimiters.COMMA)
          : extractDefaultPackageString(packagesToExport, columnOrder, Delimiters.COMMA);
      } else if (payload.exportFilters === ExportFilters.ONLINE_ENGLISH_CAMPAIGN_ROWS) {
        packageString = extractOnlineEnglishCampaignString(packagesToExport, columnOrder, Delimiters.COMMA);
      }
      const link = document.createElement('a');
      // Prepending a byte order mask sequence at the beginning of the content,
      // so that browsers detect it as UTF-8 (Safari, Chrome).
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      // Setting content type to UTF-8 explicitly (Opera, FF)
      const blob = new Blob([bom, packageString], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' });
      const url = window.URL.createObjectURL(blob);
      const fileName = `exported_${currentBusinessStartYear}.csv`;
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return next(action);
};
