import { DashboardActions } from '../../constants';

export const initialState = {
  isLoading: false,
  isOverlapMode: false,
  dateRange: [],
  startTime: '00:00',
  endTime: '00:00',
  startDateTime: null,
  endDateTime: null,
  totalStats: {
    totalCampaigns: 0,
    statsBy: {},
  },
  myStats: {
    totalCampaigns: 0,
    statsBy: {},
  },
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const { type, data } = action;

  switch (type) {
    case DashboardActions.TOGGLE_MODE: {
      return {
        ...state,
        isOverlapMode: !state.isOverlapMode,
      };
    }
    case DashboardActions.RUN_CALC: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DashboardActions.SET_DATA: {
      return {
        ...state,
        isLoading: false,
        ...data.stats,
      };
    }
    case DashboardActions.SET_DATE_RANGE: {
      const { dateRange } = data;
      return {
        ...state,
        dateRange,
      };
    }
    case DashboardActions.SET_START_TIME: {
      const { startTime } = data;
      return {
        ...state,
        startTime,
      };
    }
    case DashboardActions.SET_END_TIME: {
      const { endTime } = data;
      return {
        ...state,
        endTime,
      };
    }
    default: {
      return state;
    }
  }
};
