import React, { useRef, useState } from 'react';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Button from '@amzn/meridian/button';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import { getPlacementsByType } from '../../../helpers/rateCard';
import { CampaignType } from '../../../constants/campaign';

const BundleInfo = (props) => {
  const {
    bundleName,
    bundleConfig,
    placementsConfig,
  } = props;
  const buttonRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const onClick = () => setOpen(!isOpen);

  const getFormattedPlacementNames = (placementNames) => {
    const placementList = placementNames && placementNames.length
      ? placementNames.map((placementName) => {
        return <Text type="b300">{placementName}</Text>;
      })
      : <Text type="b300">No placements</Text>;

    return (
      <Box type="outline" spacingInset="400">
        {placementList}
      </Box>
    );
  };

  const getPlacementsInfo = () => {
    if (!bundleName) {
      return 'No package/bundle name defined';
    }

    const { placements } = bundleConfig;
    if (!placements) {
      return 'No placements are set up for this package/bundle';
    }

    const placementsByType = getPlacementsByType(placements, placementsConfig);
    const {
      [CampaignType.ONLINE]: onlinePlacements,
      [CampaignType.IN_STORE]: inStorePlacements,
    } = placementsByType;

    const totalPlacementsCount = onlinePlacements.length + inStorePlacements.length;
    if (!totalPlacementsCount) {
      return 'No placements are assigned to this package/bundle';
    }
    const formattedOnlinePlacements = getFormattedPlacementNames(onlinePlacements);
    const formattedInStorePlacements = getFormattedPlacementNames(inStorePlacements);

    return (
      <div>
        <p>Placements in this package/bundle: {totalPlacementsCount}</p>
        <p>
          Online ({onlinePlacements.length}*):
          {formattedOnlinePlacements}
          <br />
          In-Store ({inStorePlacements.length}):
          {formattedInStorePlacements}
        </p>
        <p>* - Since Spanish and English campaigns will be created simultaneously,
          <br />
          this number will be multiplied by 2.
        </p>
      </div>
    );
  };

  return (
    <>
      <Button
        onClick={onClick}
        ref={buttonRef}
        aria-haspopup="dialog"
        aria-expanded="false"
      >
        {bundleName}
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={isOpen}
        onClose={onClick}
        position="bottom"
      >
        <PopoverHeader closeLabel="Close">{bundleName}</PopoverHeader>
        {getPlacementsInfo()}
      </Popover>
    </>
  );
};

export default BundleInfo;
