import { createOrUpdateCsCampaign as createOrUpdateCSCampaign } from 'src/graphql/mutations';
import { validateCsCampaignInput as validateCSCampaignInput } from 'src/graphql/queries';
import {
  onCreateCsCampaignCalcDiff,
  onCreateCsCampaignClearValidationErrors,
  onCreateCsCampaignCloseModal,
  onCreateCsCampaignSetRunningStatus,
  onCreateCsCampaignSetValidationErrors,
  onOpenToast,
  onSendCampaignEdit,
  onUpdateCampaignColumn,
} from 'src/actionCreators';
import onGraphQLAction from 'src/actionCreators/graphql';
import { submitAppSyncError, submitStringMetric } from 'src/metrics';
import {
  CampaignColumns,
  GraphQLLabels,
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from 'src/constants';

export default (state, dispatch) => {
  const {
    User: { currentUser: { username } },
    ContentSymphony: {
      campaign,
      creativeData,
      placementData,
      contentData,
      csCampaignIdsData,
      shouldValidateCsCampaignInput,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;
  const { id, packageId } = campaign;

  const creativeInput = creativeData;

  const getMetricsContext = (input = {}) => {
    return {
      location: MetricLocations.CREATE_SYMPHONY_CAMPAIGN,
      type: MetricTypes.APPSYNC,
      data: input,
      action: MetricNames.CREATE_SYMPHONY_CAMPAIGN,
      username,
    };
  };

  const getInputQuery = (isValidationQuery = false) => {
    return {
      input: {
        creative: creativeInput,
        placement: placementData,
        content: contentData,
        csCampaignIds: !isValidationQuery ? csCampaignIdsData : {},
        business: selectedBusiness,
      },
    };
  };
  const getValidationInputQuery = () => getInputQuery(true);

  const dispatchCreateOrUpdateCSCampaign = () => {
    const input = getInputQuery();
    submitStringMetric(MetricNames.CREATE_SYMPHONY_CAMPAIGN, getMetricsContext(input));
    dispatch(onGraphQLAction({
      graphqlAction: createOrUpdateCSCampaign,
      data: input,
      label: GraphQLLabels.CREATE_OR_UPDATE_CS_CAMPAIGN,
      onSuccessFunction(response) {
        // eslint-disable-next-line no-shadow
        const { data: { createOrUpdateCSCampaign } } = response;
        const {
          updatedCampaign: {
            creative: {
              creativeUrl,
            },
          },
          existingCampaign,
          csCampaignIds,
        } = createOrUpdateCSCampaign;
        const successMessage = existingCampaign
          ? Language.CREATIVE_UPDATE_SUCCESS
          : Language.CREATIVE_CREATION_SUCCESS;

        dispatch(onUpdateCampaignColumn(
          campaign,
          CampaignColumns.SYMPHONY_URL.name,
          creativeUrl,
        ));

        dispatch(onUpdateCampaignColumn(
          campaign,
          CampaignColumns.CS_CAMPAIGN_IDS.name,
          csCampaignIds,
        ));

        dispatch(onSendCampaignEdit(id, packageId));
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(successMessage));
        dispatch(onCreateCsCampaignClearValidationErrors());
        dispatch(onCreateCsCampaignCloseModal());
        dispatch(onCreateCsCampaignCalcDiff(createOrUpdateCSCampaign));
      },
      onFailureFunction(error) {
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(Language.CREATIVE_CREATION_ERROR));
        submitAppSyncError(error, getMetricsContext(input));
      },
    }));
  };

  dispatch(onCreateCsCampaignSetRunningStatus(true));
  if (shouldValidateCsCampaignInput) {
    const input = getValidationInputQuery();

    submitStringMetric(
      MetricNames.CREATE_SYMPHONY_CAMPAIGN,
      getMetricsContext(input),
    );

    dispatch(onGraphQLAction({
      graphqlAction: validateCSCampaignInput,
      data: input,
      label: GraphQLLabels.VALIDATE_CS_CAMPAIGN_INPUT,
      onSuccessFunction(data) {
        const validateCSCampaignInputResult = data.data.validateCSCampaignInput;
        const creativeErrorMessage = validateCSCampaignInputResult.creative.errorMessage;
        const desktopPlacementErrorMessage = validateCSCampaignInputResult.desktopPlacement
          ? validateCSCampaignInputResult.desktopPlacement.errorMessage : null;
        const mobilePlacementErrorMessage = validateCSCampaignInputResult.mobilePlacement
          ? validateCSCampaignInputResult.mobilePlacement.errorMessage : null;
        const contentErrorMessage = validateCSCampaignInputResult.content
          ? validateCSCampaignInputResult.content.errorMessage : null;
        if (creativeErrorMessage == null
            && desktopPlacementErrorMessage == null
            && mobilePlacementErrorMessage == null
            && contentErrorMessage == null) {
          dispatchCreateOrUpdateCSCampaign();
        } else {
          dispatch(onCreateCsCampaignSetValidationErrors({
            creativeErrorMessage,
            desktopPlacementErrorMessage,
            mobilePlacementErrorMessage,
            contentErrorMessage,
          }));
          dispatch(onCreateCsCampaignSetRunningStatus(false));
        }
      },
      onFailureFunction(error) {
        dispatch(onCreateCsCampaignSetRunningStatus(false));
        dispatch(onOpenToast(Language.CREATIVE_CREATION_ERROR));
        submitAppSyncError(error, getMetricsContext(input));
      },
    }));
  } else {
    dispatchCreateOrUpdateCSCampaign();
  }
};
