import {
  BulkImportActions,
  BulkImportValidationModes,
} from '../../constants';

export const initialState = {
  accepted: {
    create: [],
    update: [],
  },
  rejected: [],
  formatIssues: 0,
  mismatchedColumnNames: {},
  isProcessed: false,
  isImporting: false,
  isFileParsing: false,
  expectedPackageCount: 0,
  succeededPackageRefMarkers: [],
  failedPackageRefMarkers: [],
  validationMode: BulkImportValidationModes.ALL.name,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case BulkImportActions.SET_PENDING_PACKAGES: {
      return {
        ...state,
        ...action.payload.processedCampaigns,
        isProcessed: true,
      };
    }
    case BulkImportActions.CLEAR_PACKAGES: {
      return {
        ...state,
        accepted: {
          create: [],
          update: [],
        },
        rejected: [],
        formatIssues: 0,
        mismatchedColumnNames: {},
        isProcessed: false,
        succeededPackageRefMarkers: [],
        failedPackageRefMarkers: [],
      };
    }
    case BulkImportActions.SET_FOR_IMPORT: {
      return {
        ...state,
        expectedPackageCount: action.payload.expectedPackageCount,
        succeededPackageRefMarkers: [],
        failedPackageRefMarkers: [],
      };
    }
    case BulkImportActions.PACKAGE_SUCCEEDED: {
      return {
        ...state,
        succeededPackageRefMarkers: [...state.succeededPackageRefMarkers, action.payload.refMarker],
      };
    }
    case BulkImportActions.PACKAGE_FAILED: {
      return {
        ...state,
        failedPackageRefMarkers: [...state.failedPackageRefMarkers, action.payload.refMarker],
      };
    }
    case BulkImportActions.START_IMPORTING: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case BulkImportActions.COMPLETE_IMPORTING: {
      return {
        ...state,
        isImporting: false,
      };
    }
    case BulkImportActions.START_FILE_PARSING: {
      return {
        ...state,
        isFileParsing: true,
      };
    }
    case BulkImportActions.COMPLETE_FILE_PARSING: {
      return {
        ...state,
        isFileParsing: false,
      };
    }
    case BulkImportActions.SET_VALIDATION_MODE: {
      const { payload: { validationMode } } = action;
      return {
        ...state,
        validationMode,
      };
    }
    default: {
      return state;
    }
  }
};
