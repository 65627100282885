import { BulkImportActions, BulkImportWorkMode } from 'src/constants';

export const onBulkImportProcessFile = (params) => {
  const {
    data,
    fileType,
    workMode = BulkImportWorkMode.STANDARD,
  } = params;

  return {
    type: BulkImportActions.PROCESS_FILE,
    payload: { data, fileType, workMode },
  };
};
export const onBulkImportSetPendingPackages = (processedCampaigns) => ({
  type: BulkImportActions.SET_PENDING_PACKAGES,
  payload: {
    processedCampaigns,
  },
});
export const onBulkImportSetValidationMode = (validationMode) => ({
  type: BulkImportActions.SET_VALIDATION_MODE,
  payload: {
    validationMode,
  },
});
export const onBulkImportClearPendingPackages = () => ({
  type: BulkImportActions.CLEAR_PACKAGES,
});
export const onBulkImportPerformImport = () => ({
  type: BulkImportActions.PERFORM_IMPORT,
});
export const onBulkImportActionCompleted = (refMarker, responseType) => ({
  type: BulkImportActions.ACTION_COMPLETED,
  payload: {
    refMarker,
    responseType,
  },
});
export const onBulkImportSetForImport = (expectedPackageCount) => ({
  type: BulkImportActions.SET_FOR_IMPORT,
  payload: {
    expectedPackageCount,
  },
});
export const onBulkImportPackageSucceeded = (refMarker) => ({
  type: BulkImportActions.PACKAGE_SUCCEEDED,
  payload: {
    refMarker,
  },
});
export const onBulkImportPackageFailed = (refMarker) => ({
  type: BulkImportActions.PACKAGE_FAILED,
  payload: {
    refMarker,
  },
});
export const onBulkImportStartImporting = () => ({
  type: BulkImportActions.START_IMPORTING,
});
export const onBulkImportCompleteImporting = () => ({
  type: BulkImportActions.COMPLETE_IMPORTING,
});
export const onBulkImportPerformAssetsImport = (rateCardId) => ({
  type: BulkImportActions.PERFORM_ASSETS_IMPORT,
  payload: {
    rateCardId,
  },
});
export const onBulkImportStartFileParsing = () => ({
  type: BulkImportActions.START_FILE_PARSING,
});
export const onBulkImportCompleteFileParsing = () => ({
  type: BulkImportActions.COMPLETE_FILE_PARSING,
});
