import { BusinessTypes } from '../constants';
import FRESH_REQUIRED_FIELDS from './Fresh';
import WFM_REQUIRED_FIELDS from './WFM';
import WFMC_NA_REQUIRED_FIELDS from './WFMC_NA';
import FRESH_SG_REQUIRED_FIELDS from './Fresh_SG';
import FRESH_UK_REQUIRED_FIELDS from './Fresh_UK';
import FRESH_DE_REQUIRED_FIELDS from './Fresh_DE';
import NA_3P_REQUIRED_FIELDS from './3P_NA';
import DE_3P_REQUIRED_FIELDS from './3P_DE';
import DEFAULT_REQUIRED_FIELDS from './default';
import FRESH_US_TRAFFIC_REQUIRED_FIELDS from './Fresh_US_Traffic';
import FRESH_INT_TRAFFIC_REQUIRED_FIELDS from './Fresh_INT_Traffic';

const REQUIRED_FIELDS_BY_BUSINESS = Object.freeze({
  [BusinessTypes.FRESH.name]: FRESH_REQUIRED_FIELDS,
  [BusinessTypes.WFM.name]: WFM_REQUIRED_FIELDS,
  [BusinessTypes.WFMC_NA.name]: WFMC_NA_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_SG.name]: FRESH_SG_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_UK.name]: FRESH_UK_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_DE.name]: FRESH_DE_REQUIRED_FIELDS,
  [BusinessTypes.NA_3P.name]: NA_3P_REQUIRED_FIELDS,
  [BusinessTypes.DE_3P.name]: DE_3P_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_ES.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_IT.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.FR_3P.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.UK_3P.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.IT_3P.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.ES_3P.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_JP.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.JP_3P.name]: DEFAULT_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_TRAFFIC.name]: FRESH_US_TRAFFIC_REQUIRED_FIELDS,
  [BusinessTypes.FRESH_INT_TRAFFIC.name]: FRESH_INT_TRAFFIC_REQUIRED_FIELDS,
});

export default REQUIRED_FIELDS_BY_BUSINESS;
