import { CampaignColumns } from './index';

export const CampaignType = Object.freeze({
  ONLINE: 'ONLINE',
  IN_STORE: 'IN_STORE',
});

export const BooleanCampaignFields = Object.freeze([
  CampaignColumns.IS_ANIMATED_CONTENT.name,
  CampaignColumns.IS_CBR_APPROVED.name,
  CampaignColumns.IS_DEAL.name,
  CampaignColumns.IS_LOCKED.name,
  CampaignColumns.SLOTS_OVERBOOKED_VALIDATION_BYPASSED.name,
  CampaignColumns.NEED_INVENTORY_AWARENESS.name,
  CampaignColumns.USE_NEW_TEMPLATE.name,
]);

export const NumericCampaignFields = Object.freeze([
  CampaignColumns.SOV.name,
]);

export const NonStringCampaignFields = Object.freeze([
  ...BooleanCampaignFields,
  ...NumericCampaignFields,
]);
