import React from 'react';
import GridViewRow from '../GridViewRow';
import { getPackageColumnNames } from '../../helpers/package';
import { getCampaignColumnNames } from '../../helpers/campaign';

const GridViewPackageRow = (props) => {
  const {
    index,
    currentPackage,
    columnOrder,
    showActionBar,
    isOmnichannelMode,
    isRowExpanded,
    displayMode,
  } = props;

  const getKey = (campaign) => campaign.id || campaign.tempId;

  const getOmnichannelRows = () => {
    const rows = [];
    const packageChanges = (currentPackage.changes && currentPackage.changes.length) ? currentPackage.changes : [];
    rows.push((
      <GridViewRow
        key={getKey(currentPackage)}
        item={currentPackage}
        columnOrder={columnOrder}
        stripe={index % 2 === 0}
        showActionBar={showActionBar}
        columnsToCompare={packageChanges}
        isPackageRow
        supportedCells={getPackageColumnNames()}
        displayMode={displayMode}
      />
    ));

    if (isRowExpanded && currentPackage.campaigns) {
      currentPackage.campaigns.forEach((campaign, rowIndex) => {
        rows.push((
          <GridViewRow
            key={getKey(campaign)}
            item={campaign}
            parentPackage={currentPackage}
            columnOrder={columnOrder}
            stripe={rowIndex % 2 === 0}
            showActionBar={showActionBar}
            columnsToCompare={packageChanges}
            supportedCells={getCampaignColumnNames()}
            displayMode={displayMode}
          />
        ));
      });
    }

    return rows;
  };

  const getNonOmnichannelRows = () => {
    const packageChanges = (currentPackage.changes && currentPackage.changes.length) ? currentPackage.changes : [];
    return (
      <GridViewRow
        key={getKey(currentPackage)}
        item={currentPackage}
        columnOrder={columnOrder}
        stripe={index % 2 === 0}
        showActionBar={showActionBar}
        columnsToCompare={packageChanges}
        displayMode={displayMode}
      />
    );
  };

  return isOmnichannelMode ? getOmnichannelRows() : getNonOmnichannelRows();
};

export default GridViewPackageRow;
