import { SELECT_BUSINESS } from '../../constants';
import { setSelectedBusiness } from '../../helpers/businessSelector';
import {
  onClearMetadata,
  onFetchMetadataAction,
  onUtilizationBusinessChanged,
  onUtilizationSwitchViewMode,
} from '../../actionCreators';
import { onSwitchWebsiteViewMode } from '../../actionCreators/sitewide';
import { UtilizationViewMode } from '../../constants/utilization';
import {
  isGenericFreshBusiness,
  isOmnichannelModeEnabled,
} from '../../helpers/common';
import { WebsiteViewMode } from '../../constants/sitewide';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;
  if (type === SELECT_BUSINESS) {
    const { data: { business } } = action;
    dispatch(onClearMetadata());
    setSelectedBusiness(dispatch, getState, action, next, business);
    dispatch(onFetchMetadataAction(business));
    dispatch(onUtilizationBusinessChanged(business));

    const viewMode = isOmnichannelModeEnabled(business)
      ? WebsiteViewMode.OMNICHANNEL.name
      : WebsiteViewMode.NON_OMNICHANNEL.name;
    dispatch(onSwitchWebsiteViewMode(viewMode));

    const utilizationViewMode = isGenericFreshBusiness(business)
      ? UtilizationViewMode.CALENDAR.name
      : UtilizationViewMode.TABLE.name;
    dispatch(onUtilizationSwitchViewMode(utilizationViewMode));
  }

  return next(action);
};
