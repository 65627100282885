import { MetadataAuditLogActions } from '../../constants';

export const initialState = {
  parsedLogs: {},
  changes: [],
  filterString: '',
};

export const getPathAndChanges = (diff, path = []) => {
  const key = Object.keys(diff)[0];
  if (!key) { return { path: [], changes: [{ add: [], remove: [] }] }; }
  if (key === '_changes') {
    return { path, changes: diff[key] };
  }
  path.push(key);
  return getPathAndChanges(diff[key], path);
};

export const parseChanges = (event) => {
  const {
    metadataDiff,
    version,
    username,
    updatedAt,
  } = event;
  const { path, changes } = getPathAndChanges(metadataDiff);

  return {
    path,
    changes,
    version,
    username,
    updatedAt,
  };
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case MetadataAuditLogActions.ADD_LOG: {
      const { payload: { version } } = action;
      if (state.parsedLogs[version]) { return state; }
      return {
        ...state,
        parsedLogs: { ...state.parsedLogs, [action.payload.version]: true },
        changes: [...state.changes, parseChanges(action.payload)],
      };
    }
    case MetadataAuditLogActions.CLEAR_DATA: {
      return initialState;
    }
    case MetadataAuditLogActions.SET_FILTER_STRING: {
      return {
        ...state,
        filterString: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
