import { connect } from 'react-redux';

import { onUtilizationOpenFiltersModal } from '../../../../actionCreators';

import ActiveUtilityFilters from './ActiveUtilityFilters';
import { getDependentOptionsForUtilization } from '../../../../helpers/getMetadataOptions';

const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      selectedFilters,
      selectedTimezone,
    },
    Meta: {
      metadata,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;
  const filterOptions = getDependentOptionsForUtilization(metadata, selectedBusiness);

  return {
    filterOptions,
    selectedTimezone,
    selectedFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenFilters() {
      dispatch(onUtilizationOpenFiltersModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUtilityFilters);
