// TODO: This will be replaced over time with components/UtilizationCalendar as In-Store
// Utilization is later expected to be rendered the same way as Online Utilization is now
import React from 'react';
import PropTypes from 'prop-types';
import CalendarMonthView from '@amzn/meridian/calendar-month-view';
import CalendarTimeframeSelect from '@amzn/meridian/calendar-timeframe-select';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import moment from 'moment';
import styles from './Calendar.module.scss';
import commonRender from './CalendarEventRender/commonRender';

const timeNow = moment().format('YYYY-MM-DD 00:00');

const Calendar = (props) => {
  const {
    onTimeFrameChange,
    events,
    eventsRender,
    timeFrame,
  } = props;

  const selectedTimeFrame = timeFrame || timeNow;

  const onTodayClick = () => onTimeFrameChange(timeNow);
  const onCalendarTimeFrameSelect = (value) => onTimeFrameChange(value);

  const getEvents = () => (typeof eventsRender === 'function'
    ? eventsRender(events)
    : commonRender(events));

  const getCalendar = () => {
    const eventsList = getEvents();

    return (
      <Column
        type="outline"
        heights={['fit', 'fill']}
        spacing="none"
        height={400}
        width="100%"
      >
        <Row
          spacingInset="400"
          alignmentHorizontal="justify"
          widths={[75, 'fit', 75]}
        >
          <Button type="secondary" onClick={onTodayClick}>
            Today
          </Button>
          <CalendarTimeframeSelect
            type="month"
            value={selectedTimeFrame}
            onChange={onCalendarTimeFrameSelect}
          />
        </Row>
        <CalendarMonthView
          viewDate={selectedTimeFrame}
          now={timeNow}
          distributedEvents={eventsList}
        />
      </Column>
    );
  };

  const calendar = getCalendar();

  return (
    <div className={styles.calendarContainer}>
      {calendar}
    </div>
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  eventsRender: PropTypes.func,
  timeFrame: PropTypes.string,
  onTimeFrameChange: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  events: [],
  eventsRender: null,
  timeFrame: '',
};

export default Calendar;
