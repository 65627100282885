/* eslint-disable no-param-reassign */
// campaign objects are mutated to include errors when found

import { validate } from '../../../helpers/formValidators';

const hasPartialValidationErrors = (campaign, partialFieldsValidationList) => {
  const errorsList = Object.keys(campaign.errors);

  for (let i = 0; i < errorsList.length; i++) {
    const columnName = errorsList[i];
    if (partialFieldsValidationList[columnName]) {
      return true;
    }
  }

  return false;
};

const validateRequiredFields = (pkg, partialFieldsValidationList = {}) => {
  const validations = validate(pkg);
  const validationFields = Object.keys(validations);
  const isPartialFieldsValidation = Object.keys(partialFieldsValidationList).length;

  if (validationFields.length === 0) {
    return false;
  }

  if (!pkg.errors) {
    pkg.errors = {};
  }

  if (validations.campaigns) {
    validations.campaigns.forEach((campaign, index) => {
      if (!pkg.campaigns[index].errors) {
        pkg.campaigns[index].errors = {};
      }
      const campaignFields = Object.keys(campaign);
      campaignFields.forEach((field) => {
        if (!pkg.campaigns[index].errors[field]) {
          pkg.campaigns[index].errors[field] = {};
        }
        pkg.campaigns[index].errors[field].text = campaign[field] || 'Missing required field';
      });
    });
  }

  validationFields.forEach((field) => {
    if (isPartialFieldsValidation && !partialFieldsValidationList[field]) {
      return;
    }
    if (!pkg.errors[field]) { pkg.errors[field] = {}; }
    pkg.errors[field].text = validations[field] || 'Missing required field';
  });

  return isPartialFieldsValidation
    ? hasPartialValidationErrors(pkg, partialFieldsValidationList)
    : true;
};

export default validateRequiredFields;
