import React from 'react';
import GridViewPage from '../GridViewPage';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';
import {
  isClonePackageRoute,
  isEditPackageRoute,
  isNewPackageRoute,
} from '../../helpers/router';
import EditPackagePage from './edit-package';
import ClonePackagePage from './clone-package';
import NewPackagePage from './new-package';

export const getPackageModal = (props) => {
  const { match: { path } } = props;

  if (isNewPackageRoute(path)) {
    return NewPackagePage(props);
  }

  if (isClonePackageRoute(path)) {
    return ClonePackagePage(props);
  }

  if (isEditPackageRoute(path)) {
    return EditPackagePage(props);
  }

  return null;
};

export const MainViewPage = (props) => {
  const packageModal = getPackageModal(props);

  return (
    <>
      {packageModal}
      <GridViewPage {...props} />
    </>
  );
};

export default WithHistoryTiming(MainViewPage, { pageName: PageNames.GRID_VIEW });
