import { connect } from 'react-redux';
import Utilization from './Utilization';
import {
  getChartItemsData,
  getGroupDescription,
  getGroupName,
  processUtilizationSlots,
} from '../../../helpers/utilization-dashboard';
import { isGenericFreshBusiness } from '../../../helpers/common';

export const mapStateToProps = (state) => {
  const {
    Sitewide: {
      selectedBusiness,
    },
    UtilizationPage: {
      selectedFilters,
      selectedDateRange,
      slotUtilization: {
        desktopSlotUtilization,
        mobileSlotUtilization,
      },
    },
  } = state;

  const desktopChart = {};
  const mobileChart = {};
  if (desktopSlotUtilization) {
    processUtilizationSlots(desktopSlotUtilization, desktopChart);
  }
  if (desktopSlotUtilization) {
    processUtilizationSlots(mobileSlotUtilization, mobileChart);
  }

  return {
    selectedFilters,
    desktopGroups: desktopChart,
    mobileGroups: mobileChart,
    getGroupName,
    getGroupDescription,
    desktopChartItems: getChartItemsData(desktopChart),
    mobileChartItems: getChartItemsData(mobileChart),
    selectedDateRange,
    isFreshBusiness: isGenericFreshBusiness(selectedBusiness),
  };
};

export default connect(mapStateToProps)(Utilization);
