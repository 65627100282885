import { change, getFormValues } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';
import { getCampaignColumnWithName } from 'src/helpers/common';
import { getUtilizationFieldsListToWatch, isValidSlot } from 'src/helpers/formValidators';
import { getUtilizationDataForCampaignForm } from 'src/helpers/utilization';
import { onCampaignFormSetUtilizationData, onCampaignFormSetValidation } from 'src/actionCreators';
import {
  CAMPAIGN_FORM_DATE_FORMAT,
  CampaignColumns,
  DateTimeColumns,
  DeviceTypes,
  Form,
  FormValidationTypes,
  LanguageTypes,
  PageTypes,
  Placements,
  PlacementType,
  PromoTypes,
  SovTypes,
  VendorTypes,
  WidgetTypes,
} from '../../constants';
import { isQuarterStartDate } from '../../helpers/dateTime';
import {
  convertImagePathsToObject,
  extractImagePaths,
  getEmptyImagePathObject,
} from '../../helpers/thirdPartyIFrame/composer';
import createCampaignTabs from './createCampaignTabs';
import {
  createCampaignFieldForIndex,
  getCurrentPackageActiveTab,
} from '../../helpers/form';
import { parseCampaignFormField } from '../../helpers/parseCampaignFormField';
import { isBusinessFreshTraffic, isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';
import {
  getCombinedStatusForPackage,
  isOmnichannelPackageName,
} from '../../helpers/package';
import { TimeValues } from '../../constants/dateTime';

const packageForm = getFormValues(Form.NAME);

const applyChangeToItem = (dispatch, fieldName, fieldValue) => dispatch(change(Form.NAME, fieldName, fieldValue));
const applyChangeToAllCampaigns = (dispatch, column, fieldValue, numCampaigns = 0) => {
  for (let i = 0; i < numCampaigns; i++) {
    const campaignField = createCampaignFieldForIndex(i);
    applyChangeToItem(dispatch, campaignField(column).name, fieldValue);
  }
};
const allCampaignValuesMatch = (campaigns, fieldName, fieldValue) => campaigns.reduce((doesMatch, campaign) => doesMatch && fieldValue === campaign[fieldName], true);
const syncPackageWithCampaignForNonOmni = (dispatch, fieldName, fieldValue) => {
  if ([
    CampaignColumns.START_DATE.name,
    CampaignColumns.START_TIME.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.END_TIME.name,
    CampaignColumns.TIMEZONE_ID.name,
    CampaignColumns.STATUS.name,
    CampaignColumns.REF_MARKER.name,
  ].includes(fieldName)) {
    applyChangeToItem(dispatch, fieldName, fieldValue);
  }
};

// TODO: fix tests when working on the form
export default (dispatch, getState, action, next) => {
  const { meta: { field }, payload: fieldValue } = action;
  const state = getState();
  const isOmnichannelMode = isWebsiteOmnichannelViewMode(state);
  const isTrafficBusiness = isBusinessFreshTraffic(state);
  const currentTab = getCurrentPackageActiveTab(state);

  const packageFormValues = packageForm(state);
  if (_.isEmpty(packageFormValues)) {
    return next(action);
  }
  const parsedCampaignField = parseCampaignFormField(field);
  const { activeCampaignIndex, fieldName, groupName } = parsedCampaignField;
  const isActiveCampaignTab = _.isNumber(activeCampaignIndex) && activeCampaignIndex >= 0;
  const isPackageChange = groupName === '';
  const activeTabValues = isActiveCampaignTab
    // eslint-disable-next-line no-shadow
    ? packageFormValues.campaigns.find((_, index) => {
      return parseInt(index) === parseInt(activeCampaignIndex);
    })
    : packageFormValues;

  const campaignField = createCampaignFieldForIndex(activeCampaignIndex);
  const { endDate, packageName, isDeal, language } = activeTabValues;
  const pageType = isActiveCampaignTab ? activeTabValues.pageType : null;
  if ((fieldName === CampaignColumns.PACKAGE_NAME.name && isOmnichannelPackageName(fieldValue))
  || (fieldName === CampaignColumns.PAGETYPE.name && fieldValue === PageTypes.EVENT)) {
    const startTimeName = TimeValues.MIDNIGHT.value;
    const endTimeName = TimeValues.MIDNIGHT.value;
    dispatch(change(Form.NAME, CampaignColumns.START_TIME.name, startTimeName));
    dispatch(change(Form.NAME, CampaignColumns.END_TIME.name, endTimeName));
    applyChangeToAllCampaigns(dispatch, CampaignColumns.START_TIME, startTimeName, packageFormValues.campaigns.length);
    applyChangeToAllCampaigns(dispatch, CampaignColumns.END_TIME, endTimeName, packageFormValues.campaigns.length);
  }

  if (fieldName === CampaignColumns.PROMO_TYPE.name && isDeal && isOmnichannelMode && fieldName !== PromoTypes.VPC && pageType !== PageTypes.DEALS) {
    const startTimeName = TimeValues.DEFAULT.value;
    const endTimeName = TimeValues.MIDNIGHT.value;
    dispatch(change(Form.NAME, CampaignColumns.START_TIME.name, startTimeName));
    dispatch(change(Form.NAME, CampaignColumns.END_TIME.name, endTimeName));
    applyChangeToAllCampaigns(dispatch, CampaignColumns.START_TIME, startTimeName, packageFormValues.campaigns.length);
    applyChangeToAllCampaigns(dispatch, CampaignColumns.END_TIME, endTimeName, packageFormValues.campaigns.length);
  }

  if ((fieldName === CampaignColumns.START_DATE.name) && !endDate) {
    const twoWeeksAfter = moment(fieldValue).add(2, 'weeks').format(CAMPAIGN_FORM_DATE_FORMAT);
    const endOfQuarter = moment(fieldValue).endOf('quarter').format(CAMPAIGN_FORM_DATE_FORMAT);
    const nextTuesday = moment(fieldValue).add(14, 'days').format(CAMPAIGN_FORM_DATE_FORMAT);
    const endDateName = isActiveCampaignTab ? campaignField(CampaignColumns.END_DATE).name : CampaignColumns.END_DATE.name;

    if (isQuarterStartDate(fieldValue)) {
      dispatch(change(Form.NAME, endDateName, endOfQuarter));
    }
    if (isOmnichannelPackageName(packageName)) {
      dispatch(change(Form.NAME, endDateName, nextTuesday));
    } else {
      dispatch(change(Form.NAME, endDateName, twoWeeksAfter));
    }
  }

  if (fieldName === CampaignColumns.WIDGET.name
      && (action.payload === WidgetTypes.HERO
          || action.payload === WidgetTypes.HERO_MAIN)) {
    dispatch(change(Form.NAME, campaignField(CampaignColumns.NEED_INVENTORY_AWARENESS).name, true));
  }

  const widget = isActiveCampaignTab ? activeTabValues.widget : null;
  const imagePaths = isActiveCampaignTab ? activeTabValues.imagePaths : null;

  // user selected ENHSHOV as widget
  if (fieldName === CampaignColumns.WIDGET.name && fieldValue === WidgetTypes.ENHSHOV) {
    if (!imagePaths) {
      dispatch(change(
        Form.NAME,
        campaignField(CampaignColumns.IMAGE_PATHS).name,
        JSON.stringify(getEmptyImagePathObject()),
      ));
    } else {
      const images = extractImagePaths(imagePaths);
      const imageObject = convertImagePathsToObject(images);
      dispatch(change(Form.NAME, campaignField(CampaignColumns.IMAGE_PATHS).name, JSON.stringify(imageObject)));
    }
  }

  // reset imagePath if something ENHSHOV is unselected as widget
  if (widget && fieldName === CampaignColumns.WIDGET.name
    && widget === WidgetTypes.ENHSHOV
    && fieldValue !== WidgetTypes.ENHSHOV
    && imagePaths
    && _.isObject(imagePaths)) {
    const imageObject = JSON.parse(imagePaths);
    if (_.isEqual(imageObject, getEmptyImagePathObject())) {
      dispatch(change(Form.NAME, campaignField(CampaignColumns.IMAGE_PATHS).name, ''));
    } else {
      const newImagePath = imageObject.desktop || imageObject.mobile || '';
      dispatch(change(Form.NAME, campaignField(CampaignColumns.IMAGE_PATHS).name, newImagePath));
    }
  }

  if (fieldName === CampaignColumns.PACKAGE_NAME.name) {
    if (fieldValue.toLowerCase().includes(VendorTypes.HOUSE.toLowerCase())) {
      dispatch(change(Form.NAME, CampaignColumns.VENDOR.name, VendorTypes.HOUSE));
    }
    if (isOmnichannelMode) {
      return createCampaignTabs(dispatch, getState, action, next);
    }
  }

  if (!isOmnichannelMode && isActiveCampaignTab) {
    syncPackageWithCampaignForNonOmni(dispatch, fieldName, fieldValue);
  }

  if (fieldName === CampaignColumns.IS_DEAL.name && isOmnichannelMode) {
    if (isPackageChange && currentTab === -1) {
      if (!allCampaignValuesMatch(packageFormValues.campaigns, CampaignColumns.IS_DEAL.name, fieldValue)) {
        applyChangeToAllCampaigns(dispatch, CampaignColumns.IS_DEAL, fieldValue, packageFormValues.campaigns.length);
      }
    } else if (!isPackageChange && currentTab !== -1) {
      const nonActiveCampaigns = packageFormValues.campaigns.filter((campaign, index) => index !== Number(activeCampaignIndex));
      if (allCampaignValuesMatch(nonActiveCampaigns, CampaignColumns.IS_DEAL.name, fieldValue) || !fieldValue) {
        applyChangeToItem(dispatch, CampaignColumns.IS_DEAL.name, fieldValue);
      }
    }
  }

  if (DateTimeColumns.includes(fieldName) && isOmnichannelMode) {
    const fieldColumn = getCampaignColumnWithName(fieldName);
    if (isPackageChange && currentTab === -1) {
      applyChangeToAllCampaigns(dispatch, fieldColumn, fieldValue, packageFormValues.campaigns.length);
    }
  }
  if (getUtilizationFieldsListToWatch().includes(fieldName) && language === LanguageTypes.ENGLISH) {
    const utilizationData = getUtilizationDataForCampaignForm(state, activeTabValues);
    if (Object.keys(utilizationData).length) {
      dispatch(onCampaignFormSetUtilizationData(utilizationData));
      dispatch(onCampaignFormSetValidation({
        validationType: FormValidationTypes.UTILIZATION,
        data: {
          isValidDesktopSlot: isValidSlot(utilizationData.desktopUtilizationData),
          isValidMobileSlot: isValidSlot(utilizationData.mobileUtilizationData),
        },
      }));
    }
  }

  if (fieldName === CampaignColumns.PLACEMENT.name && fieldValue === Placements.ZONE_9) {
    dispatch(change(Form.NAME, campaignField(CampaignColumns.SOV_TYPE).name, SovTypes.DEFAULT));
    dispatch(change(Form.NAME, campaignField(CampaignColumns.SOV).name, ''));
  }

  if (isTrafficBusiness) {
    if (fieldName === CampaignColumns.PLACEMENT.name && fieldValue === PlacementType.STRIPE) {
      const startTimeName = TimeValues.MIDNIGHT.value;
      dispatch(change(Form.NAME, CampaignColumns.START_TIME.name, startTimeName));
    }
    if (fieldName === CampaignColumns.PLACEMENT.name && fieldValue === PlacementType.ILM) {
      const deviceTypeName = DeviceTypes.ALL;
      const slotName = 'detail-ilm';
      dispatch(change(Form.NAME, campaignField(CampaignColumns.DEVICE_TYPE).name, deviceTypeName));
      dispatch(change(Form.NAME, campaignField(CampaignColumns.DESKTOP_SLOT).name, slotName));
      dispatch(change(Form.NAME, campaignField(CampaignColumns.MOBILE_SLOT).name, slotName));
    }
  }

  if (fieldName === CampaignColumns.STATUS.name) {
    next(action);

    if (isPackageChange && currentTab === -1) {
      applyChangeToAllCampaigns(dispatch, CampaignColumns.STATUS, fieldValue, packageFormValues.campaigns.length);
    } else if (!isPackageChange && currentTab !== -1) {
      const packageStatus = getCombinedStatusForPackage(packageForm(getState()));
      applyChangeToItem(dispatch, CampaignColumns.STATUS.name, packageStatus);
    }
  }

  return next(action);
};
