import {
  ColorCodes,
  SlotsUtilizationChart,
  SlotsUtilizationGroups,
} from '../constants';

export const getChartColorByGroupName = (groupName) => {
  const colorsList = {
    [SlotsUtilizationGroups.EXCELLENT]: ColorCodes.GREEN,
    [SlotsUtilizationGroups.UNDERBOOKED]: ColorCodes.ORANGE,
    [SlotsUtilizationGroups.OVERBOOKED]: ColorCodes.RED,
  };

  return colorsList[groupName] ? colorsList[groupName] : ColorCodes.BLUE;
};

export const getGroupName = (groupId) => {
  const groupNames = {
    [SlotsUtilizationGroups.EXCELLENT]: 'Excellent',
    [SlotsUtilizationGroups.UNDERBOOKED]: 'Underbooked',
    [SlotsUtilizationGroups.OVERBOOKED]: 'Overbooked',
  };

  return groupNames[groupId] ? groupNames[groupId] : '';
};

export const getGroupDescription = (groupName) => {
  if (!SlotsUtilizationChart[groupName]) {
    return '';
  }
  const { start } = SlotsUtilizationChart[groupName];
  let { end } = SlotsUtilizationChart[groupName];

  if (!end) {
    end = 'Infinity';
  }

  return [
    start, end,
  ];
};

const isChartGroupMatch = (value, chartLevel) => {
  const currentChartLevel = SlotsUtilizationChart[chartLevel];
  if (!currentChartLevel) {
    return false;
  }

  if (!currentChartLevel.end) {
    return value >= currentChartLevel.start;
  }

  return (value >= currentChartLevel.start && value <= currentChartLevel.end);
};

const getGroupsToProcess = () => [
  SlotsUtilizationGroups.EXCELLENT,
  SlotsUtilizationGroups.UNDERBOOKED,
  SlotsUtilizationGroups.OVERBOOKED,
];

const processChart = (chart, { date, slotsUtilization, slotName }) => {
  const { placement, utilization } = slotsUtilization[slotName];

  const currentDateUtilization = utilization[date];
  const { totalSOV } = currentDateUtilization;

  getGroupsToProcess().forEach((currentLevel) => {
    if (isChartGroupMatch(totalSOV, currentLevel)) {
      if (!chart[currentLevel]) {
        /* eslint-disable-next-line no-param-reassign */
        chart[currentLevel] = {};
      }
      if (!chart[currentLevel][slotName]) {
        /* eslint-disable-next-line no-param-reassign */
        chart[currentLevel][slotName] = {
          placement,
          utilization: {
            [date]: currentDateUtilization,
          },
        };

        return;
      }

      const currentSlot = chart[currentLevel][slotName];
      currentSlot.utilization = {
        ...currentSlot.utilization,
        [date]: currentDateUtilization,
      };
    }
  });
};

export const processUtilizationSlots = (slotsUtilization, chart) => {
  if (!slotsUtilization || !Object.keys(slotsUtilization)) {
    return;
  }

  Object
    .keys(slotsUtilization)
    .forEach((slotName) => {
      const { utilization } = slotsUtilization[slotName];

      const datesList = Object.keys(utilization);
      if (!utilization || !datesList.length) {
        return;
      }
      datesList
        .forEach((date) => {
          processChart(
            chart,
            {
              date,
              slotsUtilization,
              slotName,
            },
          );
        });
    });
};

export const getChartItemsData = (chart) => {
  const countByGroups = getGroupsToProcess()
    .reduce((obj, key) => Object.assign(obj, { [key]: 0 }), {});
  let totalCount = 0;

  Object.keys(chart).forEach((groupName) => {
    if (!countByGroups[groupName]) {
      countByGroups[groupName] = 0;
    }

    const itemsCount = Object.keys(chart[groupName]).length;
    countByGroups[groupName] = itemsCount;
    totalCount += itemsCount;
  });

  const stats = [];
  Object.keys(countByGroups).forEach((groupName) => {
    const groupCount = countByGroups[groupName];

    stats.push({
      label: getGroupName(groupName),
      percent: ((groupCount / totalCount) * 100).toFixed(2),
      color: getChartColorByGroupName(groupName),
    });
  });

  return stats;
};
