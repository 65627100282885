// TODO: This configuration will be replaced with a UI/UX Editor after Omnichannel launch
// SIM: https://sim.amazon.com/issues/P123297442
import IN_STORE_ONLY_PACKAGES from './inStoreOnlyPackages';
import ONLINE_ONLY_PACKAGES from './onlineOnlyPackages';
import OMNICHANNEL_PACKAGES from './omnichannelPackages';

const PACKAGE_MAPPING = {
  ...IN_STORE_ONLY_PACKAGES,
  ...ONLINE_ONLY_PACKAGES,
  ...OMNICHANNEL_PACKAGES,
};

export default PACKAGE_MAPPING;
