import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@amzn/meridian/tooltip';
import { Field } from 'redux-form';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import Icon from '@amzn/meridian/icon';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import { Language } from '../../constants';
import styles from './RateCardPlacementList.module.scss';

const RateCardPlacementList = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    columns,
    defaultRender,
    bundlesConfig,
    placementsConfig,
  } = props;

  const getRows = () => {
    return fields.map((field, fieldIndex) => {
      const cellList = columns.map(({ name, render }) => {
        const renderComponent = render && typeof render === 'function'
          ? render
          : defaultRender;

        return (
          <TableCell key={name}>
            <Field
              name={`${field}.${name}`}
              type="text"
              component={renderComponent}
              props={{ bundlesConfig, placementsConfig, fields, index: fieldIndex }}
            />
          </TableCell>
        );
      });

      return (
        <TableRow key={`${field}.placementName`} highlightOnHover>
          {cellList}
        </TableRow>
      );
    });
  };

  const getHeaderRows = () => {
    const headerRowsList = columns.map(({ name, label, description }) => {
      const content = description
        ? (
          <div className={styles.tableHeader}>
            <span className={styles.tableHeaderLabel}>{label}</span>
            <Tooltip position="top" title={description}>
              <Icon tokens={infoKnockoutTokens}>Description</Icon>
            </Tooltip>
          </div>
        )
        : label;

      return <TableCell key={name}>{content}</TableCell>;
    });

    return (
      <TableRow highlightOnHover>
        {headerRowsList}
      </TableRow>
    );
  };

  const getTable = () => {
    const headerRows = getHeaderRows();
    const rows = getRows();

    return (
      <Table headerRows={1} showDividers spacing="small">
        {headerRows}
        {rows}
      </Table>
    );
  };

  const getContent = () => {
    if (!fields.length) {
      return (
        <p className={styles.refreshPlacementsMessage}>
          {Language.RATE_CARD_REFRESH_PLACEMENTS_MESSAGE}
        </p>
      );
    }

    return getTable();
  };

  return (
    <>
      {submitFailed && error && <span>{error}</span>}
      {getContent()}
    </>
  );
};

RateCardPlacementList.propTypes = {
  fields: PropTypes.objectOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }).isRequired,
  defaultRender: PropTypes.elementType.isRequired,
};

RateCardPlacementList.defaultProps = {
  fields: [],
  columns: [],
};

export default RateCardPlacementList;
